import { Avatar, FormControl, MenuItem, Select } from "@mui/material";
import { statusPatchKeys, USER_STATUSES } from "app/components/Statuses";
import { UserAPI } from "app/routes/middleware";

const currencyFormatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "GHS",
});

const ghsPrice = {
	type: "number",
	width: 130,
	valueFormatter: ({ value }) => currencyFormatter.format(null),
	cellClassName: "font-tabular-nums",
};

// const handleChange = async (event, params) => {
// 	const state = statusPatchKeys["User " + event.target.value?.trim()] || null;
// 	const userId = params.row.id;

// 	if (state && userId) {
// 		try {
// 			await UserAPI.patchUserStatus(userId, state);
// 			// console.log(request, "status");
// 		} catch (error) {
// 			console.log(error);
// 		}
// 	}
// };

export const columns = [
	{
		field: "imageURL",
		headerName: "Avatar",
		width: 80,
		renderCell: (params) => <Avatar src={params?.value || ""} alt="Avatar" />,
	},
	{
		field: "firstName",
		headerName: "First Name",
		width: 150,
		renderCell: (params) => {
			return params.value === null || !params.value ? "N/A" : params.value;
		},
	},
	{
		field: "otherNames",
		headerName: "Other Names",
		width: 150,
		renderCell: (params) => {
			return params.value === null || !params.value ? "N/A" : params.value;
		},
	},
	{
		field: "lastName",
		headerName: "Last Name",
		width: 150,
		renderCell: (params) => {
			return params.value === null || !params.value ? "N/A" : params.value;
		},
	},
	{
		field: "msisdn",
		headerName: "Phone Number",
		width: 140,
	},
	{
		field: "email",
		headerName: "Email",
		width: 200,
		renderCell: (params) => {
			return params.value === null || !params.value ? "N/A" : params.value;
		},
	},
	{
		field: "socialHandle",
		headerName: "Social Handle",
		width: 120,
		renderCell: (params) => {
			return params.value === null || !params.value ? "N/A" : params.value;
		},
	},
	{
		field: "userType",
		headerName: "User Type",
		width: 140,
	},
	{
		field: "userStatus",
		headerName: "User Status",
		width: 140,
	},
	// {
	// 	field: "contacts",
	// 	headerName: "All Contacts",
	// 	width: 180,
	// 	renderCell: (params) => {
	// 		// console.log( params, "params" );
	// 		return params.value.contactsCount;
	// 	},
	// },
	{
		field: "contacts",
		headerName: "Spark Contacts",
		width: 180,
		renderCell: (params) => {
			// console.log( params, "params" );
			return params.value.sparkContactsCount;
		},
	},
	{
		field: "hasAVerifiedId",
		headerName: "ID Verified?",
		width: 100,
		renderCell: (params) => <span>{params.value === true ? "Yes" : "No"}</span>,
	},
	{
		field: "verifiedAt",
		headerName: "Verified At",
		width: 200,
		type: "dateTime",
		valueFormatter: ({ value }) => value && new Date(value).toLocaleString(),
	},
	{
		field: "createdAt",
		headerName: "Date Created",
		type: "dateTime",
		valueFormatter: ({ value }) => value && new Date(value).toLocaleString(),
		width: 200,
	},
	{
		field: "updatedAt",
		headerName: "Date Updated",
		type: "dateTime",
		valueFormatter: ({ value }) => value && new Date(value).toLocaleString(),
		width: 200,
	},
	// {
	// 	field: "actions",
	// 	type: "actions",
	// 	headerName: "Actions",
	// 	width: 100,
	// 	cellClassName: "actions",
	// },
];
