import React from "react";
import SignIn from '../pages/auth/signIn'
import { _paths_ } from "app/utils/appHelpers";
import { AuthMiddleware } from "./middleware";
import AdminDashboard from "../pages/dashboard";
import Users from "app/pages/users";
import Clubs from "app/pages/clubs";
import MoneyRequests from "app/pages/moneyrequests";
import Permissions from "app/pages/permissions";
import Compliance from "app/pages/compliance";
import Transactions from "app/pages/transactions";
import Kyc from "app/pages/kyc";
import Verification from "app/pages/kyc/verification";
import Home from "app/pages/home";
import Notifications from "app/pages/notifications";
import Catalogs from "app/pages/deals/catalogs";
import Products from "app/pages/deals/products";
import Orders from "app/pages/deals/orders";

const routes = {
	unsecured: [
		{
			path: _paths_.SIGN_IN,
			element: <SignIn />,
		},
		{
			path: _paths_.SIGN_IN_HOME,
			element: <SignIn />,
		},
	],
	secured: [
		{
			path: _paths_.HOME,
			element: (
				<AuthMiddleware fallbackPath={_paths_.SIGN_IN} children={<Home />} />
			),
		},
		{
			path: _paths_.DASHBOARD,
			element: (
				<AuthMiddleware
					fallbackPath={_paths_.SIGN_IN}
					children={<AdminDashboard />}
				/>
			),
		},
		{
			path: _paths_.COMPLIANCE,
			element: (
				<AuthMiddleware
					fallbackPath={_paths_.SIGN_IN}
					children={<Compliance />}
				/>
			),
		},
		{
			path: _paths_.USERS,
			element: (
				<AuthMiddleware fallbackPath={_paths_.SIGN_IN} children={<Users />} />
			),
		},
		{
			path: _paths_.CLUBS,
			element: (
				<AuthMiddleware fallbackPath={_paths_.SIGN_IN} children={<Clubs />} />
			),
		},
		{
			path: _paths_.MONEYREQUESTS,
			element: (
				<AuthMiddleware
					fallbackPath={_paths_.SIGN_IN}
					children={<MoneyRequests />}
				/>
			),
		},
		{
			path: _paths_.TRANSACTIONS,
			element: (
				<AuthMiddleware
					fallbackPath={_paths_.SIGN_IN}
					children={<Transactions />}
				/>
			),
		},
		{
			path: _paths_.KYC,
			element: (
				<AuthMiddleware fallbackPath={_paths_.SIGN_IN} children={<Kyc />} />
			),
		},
		{
			path: _paths_.VERIFICATION,
			element: (
				<AuthMiddleware
					fallbackPath={_paths_.SIGN_IN}
					children={<Verification />}
				/>
			),
		},
		{
			path: _paths_.PERMISSIONS,
			element: (
				<AuthMiddleware
					fallbackPath={_paths_.SIGN_IN}
					children={<Permissions />}
				/>
			),
		},
		{
			path: _paths_.NOTIFICATIONS,
			element: (
				<AuthMiddleware
					fallbackPath={_paths_.SIGN_IN}
					children={<Notifications />}
				/>
			),
		},
		{
			path: _paths_.CATALOGS,
			element: (
				<AuthMiddleware
					fallbackPath={_paths_.SIGN_IN}
					children={<Catalogs />}
				/>
			),
		},
		{
			path: _paths_.PRODUCTS,
			element: (
				<AuthMiddleware
					fallbackPath={_paths_.SIGN_IN}
					children={<Products />}
				/>
			),
		},
		{
			path: _paths_.ORDERS,
			element: (
				<AuthMiddleware fallbackPath={_paths_.SIGN_IN} children={<Orders />} />
			),
		},
	],
};

export default routes;