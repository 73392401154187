import { Snackbar, Alert, AlertTitle } from "@mui/material";

const Toaster = ({
	open,
	handleClose,
	message,
	severity,
	autoHideDuration,
	vertical,
	horizontal,
	showTitle,
}) => {
	return (
		<Snackbar
			open={open}
			autoHideDuration={autoHideDuration}
			onClose={handleClose}
			key={vertical + horizontal}
			anchorOrigin={{ vertical, horizontal }}
		>
			<Alert
				style={{ backgroundColor: "rgb(12, 19, 13)" }}
				onClose={handleClose}
				severity={severity}
				sx={{ width: "100%" }}
			>
				{showTitle ? (
					<AlertTitle>
						<strong>
							{severity && severity[0].toUpperCase() + severity.slice(1)}
						</strong>
					</AlertTitle>
				) : null}
				{message}
			</Alert>
		</Snackbar>
	);
};

export default Toaster;
