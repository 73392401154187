import {
	Avatar,
	MenuItem,
	Badge,
	MenuList,
	styled,
	Tooltip,
	tooltipClasses,
	Chip,
} from "@mui/material";
import React from "react";

function stringToColor(string) {
	let hash = 0;
	let i;

	/* eslint-disable no-bitwise */
	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = "#";

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.substr(-2);
	}
	/* eslint-enable no-bitwise */

	return color;
}

function stringAvatar(name) {
	return {
		sx: {
			bgcolor: stringToColor(name),
		},
		children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
	};
}

export const HtmlTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "#f5f5f9",
		color: "rgba(0, 0, 0, 0.87)",
		maxWidth: 210,
		padding: 1,
		fontSize: theme.typography.pxToRem(12),
		border: "1px solid #dadde9",
	},
}));

export const UserDetailsToolTip = ({ data }) => {
	const fullName = `${data?.firstName} ${data?.lastName}`; //${data?.otherNames ? `[${data?.otherNames}]` : ''}
	const userStatusColor =
		data?.userStatus === "Active"
			? "success"
			: data?.userStatus === "Suspended"
			? "warning"
			: "error";

	return (
		<React.Fragment>
			<MenuList style={{ overflowY: "scroll", maxHeight: 300, padding: 3 }}>
				<MenuItem style={{ fontSize: 11 }}>
					{
						<Badge variant="dot" color={userStatusColor}>
							<Avatar
								{...stringAvatar(`${data?.firstName} ${data?.lastName}`)}
								src={data?.imageURL || ""}
								sx={{ width: 30, height: 30 }}
							/>{" "}
						</Badge>
					}
					<br />
					<span>&ensp;{fullName}</span>
				</MenuItem>
				<MenuItem style={{ fontSize: 11 }}>
					<div>
						<span style={{ fontWeight: "bold" }}>First Name: &ensp;</span>
						<span>{data?.firstName || ""}</span>
					</div>
				</MenuItem>
				<MenuItem style={{ fontSize: 11 }}>
					<div>
						<span style={{ fontWeight: "bold" }}>Last Name: &ensp;</span>
						<span>{data?.lastName || ""}</span>
					</div>
				</MenuItem>
				<MenuItem style={{ fontSize: 11 }}>
					<div>
						<span style={{ fontWeight: "bold" }}>Other Names: &ensp;</span>
						<span>{data?.otherNames || ""}</span>
					</div>
				</MenuItem>
				<MenuItem style={{ fontSize: 11 }}>
					<div>
						<span style={{ fontWeight: "bold" }}>Phone: &ensp;</span>
						<span>{data?.msisdn || ""}</span>
					</div>
				</MenuItem>
				<MenuItem style={{ fontSize: 11 }}>
					<div>
						<span style={{ fontWeight: "bold" }}>Social Handle: &ensp;</span>
						<span>{data?.socialHandle || ""}</span>
					</div>
				</MenuItem>
				<MenuItem style={{ fontSize: 11 }}>
					<div>
						<span style={{ fontWeight: "bold" }}>ID Verified: &ensp;</span>
						<span>{data?.hasAVerifiedId ? "Yes" : "No"}</span>
					</div>
				</MenuItem>
			</MenuList>
		</React.Fragment>
	);
};

export function userDetailsFormat(
	params,
	key
) {
	const firstName = params?.value?.firstName;
	const lastName = params?.value?.lastName;
	const otherNames = params?.value?.otherNames;

	const name = `${firstName} ${lastName}`;
	if (firstName || lastName || otherNames) {
		return (
			<HtmlTooltip
				followCursor={true}
				placement="top-start"
				title={<UserDetailsToolTip data={params?.value} />}
			>
				<Chip
					style={{ fontSize: 11 }}
					label={name}
					color="primary"
					variant="outlined"
				/>
			</HtmlTooltip>
		);
	}

	return (
		<Chip
			style={{ fontSize: 11 }}
			label={`${key} not found`}
			// color="warning"
			// variant="outlined"
		/>
	);
}
