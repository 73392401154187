import React from "react";
import { Typography, Popover } from "@mui/material";
import TimelineIcon from "@mui/icons-material/Timeline";
import { Chip } from "@mui/material";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import Stack from "@mui/material/Stack";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { Chart1, Chart4, Chart5 } from "../charts/Charts";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import { Popovers } from "../Popovers";

const data = [
	{ month: "Jan", count: 800 },
	{ month: "Feb", count: 1600 },
	{ month: "Mar", count: 1100 },
	{ month: "Apr", count: 2600 },
	{ month: "May", count: 1900 },
	{ month: "Jun", count: 3200 },
];

export const BorderCard = ({
	title,
	label,
	chipcolor,
	digits,
	subtitle,
	subtitleamount,
	details,
}) => {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	return (
		<JumboCardQuick
			title={
				<Typography variant={"h5"} mb={0}>
					{title}
				</Typography>
			}
			action={
				<Stack direction={"row"} spacing={1}>
					{/* <Chip label={label} color={chipcolor} size={"small"} /> */}
					<HelpOutlineRoundedIcon
						style={{ fontSize: "15px", cursor: "pointer", color: "#000" }}
						aria-owns={open ? "mouse-over-popover" : undefined}
						aria-haspopup="true"
						onMouseEnter={handlePopoverOpen}
						onMouseLeave={handlePopoverClose}
					/>
				</Stack>
			}
			wrapperSx={{ textAlign: "center" }}
			headerSx={{
				borderBottom: 1,
				borderBottomColor: "divider",
			}}
			sx={{ boxShadow: 0 }}
		>
			<Typography variant={"h2"}>{digits}</Typography>
			<Popovers
				open={open}
				anchorEl={anchorEl}
				handlePopoverClose={handlePopoverClose}
				details={details}
			/>
		</JumboCardQuick>
	);
};

export const SimpleCard = ( { title, label, chipcolor, digits, details } ) => {
		const [anchorEl, setAnchorEl] = React.useState(null);

		const handlePopoverOpen = (event) => {
			setAnchorEl(event.currentTarget);
		};

		const handlePopoverClose = () => {
			setAnchorEl(null);
		};

		const open = Boolean(anchorEl);
	return (
		<JumboCardQuick
			title={
				<Typography variant={"h5"} mb={0}>
					{title}
				</Typography>
			}
			action={
				<Stack direction={"row"} spacing={1}>
					<HelpOutlineRoundedIcon
						style={{ fontSize: "15px", cursor: "pointer", color: "#000" }}
						aria-owns={open ? "mouse-over-popover" : undefined}
						aria-haspopup="true"
						onMouseEnter={handlePopoverOpen}
						onMouseLeave={handlePopoverClose}
					/>
				</Stack>
			}
			wrapperSx={{ textAlign: "center" }}
			headerSx={{
				borderBottom: 1,
				borderBottomColor: "divider",
			}}
			sx={{ boxShadow: 0 }}
		>
			<Typography mb={1} variant={"h2"}>
				{digits} <ShowChartIcon mr={2} fontSize={"small"} color={"success"} />
			</Typography>
			<Popovers
				open={open}
				anchorEl={anchorEl}
				handlePopoverClose={handlePopoverClose}
				details={details}
			/>
		</JumboCardQuick>
	);
};

export const LineChartCard = ({ title, digits, percentage }) => {
	return (
		<JumboCardQuick
			title={<Typography variant={"h3"}>{digits}</Typography>}
			subheader={title}
			action={
				<Typography variant={"body1"} color={"success.main"}>
					{percentage}{" "}
					<TrendingUpIcon
						sx={{
							verticalAlign: "middle",
							fontSize: "1rem",
							ml: 0.5,
							color: "inherit",
						}}
					/>
				</Typography>
			}
			wrapperSx={{ pt: 1 }}
			sx={{ boxShadow: 0 }}
		>
			<Chart1 data={data} />
		</JumboCardQuick>
	);
};

export const ColoredCard = ({
	title,
	digits,
	hovertitle,
	color,
	borderTop,
	Icon,
	details,
}) => {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<JumboCardQuick
			title={
				<Typography fontWeight={"500"} variant={"h3"}>
					{digits}
				</Typography>
			}
			subheader={
				<Typography variant={"h6"} mb={0}>
					{title}
				</Typography>
			}
			reverse
			sx={{ borderTop: { borderTop }, boxShadow: 0 }}
			bgColor={["#fff", "#fff"]}
			wrapperSx={{ pb: 0 }}
		>
			{/* <Chart2 data={data} hovertitle={hovertitle} color={color} /> */}
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				{Icon}
				<HelpOutlineRoundedIcon
					style={{ fontSize: "15px", cursor: "pointer", color: "#000" }}
					aria-owns={open ? "mouse-over-popover" : undefined}
					aria-haspopup="true"
					onMouseEnter={handlePopoverOpen}
					onMouseLeave={handlePopoverClose}
				/>
			</div>
			<Popovers
				open={open}
				anchorEl={anchorEl}
				handlePopoverClose={handlePopoverClose}
				details={details}
			/>
		</JumboCardQuick>
	);
};

export const FilledCard = ({
	bgColor,
	color,
	textcolor,
	digits,
	title,
	Icon,
	details
} ) => {
		const [anchorEl, setAnchorEl] = React.useState(null);

		const handlePopoverOpen = (event) => {
			setAnchorEl(event.currentTarget);
		};

		const handlePopoverClose = () => {
			setAnchorEl(null);
		};

		const open = Boolean(anchorEl);
	return (
		<JumboCardQuick
			noWrapper
			title={
				<Typography
					variant={"h2"}
					color={textcolor ? textcolor : "common.white"}
				>
					{`${digits ? digits + "+" : "Loading.."}`}
				</Typography>
			}
			subheader={
				<Typography
					variant={"h6"}
					// color={"text.secondary"}
					color={textcolor ? textcolor : "common.white"}
					mb={0}
				>
					{title}
				</Typography>
			}
			action={
				<div style={{ display: "flex" }}>
					<TimelineIcon />
					<HelpOutlineRoundedIcon
						style={{ fontSize: "15px", cursor: "pointer" }}
						aria-owns={open ? "mouse-over-popover" : undefined}
						aria-haspopup="true"
						onMouseEnter={handlePopoverOpen}
						onMouseLeave={handlePopoverClose}
					/>
				</div>
			}
			sx={{ color: `${textcolor ? textcolor : "common.white"}`, boxShadow: 0 }}
			bgColor={bgColor}
		>
			{/* <Chart3 data={data} color={color} /> */}
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					marginBottom: "1rem",
				}}
			>
				<img src={Icon} alt="icon" width={50} />
			</div>
			<Popovers
				open={open}
				anchorEl={anchorEl}
				handlePopoverClose={handlePopoverClose}
				details={details}
			/>
		</JumboCardQuick>
	);
};

export const PieCard = ({title, subheader, data}) => {
	return (
		<JumboCardQuick
			title={title}
			subheader={subheader}
			sx={{
				textAlign: "center",
			}}
		>
			<Chart4 data={data} />
		</JumboCardQuick>
	);
};

export const DiffCard = ({ chartHeight, subTitle }) => {
	<JumboCardQuick
		title={
			<Typography variant={"h4"} mb={0}>
				In-Spark Transactions
			</Typography>
		}
		subheader={
			subTitle === null ? subTitle : `Processed vs Pending`
		}
		sx={{ textAlign: "center" }}
		wrapperSx={{ pt: 0 }}
	>
		<Chart5 height={chartHeight} />
	</JumboCardQuick>;
};