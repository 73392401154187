import React from "react";
import { Box, Grid, Snackbar } from "@mui/material";
import { DashboardAPI } from "app/routes/middleware";
import { Helmet } from "react-helmet-async";
import { appendQueries, metricURLs } from "app/routes/middleware/metrics.url";
import { dateRangePicker } from "app/utils/appHelpers";
import {
	BorderCard,
	ColoredCard,
	FilledCard,
	SimpleCard,
} from "app/components/cards/Cards";
import DatePicker from "app/components/DatePicker";
import GradingIcon from "@mui/icons-material/Grading";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import FormatIndentDecreaseOutlinedIcon from "@mui/icons-material/FormatIndentDecreaseOutlined";
import HistoryToggleOffOutlinedIcon from "@mui/icons-material/HistoryToggleOffOutlined";
import BeenhereOutlinedIcon from "@mui/icons-material/BeenhereOutlined";
import PriceCheckOutlinedIcon from "@mui/icons-material/PriceCheckOutlined";
import DonutSmallOutlinedIcon from "@mui/icons-material/DonutSmallOutlined";
import FiberSmartRecordOutlinedIcon from "@mui/icons-material/FiberSmartRecordOutlined";
import HomeMiniOutlinedIcon from "@mui/icons-material/HomeMiniOutlined";
import StarHalfOutlinedIcon from "@mui/icons-material/StarHalfOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import OutboxOutlinedIcon from "@mui/icons-material/OutboxOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import AltRouteOutlinedIcon from "@mui/icons-material/AltRouteOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import OutboundOutlinedIcon from "@mui/icons-material/OutboundOutlined";
import ConnectWithoutContactOutlinedIcon from "@mui/icons-material/ConnectWithoutContactOutlined";
import InsertChartOutlinedTwoToneIcon from "@mui/icons-material/InsertChartOutlinedTwoTone";
import DirectionsOutlinedIcon from "@mui/icons-material/DirectionsOutlined";
import GppBadOutlinedIcon from "@mui/icons-material/GppBadOutlined";
import Alert from "@mui/material/Alert";
import { CircularProgress, LinearProgress } from "@mui/material";
import { DateRangeComponent } from "app/components/DateRange";
import { Table1 } from "./data/table";
import { columns } from "./data/column";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import { Popovers } from "app/components/Popovers";
import J4RobotLoader, { LoadingModal } from "app/components/Modals";

// const data = {
// 	colors: ["#0795F4", "#8DCD03", "#23036A"],
// 	pieData: [
// 		{
// 			name: "Windows",
// 			value: 25.85,
// 		},
// 		{
// 			name: "Apple",
// 			value: 50.85,
// 		},
// 		{
// 			name: "Android ",
// 			value: 25.91,
// 		},
// 	],
// 	applicationsList: [
// 		`${ASSET_IMAGES}/dashboard/window.png`,
// 		`${ASSET_IMAGES}/dashboard/apple.png`,
// 		`${ASSET_IMAGES}/dashboard/android.png`,
// 	],
// };

const AdminDashboard = () => {
	const [allMetrics, setAllMetrics] = React.useState({});
	const [dateRange, setDateRange] = React.useState(dateRangePicker.ytd());
	const [loading, setLoading] = React.useState(true);
	const [progress, setProgress] = React.useState(0);
	const [open, setOpen] = React.useState(false);
	const [message, setMessage] = React.useState("");
	const [severity, setSeverity] = React.useState("success");
	const [view, setView] = React.useState(false);
	const [mostActiveUsersData, setMostActiveUsersData] = React.useState([]);

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const openAnchor = Boolean(anchorEl);

	React.useEffect(() => {
		const timer = setInterval(() => {
			setProgress((oldProgress) => {
				if (oldProgress === 100) {
					return 0;
				}
				const diff = Math.random() * 10;
				return Math.min(oldProgress + diff, 100);
			});
		}, 500);

		return () => {
			clearInterval(timer);
		};
	}, []);

	React.useEffect(() => {
		// console.log({ dateRange }, "dateRange");
		if (dateRange.startDate !== undefined && dateRange.endDate !== undefined) {
			fetchAllMetrics(dateRange);
			fetchMostActiveUsers(dateRange);
		}
		// console.log({ allMetrics }, "allMetrics");
		// eslint-disable-next-line react-hooks/exhaustive-deps

		return () => {
			setAllMetrics({});
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setDateRange, dateRange]);

	async function fetchMostActiveUsers(range) {
		const request = await DashboardAPI.getMostActiveUsers(range);
		if (request.data.status === 200) {
			setMostActiveUsersData(request.data.data.amount);
			// console.log(request.data.data.amount, "most active users");
		}
	}

	async function fetchAllMetrics(range) {
		// console.log({ range });
		const urls = appendQueries(metricURLs, { ...range });
		// console.log({ urls });

		const requests = await DashboardAPI.getAllMetrics(urls, false);
		for (const request of requests) {
			if (!request.isLoadingInBatch) {
				setLoading(true);
				await request.res
					.then((response) => {
						if (response.data.status === 200) {
							setLoading(false);
							if (request?.label) {
								setAllMetrics((x) => ({
									...x,
									[request.label]: response.data.data,
									label: request.name,
								}));
							}
						}
					})
					.catch((error) => {
						Promise.reject(error);
						setLoading(false);
						// setMessage(error.response?.data?.message || error.message || "Something went wrong");
						setMessage(
							"Something went wrong while loading one or multiple data on the dashboard"
						);
						setSeverity("error");
						setOpen(true);
					});
			}
		}
	}

	let averageSessionTime =
		allMetrics?.Users_Session?.averageSessionTimePerUniqueUser?.inHrs;

	let numberOfTransactedUsers =
		allMetrics?.Users_Session?.numberOfTransactedUsers;

	let numberOfTimesActiveUsersLoggedIn =
		allMetrics?.Users_Session?.numberOfTimesActiveUsersLoggedIn;

	let totalTransactionVolumes =
		allMetrics?.Withdraw_Volume?.volume +
		allMetrics?.Deposit_Volume?.volume +
		allMetrics?.All_InSpark_Transactions_Volume?.volume;

	// let moneyRequestP2PCount =
	// 	allMetrics?.Total_Money_Requested_Count?.count -
	// 	allMetrics?.Club_Money_Request_Approved_Volume?.count;

	// let moneyRequestP2PVolume =
	// 	allMetrics?.Total_Money_Requested_Volume?.volume -
	// 	allMetrics?.Club_Money_Request_Approved_Volume?.volume;


	return (
		<div>
			<Helmet>
				<title>Admin Dashboard - Spark</title>
			</Helmet>
			<Snackbar
				open={open}
				autoHideDuration={2000}
				onClose={() => setOpen(false)}
			>
				<Alert
					onClose={() => setOpen(false)}
					severity={severity}
					sx={{ width: "60%" }}
				>
					{message}
				</Alert>
			</Snackbar>
			<div
				style={{ marginTop: "-30px", display: "flex", alignItems: "center" }}
			>
				<DatePicker setDateRange={setDateRange} />
				<DateRangeComponent setDateRange={setDateRange} />
				{/* {loading && <LoadingModal loading={loading} />} */}
			</div>
			<div style={{ marginTop: "20px" }}>
				{loading && <LinearProgress value={progress} variant="determinate" />}
			</div>
			<div style={{ marginBottom: "50px" }}>
				<h2>Onboarding & User Metrics</h2>
				<Grid container spacing={3.75}>
					<Grid item xs={12} sm={6} lg={4}>
						<ColoredCard
							title={"Total Users"}
							digits={
								allMetrics.Total_Users
									? allMetrics.Total_Users.count === null
										? 0
										: allMetrics.Total_Users.count
												.toFixed()
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}
							color={"#000"}
							borderTop={"4px solid #F79031"}
							Icon={<GradingIcon style={{ fontSize: "30px" }} />}
							details={"Total spark users that have created a pin"}
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={4}>
						<ColoredCard
							title={"Incomplete"}
							hovertitle={"Incomplete"}
							digits={
								allMetrics.Incomplete
									? allMetrics.Incomplete.count === null
										? 0
										: allMetrics.Incomplete.count
												.toFixed()
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}
							color={"#62A933"}
							borderTop={"4px solid #000"}
							Icon={<GppBadOutlinedIcon style={{ fontSize: "30px" }} />}
							details={
								"Users that have verified their phone numbers only but did not create their pins"
							}
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={4}>
						<ColoredCard
							title={"Active (transacted) Users"}
							hovertitle={"transactions"}
							digits={
								numberOfTransactedUsers
									? numberOfTransactedUsers
											.toFixed()
											.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}
							color={"#000"}
							borderTop={"4px solid #F79031"}
							Icon={<PaidOutlinedIcon style={{ fontSize: "30px" }} />}
							details={
								"Users that have transacted on the platform (sent or approved request, cashin or cashout)"
							}
						/>
					</Grid>
					{/* <Grid item xs={12} sm={6} lg={3}>
						<ColoredCard
							title={"P2P Dialogs"}
							hovertitle={"P2P Dialogs"}
							digits={`coming soon...`}
							color={"#62A933"}
							borderTop={"4px solid #000"}
							Icon={<ChatOutlinedIcon style={{ fontSize: "30px" }} />}
						/>
					</Grid> */}

					<Grid item xs={12} md={6} lg={4}>
						<BorderCard
							title={"KYC Complete"}
							label={"In total"}
							digits={
								allMetrics.Kyc
									? allMetrics.Kyc.count === null
										? 0
										: allMetrics.Kyc.count
												.toFixed()
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}
							chipcolor={"info"}
							details={
								"Users that have completed their KYC process and are verified"
							}
						/>
					</Grid>
					<Grid item xs={12} md={6} lg={4}>
						<BorderCard
							title={"External Wallets"}
							label={"In total"}
							digits={
								allMetrics.Ext_Wallet
									? allMetrics.Ext_Wallet.count === null
										? 0
										: allMetrics.Ext_Wallet.count
												.toFixed()
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}
							chipcolor={"info"}
							details={
								"Users that have connected their external wallet to the platform"
							}
						/>
					</Grid>
					<Grid item xs={12} md={6} lg={4}>
						<BorderCard
							title={"KYC + External Wallets"}
							label={"In total"}
							hovertitle={"KYC n X-Ws"}
							digits={
								allMetrics.Kyc_and_Ext_Wallet
									? allMetrics.Kyc_and_Ext_Wallet.count === null
										? 0
										: allMetrics.Kyc_and_Ext_Wallet.count
												.toFixed()
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}
							chipcolor={"info"}
							details={
								"Users that have completed their KYC process and are verified and have connected their external wallet to the platform"
							}
						/>
					</Grid>
					<Grid item xs={12} md={6} lg={4}>
						<SimpleCard
							title={"User Logins"}
							label={"Up"}
							digits={
								allMetrics.User_Logins
									? allMetrics.User_Logins.count === null
										? 0
										: allMetrics.User_Logins.count
												.toFixed()
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}
							details={"Total number of user logins"}
						/>
					</Grid>
					<Grid item xs={12} md={6} lg={4}>
						<SimpleCard
							title={"Active User Logins*"}
							label={"Up"}
							digits={
								numberOfTimesActiveUsersLoggedIn
									? numberOfTimesActiveUsersLoggedIn
											.toFixed()
											.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}
							details={
								"Total number of times active(transacted) users that have logged into the platform. Active (transacted) being at least one approved request, send money etc"
							}
						/>
					</Grid>
					<Grid item xs={12} md={6} lg={4}>
						<SimpleCard
							title={"Average Session Time"}
							label={"Up"}
							digits={
								averageSessionTime
									? averageSessionTime
											?.toFixed(2)
											.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}
							details={
								"Average time spent by unique (transacted) users on the platform per unique login (In Hours)"
							}
						/>
					</Grid>
				</Grid>
			</div>
			<div
				style={{
					marginBottom: "50px",
					backgroundColor: "#CCE",
					padding: "20px",
					borderRadius: "30px",
				}}
			>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						flexDirection: "row",
					}}
				>
					<h2>Top 10 Most Active Users</h2>
					<HelpOutlineRoundedIcon
						style={{ fontSize: "15px", cursor: "pointer", color: "#000" }}
						aria-owns={open ? "mouse-over-popover" : undefined}
						aria-haspopup="true"
						onMouseEnter={handlePopoverOpen}
						onMouseLeave={handlePopoverClose}
					/>
				</div>
				<Table1
					rows={mostActiveUsersData}
					columns={columns}
					viewState={view}
					setViewState={setView}
				/>
			</div>
			<div
				style={{
					marginBottom: "50px",
					backgroundColor: "#FFF2E1",
					padding: "20px",
					borderRadius: "30px",
				}}
			>
				<h2>Club Metrics</h2>
				<Grid container spacing={3.75}>
					<Grid item xs={12} sm={6} lg={4}>
						<BorderCard
							title={"Clubs"}
							label={"In total"}
							digits={
								allMetrics.Total_Clubs
									? allMetrics.Total_Clubs.count === null
										? 0
										: allMetrics.Total_Clubs.count
												.toFixed()
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}
							chipcolor={"primary"}
							details={"Total number of clubs that have been created"}
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={4}>
						<BorderCard
							title={"Active(transacted) Clubs"}
							label={"In total"}
							digits={
								allMetrics.Active_Clubs
									? allMetrics.Active_Clubs.count === null
										? 0
										: allMetrics.Active_Clubs.count
												.toFixed()
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}
							chipcolor={"primary"}
							details={
								"Total number of active (transacted) clubs on Spark. Active (transacted) being at least one approved request, send money etc"
							}
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={4}>
						<BorderCard
							title={"Club Transactions (#)"}
							label={"In total"}
							digits={
								allMetrics.Club_Transactions_Count
									? allMetrics.Club_Transactions_Count.count === null
										? 0
										: allMetrics.Club_Transactions_Count.count
												.toFixed()
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}
							chipcolor={"primary"}
							details={
								"Total number of all transactions in Clubs only (Cash in, Cash out, Sent, Requested). NOTE: This is for approved requests only"
							}
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={4}>
						<SimpleCard
							title={"Average Transactions"}
							digits={
								allMetrics.Avg_Transactions_Per_Active_Club
									? allMetrics.Avg_Transactions_Per_Active_Club.count === null
										? 0
										: allMetrics.Avg_Transactions_Per_Active_Club.count
												.toFixed(2)
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}
							details={"Average number of transactions per active club"}
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={4}>
						<SimpleCard
							title={"Users In Active Clubs"}
							digits={
								allMetrics.Users_In_Active_Clubs
									? allMetrics.Users_In_Active_Clubs.count === null
										? 0
										: allMetrics.Users_In_Active_Clubs.count
												.toFixed()
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}
							details={"Total number of users in active clubs"}
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={4}>
						<SimpleCard
							title={"1 Member Active Clubs"}
							digits={
								allMetrics.One_Member_Active_Clubs
									? allMetrics.One_Member_Active_Clubs.count === null
										? 0
										: allMetrics.One_Member_Active_Clubs.count
												.toFixed()
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}
							details={"Total active clubs with only one member"}
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={3}>
						<ColoredCard
							title={"Avg Invites"}
							digits={
								allMetrics.Avg_Total_Invites_On_Club_Create
									? allMetrics.Avg_Total_Invites_On_Club_Create.count === null
										? 0
										: allMetrics.Avg_Total_Invites_On_Club_Create.count
												.toFixed(2)
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}
							color={"#000"}
							Icon={
								<FormatIndentDecreaseOutlinedIcon
									style={{ fontSize: "30px" }}
								/>
							}
							details={
								"Average number of invites on club creation (active or not)"
							}
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={3}>
						<ColoredCard
							title={"Avg Time I.V Accepted"}
							digits={
								allMetrics.Avg_Time_Invites_Accptd_On_Club_Create
									? allMetrics.Avg_Time_Invites_Accptd_On_Club_Create.count ===
									  null
										? 0
										: allMetrics.Avg_Time_Invites_Accptd_On_Club_Create.count
												.toFixed(2)
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}
							color={"#000"}
							Icon={
								<HistoryToggleOffOutlinedIcon style={{ fontSize: "30px" }} />
							}
							details={
								"Average time it takes for an invite to be accepted on club creation (in minutes)"
							}
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={3}>
						<ColoredCard
							title={"Accepted Club Invites"}
							digits={`${
								allMetrics.Acceptance_Rate_On_Club_Create
									? allMetrics.Acceptance_Rate_On_Club_Create.count === null
										? 0
										: allMetrics.Acceptance_Rate_On_Club_Create.count
												.toFixed(2)
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}%`}
							color={"#000"}
							Icon={<BeenhereOutlinedIcon style={{ fontSize: "30px" }} />}
							details={"Percentage of accepted invites on club creation"}
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={3}>
						<ColoredCard
							title={"Total ₵ in club wallets"}
							digits={`₵${
								allMetrics.Total_Club_Wallets_Volume
									? allMetrics.Total_Club_Wallets_Volume === null || ""
										? 0
										: allMetrics.Total_Club_Wallets_Volume.toFixed(2).replace(
												/\B(?=(\d{3})+(?!\d))/g,
												","
										  )
									: "0"
							}`}
							color={"#000"}
							Icon={<PriceCheckOutlinedIcon style={{ fontSize: "30px" }} />}
							details={"Total money (in ₵edis) in club wallets"}
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={3}>
						<ColoredCard
							title={"Avg Act. Users /Act. Club"}
							digits={
								allMetrics.Avg_Active_Users_Per_Active_Club
									? allMetrics.Avg_Active_Users_Per_Active_Club.count === null
										? 0
										: allMetrics.Avg_Active_Users_Per_Active_Club.count
												.toFixed(2)
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}
							color={"#000"}
							Icon={<DonutSmallOutlinedIcon style={{ fontSize: "30px" }} />}
							details={"Average number of active users per active club"}
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={3}>
						<ColoredCard
							title={"Avg Users P/Active Club"}
							digits={
								allMetrics.Avg_Users_Per_Active_Club
									? allMetrics.Avg_Users_Per_Active_Club.count === null
										? 0
										: allMetrics.Avg_Users_Per_Active_Club.count
												.toFixed(2)
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}
							color={"#000"}
							Icon={
								<FiberSmartRecordOutlinedIcon style={{ fontSize: "30px" }} />
							}
							details={"Average number of users per active club"}
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={3}>
						<ColoredCard
							title={"Avg ₵ Received P2P p/user"}
							digits={"coming soon..."}
							color={"#000"}
							Icon={<StarHalfOutlinedIcon style={{ fontSize: "30px" }} />}
							details={"Average amount of money in ₵edis received per user"}
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={3}>
						<ColoredCard
							title={"Avg Trans P/Active Club"}
							digits={
								allMetrics.Avg_Transactions_Per_Active_Club
									? allMetrics.Avg_Transactions_Per_Active_Club.count === null
										? 0
										: allMetrics.Avg_Transactions_Per_Active_Club.count
												.toFixed(2)
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}
							color={"#000"}
							Icon={<HomeMiniOutlinedIcon style={{ fontSize: "30px" }} />}
							details={"Average number of transactions per active club"}
						/>
					</Grid>

					<Grid item xs={12} md={6} lg={4}>
						<SimpleCard
							title={"Total ₵ Sent To Members"}
							digits={`₵${
								allMetrics.Send_Money_Club_Volume
									? allMetrics.Send_Money_Club_Volume.volume === null
										? 0
										: allMetrics.Send_Money_Club_Volume.volume
												.toFixed(2)
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}`}
							color={"#000"}
							Icon={<DirectionsOutlinedIcon style={{ fontSize: "30px" }} />}
							details={
								"Total amount of money in ₵edis sent to members in a club"
							}
						/>
					</Grid>
					{/* <Grid item xs={12} md={6} lg={4}>
						<SimpleCard
							title={"New Clubs"}
							label={"Up"}
							digits={
								allMetrics.Total_Clubs
									? allMetrics.Total_Clubs.count === null
										? 0
										: allMetrics.Total_Clubs.count
												.toFixed()
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}
						/>
					</Grid> */}
					<Grid item xs={12} md={6} lg={4}>
						<SimpleCard
							title={"Total Approved Money Requests"}
							digits={`₵${
								allMetrics.Club_Money_Request_Approved_Volume
									? allMetrics.Club_Money_Request_Approved_Volume.volume ===
									  null
										? 0
										: allMetrics.Club_Money_Request_Approved_Volume.volume
												.toFixed(2)
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}`}
							details={
								"Total amount of money in ₵edis requested by members in a club. NOTE: This is for approved requests only"
							}
						/>
					</Grid>
					<Grid item xs={12} md={6} lg={4}>
						<SimpleCard
							title={"Total Club Transactions"}
							digits={`₵${
								allMetrics.Club_Transactions_Volume
									? allMetrics.Club_Transactions_Volume.volume === null
										? 0
										: allMetrics.Club_Transactions_Volume.volume
												.toFixed(2)
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}`}
							details={"Total amount of money in ₵edis transacted in a club"}
						/>
					</Grid>
					<Grid item xs={12} md={6} lg={4}>
						<SimpleCard
							title={"Avg Users Per Club"}
							digits={
								allMetrics.Avg_Users_Per_Club
									? allMetrics.Avg_Users_Per_Club.count === null
										? 0
										: allMetrics.Avg_Users_Per_Club.count
												.toFixed(2)
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}
							details={"Average number of users per club"}
						/>
					</Grid>
					{/* <Grid item xs={12} md={6} lg={4}>
						<SimpleCard
							title={"Users in Clubs"}
							digits={
								allMetrics.Users_In_Active_Clubs
									? allMetrics.Users_In_Active_Clubs.count === null
										? 0
										: allMetrics.Users_In_Active_Clubs.count
												.toFixed(2)
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}
						/>
					</Grid> */}
				</Grid>
			</div>
			<div
				style={{
					marginBottom: "50px",
					// backgroundColor: "#C1FFC1",
					backgroundColor: "#D2FBA4",
					padding: "20px",
					borderRadius: "30px",
				}}
			>
				<h2>Transaction Metrics</h2>
				<Grid container spacing={3.75}>
					<Grid item xs={12} sm={6} lg={3}>
						<ColoredCard
							title={"Total Money Sent(#)"}
							digits={`${
								allMetrics.Total_Money_Sent_Count
									? allMetrics.Total_Money_Sent_Count.count === null
										? 0
										: allMetrics.Total_Money_Sent_Count.count
												.toFixed()
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}`}
							color={"#000"}
							borderTop={"4px solid grey"}
							Icon={
								<InsertChartOutlinedTwoToneIcon style={{ fontSize: "30px" }} />
							}
							details={"Total number of times money got sent in Spark"}
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={3}>
						<ColoredCard
							title={"Total Money Sent(₵)"}
							digits={`₵${
								allMetrics.Total_Money_Sent_Volume
									? allMetrics.Total_Money_Sent_Volume.volume === null
										? 0
										: allMetrics.Total_Money_Sent_Volume.volume
												.toFixed(2)
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}`}
							color={"#000"}
							borderTop={"4px solid #62A944"}
							Icon={
								<InsertChartOutlinedTwoToneIcon style={{ fontSize: "30px" }} />
							}
							details={"Total amount of money sent in Spark"}
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={3}>
						<ColoredCard
							title={"Total Approved Money Requests(#)"}
							digits={`${
								allMetrics.Total_Money_Requested_Count
									? allMetrics.Total_Money_Requested_Count.count === null
										? 0
										: allMetrics.Total_Money_Requested_Count.count
												.toFixed()
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}`}
							color={"#000"}
							borderTop={"4px solid grey"}
							Icon={<TrendingUpOutlinedIcon style={{ fontSize: "30px" }} />}
							details={
								"Total number of times money got requested in Spark. NOTE: This is for approved requests only"
							}
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={3}>
						<ColoredCard
							title={"Total Approved Money Requests(₵)"}
							digits={`₵${
								allMetrics.Total_Money_Requested_Volume
									? allMetrics.Total_Money_Requested_Volume.volume === null
										? 0
										: allMetrics.Total_Money_Requested_Volume.volume
												.toFixed(2)
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}`}
							color={"#000"}
							borderTop={"4px solid #F79031"}
							Icon={<TrendingUpOutlinedIcon style={{ fontSize: "30px" }} />}
							details={
								"Total amount of money requested in Spark. NOTE: This is for approved requests only"
							}
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={3}>
						<ColoredCard
							title={"Money Request P2P(#)"}
							digits={`${
								allMetrics?.Request_Money_P2P
									? allMetrics.Request_Money_P2P.count === null
										? 0
										: allMetrics.Request_Money_P2P.count
												.toFixed()
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}`}
							color={"#000"}
							borderTop={"4px solid grey"}
							Icon={
								<ConnectWithoutContactOutlinedIcon
									style={{ fontSize: "30px" }}
								/>
							}
							details={
								"P2P only - Total number of times money got requested in Spark. NOTE: This is for approved requests only"
							}
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={3}>
						<ColoredCard
							title={"Money Request P2P(₵)"}
							digits={`₵${
								allMetrics?.Request_Money_P2P
									? allMetrics.Request_Money_P2P.volume === null
										? 0
										: allMetrics.Request_Money_P2P.volume
												.toFixed(2)
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}`}
							color={"#000"}
							borderTop={"4px solid #F79031"}
							Icon={<OutboundOutlinedIcon style={{ fontSize: "30px" }} />}
							details={
								"P2P only - Total amount of money requested in Spark. NOTE: This is for approved requests only"
							}
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={3}>
						<ColoredCard
							title={"Money Sent P2P(#)"}
							digits={
								allMetrics.Money_Sent_P2P_Count
									? allMetrics.Money_Sent_P2P_Count.count === null
										? 0
										: allMetrics.Money_Sent_P2P_Count.count
												.toFixed()
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}
							color={"#000"}
							borderTop={"4px solid grey"}
							Icon={<LogoutOutlinedIcon style={{ fontSize: "30px" }} />}
							details={
								"P2P only - Total number of times money got sent in Spark"
							}
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={3}>
						<ColoredCard
							title={"Money Sent P2P(₵)"}
							digits={`₵coming soon...`}
							color={"#000"}
							borderTop={"4px solid #62A944"}
							Icon={<AltRouteOutlinedIcon style={{ fontSize: "30px" }} />}
							details={"P2P only - Total amount of money sent in Spark"}
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={3}>
						<ColoredCard
							title={"Spark Topup(#)"}
							digits={
								allMetrics.Deposit_Count
									? allMetrics.Deposit_Count.count === null
										? 0
										: allMetrics.Deposit_Count.count
												.toFixed()
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}
							color={"#000"}
							borderTop={"4px solid grey"}
							Icon={<LocalShippingOutlinedIcon style={{ fontSize: "30px" }} />}
							details={
								"Total number of times money got deposited in Spark from external wallets"
							}
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={3}>
						<ColoredCard
							title={"Spark Topup(₵)"}
							digits={`₵${
								allMetrics.Deposit_Volume
									? allMetrics.Deposit_Volume.volume === null
										? 0
										: allMetrics.Deposit_Volume.volume
												.toFixed(2)
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}`}
							color={"#000"}
							borderTop={"4px solid #62A944"}
							Icon={<ArrowDownwardOutlinedIcon style={{ fontSize: "30px" }} />}
							details={
								"Total amount of money deposited in Spark from external wallets"
							}
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={3}>
						<ColoredCard
							title={"Spark Withdrawal(#)"}
							digits={`${
								allMetrics.Withdraw_Count
									? allMetrics.Withdraw_Count.count === null
										? 0
										: allMetrics.Withdraw_Count.count
												.toFixed()
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}`}
							color={"#000"}
							borderTop={"4px solid grey"}
							Icon={<ArrowUpwardOutlinedIcon style={{ fontSize: "30px" }} />}
							details={
								"Total number of times money got withdrawn from Spark to external wallets"
							}
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={3}>
						<ColoredCard
							title={"Spark Withdrawal(₵)"}
							digits={`₵${
								allMetrics.Withdraw_Volume
									? allMetrics.Withdraw_Volume.volume === null
										? 0
										: allMetrics.Withdraw_Volume.volume
												.toFixed(2)
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}`}
							color={"#000"}
							borderTop={"4px solid #F79031"}
							Icon={<OutboxOutlinedIcon style={{ fontSize: "30px" }} />}
							details={
								"Total amount of money withdrawn from Spark to external wallets"
							}
						/>
					</Grid>
				</Grid>
			</div>
			<div>
				<h2>Money</h2>
				<Grid container spacing={3.75}>
					<Grid item xs={12} sm={6} lg={4}>
						<FilledCard
							bgColor={"#62A944"}
							color={"#E06481"}
							digits={`₵${
								allMetrics.Cash_Held_On_Spark
									? allMetrics.Cash_Held_On_Spark.amountHeldOnSpark === null
										? 0
										: allMetrics.Cash_Held_On_Spark.amountHeldOnSpark
												.toFixed(2)
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "0"
							}`}
							// digits={"₵0"}
							title={"Cumm. Cash Balance On Spark"}
							Icon={"/images/icons/ghana-cedis-white-icon.png"}
							textcolor={"#fff"}
							details={
								"This is indicates the cummulative cash balance on spark which is defined as cumulative topups minus withdrawals"
							}
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={4}>
						<FilledCard
							bgColor={"#F79031"}
							digits={`₵${
								allMetrics.All_InSpark_Transactions_Volume
									? allMetrics.All_InSpark_Transactions_Volume.volume === null
										? 0
										: allMetrics.All_InSpark_Transactions_Volume.volume
												.toFixed(2)
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}`}
							title={"In-Spark Transactions"}
							Icon={"/images/icons/ghana-cedis-icon.png"}
							textcolor={"#000"}
							details={
								"Total amount of money transacted on Spark(send or approved request, whether in-Club or not, cashin or cashout)."
							}
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={4}>
						<FilledCard
							bgColor={"#000000"}
							color={"#282C35"}
							digits={`₵${
								totalTransactionVolumes
									? totalTransactionVolumes
											.toFixed(2)
											.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									: "..."
							}`}
							title={"Total Transaction Volumes"}
							Icon={"/images/icons/ghana-cedis-white-icon.png"}
							textcolor={"#fff"}
							details={
								"Total amount of money transacted on Spark(send or approved request, whether in-Club or not, cashin or cashout)."
							}
						/>
					</Grid>
				</Grid>
			</div>
			<Popovers
				open={openAnchor}
				anchorEl={anchorEl}
				handlePopoverClose={handlePopoverClose}
				details={
					"This table shows a list of the top 10 most active(transacted) users with the sum of amount transacted and the frequency"
				}
			/>
		</div>
	);
};

export default AdminDashboard;
