import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import {
	Alert,
	Box,
	Button,
	Snackbar,
	Modal,
	Typography,
	Chip,
	LinearProgress,
	Popover,
} from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { NotificationsAPI } from "app/routes/middleware";
import Div from "@jumbo/shared/Div";
import { NotifsModalStyle } from "app/utils/constants/styles";
import { Columns } from "./columns";

const Notifications = () => {
	const [loading, setLoading] = React.useState(true);
	const [progress, setProgress] = React.useState(0);
	const [rows, setRows] = React.useState([]);
	const [rowModesModel, setRowModesModel] = React.useState({});
	const [numberOfItems, setNumberOfItems] = React.useState(10);
	const [open, setOpen] = React.useState(false);
	const [message, setMessage] = React.useState("");
	const [severity, setSeverity] = React.useState("success");
	const [notification, setNotification] = React.useState({});
	const [openModal, setOpenModal] = React.useState(false);
	const [edit, setEdit] = React.useState({
		description: "",
		value: "",
		title: "",
	});
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopOverClose = () => {
		setAnchorEl(null);
	};

	const openpopover = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const handleModalClose = () => setOpenModal(false);

	const handleModalOpen = async () => {
		setOpenModal(true);
	};

	const actions = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 100,
			cellClassName: "actions",
			getActions: (params) => {
				let items = params.row;
				return [
					<GridActionsCellItem
						icon={<EditIcon />}
						label="Edit"
						className="textPrimary"
						onClick={async () => {
							setNotification({
								id: items.id,
								value: items.value,
								description: items.description,
								type: items.type,
								key: items.key,
								scope: items.scope,
								status: items.status,
								title: items.title,
								owner: items.owner,
							});
							handleModalOpen();
						}}
						color="inherit"
					/>,
				];
			},
		},
	];

	React.useEffect(() => {
		handleNotificationsLoad();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		const timer = setInterval(() => {
			setProgress((oldProgress) => {
				if (oldProgress === 100) {
					return 0;
				}
				const diff = Math.random() * 10;
				return Math.min(oldProgress + diff, 100);
			});
		}, 500);

		return () => {
			clearInterval(timer);
		};
	}, []);

	const handleNotificationsLoad = async () => {
		try {
			setLoading(true);
			const request = await NotificationsAPI.get();
			const response = request.data.data;
			// console.log(request, "notifs data");
			if (response && response.length) {
				setLoading(false);
				setRows(response);
			}
		} catch (error) {
			setLoading(false);
			if (error?.response?.status === 401) {
				setMessage(
					"Sorry your sign in token has expired, please sign in again to continue using Spark Admin 🤭"
				);
				setSeverity("error");
				setOpen(true);
				localStorage.clear("@spark-session");
				window.location.replace("/sign-in");
			} else if (error?.response?.status === 210) {
				setMessage("Please check your internet connection 😅");
				setSeverity("error");
				setOpen(true);
				localStorage.clear("@spark-session");
				window.location.replace("/sign-in");
			} else {
				setMessage(
					"Sorry, there was an error loading the roles, please check your internet connection, refresh or sign out and sign in again 😅"
				);
				setSeverity("error");
				setOpen(true);
			}
		}
	};

	const handleNotificationUpdate = async (e) => {
		setAnchorEl(null);
		setLoading(true);
		e.preventDefault();
		console.log(edit, "edits");
		const { title, description, value } = edit;
		if (!description && !value) {
			setLoading(false);
			setMessage("Please edit at least one field to update");
			setSeverity("error");
			setOpen(true);
			return;
		}
		let scope = notification?.scope || "";
		let payload = {
			type: notification?.type,
			key: notification?.key,
			scope: scope.split(","),
			status: notification?.status,
			title: title ? title : notification?.title,
			owner: notification.owner,
			description: description ? description : notification?.description,
			value: value ? value : notification?.value,
		};
		try {
			const request = await NotificationsAPI.put(payload, notification?.id);
			if (request.status === 200) {
				setLoading(false);
				setMessage("Notification updated successfully");
				setSeverity("success");
				setOpen(true);
				handleNotificationsLoad();
				handleModalClose();
			}
			console.log(request, "put request");
		} catch (error) {
			setLoading(false);
			if (error?.response?.status === 401) {
				setMessage(
					"Sorry your sign in token has expired, please sign in again to continue using Spark Admin 🤭"
				);
				setSeverity("error");
				setOpen(true);
				localStorage.clear("@spark-session");
				window.location.replace("/sign-in");
			} else if (error?.response?.status === 210) {
				setMessage("Please check your internet connection 😅");
				setSeverity("error");
				setOpen(true);
				localStorage.clear("@spark-session");
				window.location.replace("/sign-in");
			} else {
				setMessage(
					"Sorry, there was an error loading the roles, please check your internet connection, refresh or sign out and sign in again 😅"
				);
				setSeverity("error");
				setOpen(true);
			}
		}
	};

	return (
		<div>
			<Snackbar
				open={open}
				autoHideDuration={3000}
				onClose={() => setOpen(false)}
			>
				<Alert
					onClose={() => setOpen(false)}
					severity={severity}
					sx={{ width: "60%" }}
				>
					{message}
				</Alert>
			</Snackbar>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					marginTop: "-30px",
				}}
			>
				<h2>Manage Notifications</h2>
			</div>
			{loading && (
				<LinearProgress
					value={progress}
					variant="determinate"
					sx={{ mt: -1, mb: 1 }}
				/>
			)}
			<Box
				sx={{
					backgroundColor: "background.paper",
					borderRadius: 3,
					height: 500,
					width: "100%",
					"& .actions": {
						color: "text.secondary",
					},
					"& .textPrimary": {
						color: "text.primary",
					},
				}}
			>
				<DataGrid
					loading={rows.length === 0}
					style={{ padding: "0px 20px", borderRadius: "10px" }}
					rows={rows}
					columns={[
						...Columns.map(
							(obj) => actions.find((o) => o.field === obj.field) || obj
						),
					]}
					editMode="row"
					rowModesModel={rowModesModel}
					onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
					componentsProps={{
						toolbar: { setRows, setRowModesModel },
					}}
					experimentalFeatures={{ newEditingApi: true }}
					pageSize={numberOfItems}
					pagination={true}
					onPageSizeChange={(pageSize) => {
						setNumberOfItems(pageSize);
					}}
					rowsPerPageOptions={[10, 15, 20]}
					onProcessRowUpdateError={(error) => {
						// console.log(error, "error");
					}}
				/>
			</Box>

			<Modal
				open={openModal}
				onClose={handleModalClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={NotifsModalStyle}>
					<Div sx={{ mb: 2 }}>
						<Typography variant="h2" sx={{ mb: 2, fontWeight: 600 }}>
							Edit Notification
						</Typography>
						<Typography id="modal-modal-title" variant="h6" component="h2">
							Notification Key:{" "}
							<Chip
								sx={{ color: "green" }}
								label={notification?.key}
								title={notification?.key}
							/>
						</Typography>
						<Typography id="modal-modal-title" variant="h6" component="h2">
							Notification Type:{" "}
							<Chip label={notification?.type} title={notification?.type} />
						</Typography>
					</Div>
					<form
					// onSubmit={ handleNotificationUpdate }
					>
						<Div sx={{ mb: 2, color: "primary.main" }}>
							<label htmlFor="description">
								Title 👇🏼{" "}
								<i style={{ fontWeight: 400, fontSize: "11px", color: "red" }}>
									{" "}
									{notification?.title
										? "Click to begin editing"
										: "No title. Click to begin editing"}
								</i>
							</label>
							<textarea
								style={{ width: "100%" }}
								name="title"
								id="title"
								defaultValue={notification?.title ? notification?.title : ""}
								onChange={(event) =>
									setEdit({ ...edit, title: event.target.value })
								}
								rows={3}
								cols={50}
							/>
						</Div>
						<Div sx={{ mb: 2, color: "secondary.main" }}>
							<label htmlFor="description">
								Description 👇🏼{" "}
								<i style={{ fontWeight: 400, fontSize: "11px", color: "red" }}>
									{" "}
									{notification?.description
										? "Click to begin editing"
										: "No description. Click to begin editing"}
								</i>
							</label>
							<textarea
								style={{ width: "100%" }}
								name="description"
								id="description"
								defaultValue={
									notification?.description ? notification?.description : ""
								}
								onChange={(event) =>
									setEdit({ ...edit, description: event.target.value })
								}
								rows={3}
								cols={50}
							/>
						</Div>
						<Div sx={{ color: "blue" }}>
							<label htmlFor="value">
								Notification Value 👇🏼{" "}
								<i style={{ fontWeight: 400, fontSize: "11px", color: "red" }}>
									{notification?.value
										? "Click to begin editing"
										: "No value. Click to begin editing"}
								</i>
							</label>

							<textarea
								style={{ width: "100%" }}
								id="value"
								name="value"
								defaultValue={notification?.value ? notification?.value : ""}
								onChange={(event) => {
									setEdit({
										...edit,
										value: event.target.value,
									});
								}}
								rows={10}
								cols={50}
							/>
						</Div>

						<Div
							sx={{
								display: "flex",
								flexDirection: "column",
								width: "100%",
								mt: 1,
							}}
						>
							<Button
								sx={{
									mt: 2,
									borderColor: "red",
									color: "red",
									width: "100%",
									py: 1.5,
								}}
								variant="outlined"
								color="secondary"
								onClick={handleModalClose}
							>
								Close Notification
							</Button>
							<Button
								sx={{ mt: 2, width: "100%", py: 1.5 }}
								variant="contained"
								color="secondary"
								onClick={handlePopoverOpen}
							>
								Update Notification
							</Button>
						</Div>
						<Popover
							id={id}
							open={openpopover}
							anchorEl={anchorEl}
							onClose={handlePopOverClose}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
						>
							<Typography sx={{ p: 2 }}>
								Are you sure you want to update this notification?
							</Typography>
							<Div
								sx={{
									display: "flex",
									// alignItems: "center",
									justifyContent: "space-between",
									mt: 2,
									px: 2,
									pb: 2,
								}}
							>
								<Button
									sx={{ width: "50%", py: 1.5, fontSize: "10px" }}
									variant="outlined"
									color="secondary"
									type="submit"
									onClick={handleNotificationUpdate}
								>
									{loading ? "Updating..." : "Yes,Update"}
								</Button>
								<Button
									sx={{ width: "50%", py: 1.5, fontSize: "10px", ml: 1 }}
									variant="contained"
									color="error"
									onClick={handlePopOverClose}
								>
									No, Continue Edit
								</Button>
							</Div>
						</Popover>
					</form>
				</Box>
			</Modal>
		</div>
	);
};

export default Notifications;
