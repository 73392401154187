import React, { useState } from "react";
import {
	Box,
	Button,
	Checkbox,
	FormControl,
	IconButton,
	InputLabel,
	ListItemText,
	MenuItem,
	Modal,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, useForm } from "react-hook-form";
import { AdminDealsAPI } from "app/routes/middleware";
import { STATUSCODES } from "app/utils/appHelpers";

const AddExistingProductToCatalog = ({
	isOpenAddExistingItems,
	setIsOpenAddExistingItems,
	catalogId,
	setMessage,
	setSeverity,
	setOpenSnackbar,
	setIsCatalogUpdateSuccess,
}) => {
	const [selectedItems, setSelectedItems] = useState([]);
	// const [isAddExistingItems, setIsAddExistingItems] = useState(null);
	const [editedItemVendorPrice, setEditedItemVendorPrice] = useState({});
	const [editedItemSellingPrice, setEditedItemSellingPrice] = useState({});
	const [editedItemAvailableItems, setEditedItemAvailableItems] = useState({});
	const [isItemUpdateSuccess, setIsItemUpdateSuccess] = useState(false);
	const [fetchedItems, setFetchedItems] = React.useState([]);
	const [isLoading, setIsLoading] = React.useState(false);
	const [isAddExistingItemsToCatalog, setIsAddExistingItemsToCatalog] =
		useState(false);

	React.useEffect(() => {
		handleGetItems();
		if (isItemUpdateSuccess === true) {
			handleGetItems();
		}
	}, [isItemUpdateSuccess]);

	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
	} = useForm();

	const handleEditModalClose = () => {
		setIsOpenAddExistingItems(false);
		setSelectedItems([]);
		setEditedItemVendorPrice({});
		setEditedItemSellingPrice({});
		setEditedItemAvailableItems({});
		setIsAddExistingItemsToCatalog(false);
	};

	const handleGetItems = async () => {
		setIsLoading(true);
		try {
			const request = await AdminDealsAPI.getItems();
			const response = request.data.data;
			setFetchedItems(response);
			setIsLoading(false);
			console.log("Items:", response);
		} catch (error) {
			console.log(error);
			setIsLoading(false);
		}
	};

	const handleEditedItemChange = (itemId, field, value) => {
		if (field === "vendorPrice") {
			setEditedItemVendorPrice((prev) => ({
				...prev,
				[itemId]: value,
			}));
		} else if (field === "sellingPrice") {
			setEditedItemSellingPrice((prev) => ({
				...prev,
				[itemId]: value,
			}));
		} else if (field === "availableItems") {
			setEditedItemAvailableItems((prev) => ({
				...prev,
				[itemId]: value,
			}));
		}
	};

	const mergeEditedAndSelectedItems = () => {
		return selectedItems.map((item) => {
			const editedVendorPrice = parseInt(editedItemVendorPrice[item.id]);
			const editedSellingPrice = parseInt(editedItemSellingPrice[item.id]);
			const editedAvailableItems = parseInt(editedItemAvailableItems[item.id]);

			return {
				id: item.id,
				vendorPrice: editedVendorPrice ? editedVendorPrice : item.vendorPrice,
				sellingPrice: editedSellingPrice
					? editedSellingPrice
					: item.sellingPrice,
				availableItems: editedAvailableItems
					? editedAvailableItems
					: item.availableItems,
				discount: editedVendorPrice
					? parseFloat(
							(
								((editedVendorPrice - editedSellingPrice) / editedVendorPrice) *
								100
							).toFixed()
					  )
					: parseFloat(
							(
								((item.vendorPrice - item.sellingPrice) / item.vendorPrice) *
								100
							).toFixed()
					  ),
			};
		});
	};

	const toggleEditMode = (itemId) => {
		setSelectedItems((prevSelectedItems) =>
			prevSelectedItems.map((item) =>
				item.id === itemId
					? {
							...item,
							editMode: !item.editMode,
					  }
					: item
			)
		);
	};

	const handleUpdateCatalogProduct = async () => {
		setIsAddExistingItemsToCatalog(true);
		console.log(mergeEditedAndSelectedItems(), "merged stuff");
		try {
			const request = await AdminDealsAPI.updateCatalog({
				id: catalogId,
				payload: { items: mergeEditedAndSelectedItems() },
			});
			if (STATUSCODES.SUCCESS.includes(request.status)) {
				handleEditModalClose();

				setIsCatalogUpdateSuccess(true);
				setOpenSnackbar(true);
				setMessage(`Items created successfully`);
				setSeverity("success");
			}
			setIsCatalogUpdateSuccess(false);
		} catch (error) {
			// console.log( error, "error" );
			setIsAddExistingItemsToCatalog(false);
			if (STATUSCODES.CLIENT_ERROR.includes(error?.response?.status)) {
				setMessage(
					`Error creating item(s). Please make sure you've entered the right details or please reach out to tech support`
				);
				setSeverity("error");
				setOpenSnackbar(true);
			} else if (STATUSCODES.SERVER_ERROR.includes(error?.response?.status)) {
				setMessage(
					"Sorry this is from us, please try again later or reach out to tech support"
				);
				setSeverity("error");
				setOpenSnackbar(true);
			}
		}
	};

	return (
		<Modal open={isOpenAddExistingItems}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: 500,
					bgcolor: "background.paper",
					boxShadow: 24,
					p: 4,
					borderRadius: "8px",
					outline: "none",
					overflow: "auto",
					maxHeight: "95vh",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						marginBottom: "20px",
					}}
				>
					<Typography variant="h5" sx={{ fontWeight: 600 }}>
						Add Existing Items To Catalog
					</Typography>
					<IconButton onClick={handleEditModalClose}>
						<CloseIcon />
					</IconButton>
				</div>
				<div>
					{/* Existing Items section */}
					{isOpenAddExistingItems === true && (
						<Controller
							name="items"
							control={control}
							render={({ field }) => (
								<FormControl fullWidth>
									<InputLabel id="demo-multiple-checkbox-label">
										Add existing items to catalog
									</InputLabel>
									<Select
										labelId="demo-multiple-checkbox-label"
										id="demo-multiple-checkbox"
										multiple
										label="	Add existing items to catalog"
										value={selectedItems.map((item) => item.id)} // Use IDs as values
										onChange={(e) => {
											const selectedIds = e.target.value;
											const selectedItemsData = selectedIds.map(
												(selectedId) => {
													const selectedItem = fetchedItems.find(
														(item) => item.id === selectedId
													);
													const itemCatalog = selectedItem.itemCatalog[0]; // Assuming it's an array with one item
													return {
														id: selectedItem.id,
														name: selectedItem.name,
														description: selectedItem.description,
														sellingPrice: itemCatalog.sellingPrice,
														vendorPrice: itemCatalog.vendorPrice,
														availableItems: itemCatalog.availableItems,
														discount: (
															((itemCatalog.vendorPrice -
																itemCatalog.sellingPrice) /
																itemCatalog.vendorPrice) *
															100
														).toFixed(),
													};
												}
											);
											setSelectedItems(selectedItemsData);
											field.onChange(selectedIds); // Update the form field value
										}}
										renderValue={(selected) =>
											selected
												.map(
													(selectedId) =>
														fetchedItems.find((item) => item.id === selectedId)
															.name
												)
												.join(", ")
										}
									>
										{fetchedItems.map((option) => (
											<MenuItem key={option.id} value={option.id}>
												{/* Use option.id as value */}
												<Checkbox
													checked={selectedItems.some(
														(item) => item.id === option.id
													)}
												/>
												<ListItemText primary={option.name} />
											</MenuItem>
										))}
									</Select>
								</FormControl>
							)}
						/>
					)}
					{selectedItems.length > 0 && (
						<div>
							<Typography variant="h5" sx={{ fontWeight: "600", mt: 2 }}>
								Selected Existing Items 👇🏽
							</Typography>
							{selectedItems.map((item, index) => (
								<div key={item.id} style={{ marginTop: "20px" }}>
									<Typography variant="subtitle2">{item?.name}</Typography>
									{item.editMode ? (
										<>
											<TextField
												label="Vendor Price"
												fullWidth
												margin="normal"
												type="text"
												value={editedItemVendorPrice[item.id] || ""}
												onChange={(e) =>
													handleEditedItemChange(
														item.id,
														"vendorPrice",
														e.target.value
													)
												}
											/>
											<TextField
												label="Selling Price"
												fullWidth
												margin="normal"
												type="text"
												value={editedItemSellingPrice[item.id] || ""}
												onChange={(e) =>
													handleEditedItemChange(
														item.id,
														"sellingPrice",
														e.target.value
													)
												}
											/>
											<TextField
												label="Available Items"
												fullWidth
												margin="normal"
												type="text"
												value={editedItemAvailableItems[item.id] || ""}
												onChange={(e) =>
													handleEditedItemChange(
														item.id,
														"availableItems",
														e.target.value
													)
												}
											/>
											<Button
												variant="outlined"
												color="primary"
												sx={{ mr: 2 }}
												onClick={() => toggleEditMode(item.id)}
											>
												Close
											</Button>
											<Button
												variant="outlined"
												color="secondary"
												onClick={() => toggleEditMode(item.id)}
											>
												Save
											</Button>
										</>
									) : (
										<div style={{ marginTop: "10px" }}>
											{/* Display item details here */}
											<Button
												variant="outlined"
												color="error"
												sx={{ mr: 2 }}
												onClick={() => {
													setSelectedItems((prevSelectedItems) =>
														prevSelectedItems.filter(
															(selectedItem) => selectedItem.id !== item.id
														)
													);
												}}
											>
												Remove
											</Button>
											<Button
												variant="outlined"
												color="secondary"
												onClick={() => toggleEditMode(item.id)}
											>
												Edit
											</Button>
										</div>
									)}
								</div>
							))}
						</div>
					)}
				</div>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						mt: 3,
					}}
				>
					<Button
						variant="contained"
						color="secondary"
						disabled={selectedItems.length === 0}
						type="submit"
						fullWidth
						sx={{ height: "50px" }}
						onClick={handleUpdateCatalogProduct}
					>
						{isAddExistingItemsToCatalog === true
							? "Adding..."
							: "Add Items To Catalog"}
					</Button>
				</Box>
			</Box>
		</Modal>
	);
};

export default AddExistingProductToCatalog;
