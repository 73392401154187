import React from "react";
import Box from "@mui/material/Box";
import {
	Avatar,
	Button,
	Chip,
	FormControl,
	InputLabel,
	MenuItem,
	MenuList,
	Modal,
	Select,
	Typography,
} from "@mui/material";
import Toaster from "app/components/Toast";
import {
	CustomToolbar,
	HtmlTooltip,
	stringAvatar,
	StyledDataGrid,
} from "app/utils/appHelpers";
import { MenuProps, ModalStyle } from "app/utils/constants/styles";
import { ClubAPI } from "app/routes/middleware";
import { LoadingButton } from "@mui/lab";

const Table = ({
	rows,
	columns,
	rowModesModel,
	handleRowEditStart,
	handleRowEditStop,
	processRowUpdate,
	setrows,
	setrowmodesmodel,
	pagination,
	pageSize,
	onPageSizeChange,
	rowCount,
	disableSelectionOnClick,
}) => {
	const [toasterOptions, setToasterOptions] = React.useState({
		open: false,
		message: "",
		severity: "success",
		vertical: "bottom",
		horizontal: "center",
		showTitle: false,
		handleClose: null,
	});
	const [show, setShow] = React.useState(false);
	const [selectedMember, setSelectedMember] = React.useState(null);
	const [approversModal, setApproversModal] = React.useState(false);
	const [approvers, setApprovers] = React.useState({});
	const [selectedApprovers, setSelectedApprovers] = React.useState([]);
	const [loading, setLoading] = React.useState(false);

	const handleApproversSelectChange = (event) => {
		const value = event.target.value;
		setSelectedApprovers(value);
	};

	const handleMemberClick = (memberData) => {
		setSelectedMember(memberData);
		setShow(true);
	};

	const handleApproversClick = (data) => {
		setApprovers(data);
		// console.log("approversData", data);
		setApproversModal(true);
	};

	const handleRemoveApprovers = async () => {
		setLoading(true);
		// console.log(selectedApprovers, "selectedApprovers");
		let data = {
			clubId: approvers.clubId,
			payload: selectedApprovers,
		};
		try {
			const request = await ClubAPI.removeApprovers(data);
			if (request.status === 200) {
				setLoading(false);
				setToasterOptions({
					open: true,
					message: `${
						selectedApprovers > 1
							? "Approvers removed successfully"
							: "Approver removed successfully"
					}`,
					severity: "success",
					vertical: "bottom",
					horizontal: "center",
					showTitle: false,
					handleClose: () => {
						setToasterOptions({ open: false });
					},
				});
				setSelectedApprovers([]);
				setApprovers({});
				setApproversModal(false);
			}
		} catch (error) {
			setLoading(false);
			// console.log(error);
			setToasterOptions({
				open: true,
				message: `Something went wrong`,
				severity: "error",
				vertical: "bottom",
				horizontal: "center",
				showTitle: false,
				handleClose: () => {
					setToasterOptions({ open: false });
				},
			});
		}
	};

	const extraColumns = [
		{
			field: "clubMembers",
			headerName: "Club Members",
			width: 150,
			renderCell: (params) => {
				const clubMembers = params.row.clubMembers
					? params.row.clubMembers.map((x, i) => (
							<MenuItem
								key={i}
								style={{ fontSize: 11 }}
								onClick={() => handleMemberClick(x)}
							>
								{
									<Avatar
										{...stringAvatar(`${x?.firstName} ${x?.lastName}`)}
										src={x?.imageURL || ""}
										sx={{ width: 24, height: 24 }}
									/>
								}
								<span>
									&ensp;
									{`${x?.firstName} ${x?.lastName} ${
										x?.otherNames ? `[${x?.otherNames}]` : ""
									}`}
								</span>
							</MenuItem>
					  ))
					: [];

				return (
					<>
						{clubMembers.length === 1 ? (
							<HtmlTooltip
								placement="left-start"
								title={
									<React.Fragment>
										<MenuList style={{ overflowY: "scroll", maxHeight: 300 }}>
											{clubMembers}
										</MenuList>
									</React.Fragment>
								}
							>
								<Chip
									label={`${clubMembers.length} Member`}
									style={{ color: "#F58320", fontSize: 12 }}
									variant="outlined"
								/>
							</HtmlTooltip>
						) : clubMembers.length > 1 ? (
							<HtmlTooltip
								placement="left-start"
								title={
									<React.Fragment>
										<MenuList style={{ overflowY: "scroll", maxHeight: 300 }}>
											{clubMembers}
										</MenuList>
									</React.Fragment>
								}
							>
								<Chip
									label={`${clubMembers.length} Members`}
									style={{ color: "#F58320", fontSize: 12 }}
									variant="outlined"
								/>
							</HtmlTooltip>
						) : (
							<Chip
								label={`No Member`}
								style={{ color: "#F58320", fontSize: 12 }}
								variant="outlined"
							/>
						)}
					</>
				);
			},
		},
		{
			field: "approvers",
			headerName: "Approvers?",
			width: 140,
			renderCell: (params) => {
				// console.log(params, "approvers");
				const approvers = params?.row?.approvers?.approvers
					? params?.row?.approvers?.approvers?.map((x, i) => (
							<MenuItem
								key={i}
								style={{ fontSize: 11 }}
								// onClick={() => handleApproversClick(x)}
							>
								{
									<Avatar
										{...stringAvatar(`${x?.firstName} ${x?.lastName}`)}
										src={x?.imageURL || ""}
										sx={{ width: 24, height: 24 }}
									/>
								}
								<span>
									&ensp;
									{`${x?.firstName} ${x?.lastName} ${
										x?.otherNames ? `[${x?.otherNames}]` : ""
									}`}
								</span>
							</MenuItem>
					  ))
					: [];
				return (
					<>
						{approvers.length === 1 ? (
							<HtmlTooltip
								placement="left-start"
								title={
									<React.Fragment>
										<MenuList style={{ overflowY: "scroll", maxHeight: 300 }}>
											{approvers}
										</MenuList>
									</React.Fragment>
								}
							>
								<Button
									variant="outlined"
									style={{ fontSize: 11 }}
									onClick={() =>
										handleApproversClick({
											data: params?.row?.approvers?.approvers,
											clubId: params?.row?.id,
											clubName: params?.row?.name,
										})
									}
								>
									{approvers.length} Approver
								</Button>
							</HtmlTooltip>
						) : approvers.length > 1 ? (
							<HtmlTooltip
								placement="left-start"
								title={
									<React.Fragment>
										<MenuList style={{ overflowY: "scroll", maxHeight: 300 }}>
											{approvers}
										</MenuList>
									</React.Fragment>
								}
							>
								<Button
									variant="outlined"
									style={{ fontSize: 11 }}
									onClick={() =>
										handleApproversClick({
											data: params?.row?.approvers?.approvers,
											clubId: params?.row?.id,
											clubName: params?.row?.name,
										})
									}
								>
									{approvers.length} Approvers
								</Button>
							</HtmlTooltip>
						) : (
							<Button
								variant="outlined"
								color="error"
								style={{ fontSize: 11, color: "#D32802" }}
							>
								No Approvers
							</Button>
						)}
					</>
				);
			},
		},
	];

	// const handleCellClick = (params, event) => {
	// 	switch (params.field) {
	// 		case "edit":
	// 			console.log(params.row, "edit");
	// 			break;
	// 		case "delete":
	// 			console.log(params.row, "delete");
	// 			break;
	// 		default:
	// 			break;
	// 	}
	// };

	const handleCloseToaster = () => {
		setToasterOptions((x) => ({ ...x, open: false }));
	};
	return (
		<>
			<Toaster
				vertical={toasterOptions.vertical}
				horizontal={toasterOptions.horizontal}
				open={toasterOptions.open}
				handleClose={handleCloseToaster}
				message={toasterOptions.message}
				severity={toasterOptions.severity}
				autoHideDuration={3000}
			/>
			<Box
				sx={{
					backgroundColor: "background.paper",
					borderRadius: 3,
					height: 500,
					width: "100%",
					"& .actions": {
						color: "text.secondary",
					},
					"& .textPrimary": {
						color: "text.primary",
					},
				}}
			>
				<StyledDataGrid
					style={{ padding: "0px 20px", borderRadius: "10px" }}
					loading={rows.length === 0}
					pagination={pagination}
					pageSize={pageSize}
					onPageSizeChange={onPageSizeChange}
					disableSelectionOnClick={disableSelectionOnClick}
					rows={rows}
					columns={[
						...columns.map(
							(obj) => extraColumns.find((o) => o.field === obj.field) || obj
						),
					]}
					rowsPerPageOptions={[10, 15, 20]}
					editMode="row"
					rowModesModel={rowModesModel}
					onRowEditStart={handleRowEditStart}
					onRowEditStop={handleRowEditStop}
					processRowUpdate={processRowUpdate}
					components={{
						Toolbar: CustomToolbar,
					}}
					componentsProps={{
						toolbar: { setrows, setrowmodesmodel },
					}}
					experimentalFeatures={{ newEditingApi: true }}
					sx={{
						boxShadow: 2,
						"& .MuiDataGrid-cell:hover": {
							color: "primary.main",
						},
					}}
					initialState={{
						sorting: {
							sortModel: [{ field: "name", sort: "asc" }],
						},
					}}
				/>
			</Box>
			<Modal
				open={show}
				onClose={() => setShow(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={ModalStyle}>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Avatar
							alt="Member"
							src={selectedMember?.imageURL || ""}
							style={{
								width: "100px",
								height: "100px",
							}}
						/>
						<div
							style={{
								marginLeft: "16px",
							}}
						>
							<span
								style={{
									backgroundColor: "#62A933",
									borderRadius: "30px",
									fontSize: "12px",
									padding: "2px 10px",
								}}
							>
								{selectedMember?.socialHandle}
							</span>
							<Typography sx={{ mt: 1 }}>
								{selectedMember?.firstName} {selectedMember?.lastName}
							</Typography>
							{selectedMember?.email && (
								<Typography>{selectedMember?.email}</Typography>
							)}
							<Typography>{selectedMember?.msisdn}</Typography>
						</div>
					</div>
				</Box>
			</Modal>
			<Modal
				open={approversModal}
				onClose={() => setApproversModal(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={ModalStyle}>
					<div>
						{approvers?.data?.length > 0 ? (
							<Typography sx={{ mb: 2 }}>
								{approvers?.clubName} Approvers list 👇🏼
							</Typography>
						) : (
							<Typography sx={{ mb: 2 }}>Approver list 👇🏼</Typography>
						)}
						{approvers?.data?.map((approver) => (
							<Chip
								key={approver.memberId}
								label={approver?.firstName + " " + approver?.lastName}
								variant="outlined"
								color="primary"
								sx={{ mr: 1 }}
							/>
						))}
					</div>
					<div>
						<FormControl fullWidth sx={{ mt: 3 }}>
							<InputLabel id="demo-simple-select-label">
								Select To Remove Approvers
							</InputLabel>
							<Select
								labelId="mutiple-select-label"
								multiple
								fullWidth
								value={selectedApprovers}
								label="Select Permissions"
								renderValue={(selected) => selected.join(", ")}
								MenuProps={MenuProps}
								onChange={handleApproversSelectChange}
							>
								{approvers?.data?.map((approver) => (
									<MenuItem key={approver.memberId} value={approver?.memberId}>
										{approver?.firstName + " " + approver?.lastName}
									</MenuItem>
								))}
							</Select>
							<div style={{ display: "flex", flexDirection: "column" }}>
								<div style={{ display: "flex", flexDirection: "column" }}>
									<LoadingButton
										fullWidth
										type="submit"
										size="large"
										sx={{ mt: 2, textTransform: "capitalize" }}
										loading={loading}
										// style={{ fontSize: 11, color: "#D32802" }}
										// sx={{ mt: 3 }}
										onClick={handleRemoveApprovers}
										variant="contained"
										color="primary"
									>
										Remove
									</LoadingButton>
								</div>
							</div>
						</FormControl>
					</div>
				</Box>
			</Modal>
		</>
	);
};

export default Table;
