import React from "react";
import PropTypes from "prop-types";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Alert, Box, Button, LinearProgress, Snackbar } from "@mui/material";
import {
	DataGrid,
	GridActionsCellItem,
	GridRowModes,
	GridToolbarContainer,
} from "@mui/x-data-grid";
import { PermissionsAPI } from "app/routes/middleware";
import { AccessColumns } from "./data/columns";

function EditToolbar(props) {
	const { setRows, setRowModesModel } = props;

	const handleClick = () => {
		const id = Math.random().toFixed(4);
		setRows((oldRows) => [
			{
				id,
				parentId: 1,
				name: "",
				description: "",
				isAction: false,
				isPage: false,
				createdAt: new Date().toISOString(),
				updatedAt: new Date().toISOString(),
				path: "",
				icon: "",
				isNew: true,
			},
			...oldRows,
		]);
		setRowModesModel((oldModel) => ({
			[id]: { mode: GridRowModes.Edit, fieldToFocus: "parentId" },
			...oldModel,
		}));
		// console.log(setRows, "setRows");
	};

	return (
		<GridToolbarContainer>
			<Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
				Add Access
			</Button>
		</GridToolbarContainer>
	);
}

EditToolbar.propTypes = {
	setRowModesModel: PropTypes.func.isRequired,
	setRows: PropTypes.func.isRequired,
};

const Access = () => {
	const [progress, setProgress] = React.useState(0);
	const [loading, setLoading] = React.useState(true);
	const [rows, setRows] = React.useState([]);
	const [rowModesModel, setRowModesModel] = React.useState({});
	const [numberOfItems, setNumberOfItems] = React.useState(10);
	const [open, setOpen] = React.useState(false);
	const [message, setMessage] = React.useState("");
	const [severity, setSeverity] = React.useState("success");

	const handleRowEditStart = (params, event) => {
		event.defaultMuiPrevented = true;
	};

	const handleRowEditStop = (params, event) => {
		event.defaultMuiPrevented = true;
	};

	const handleEditClick = (id) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
	};

	const handleSaveClick = (id) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
	};

	// const handleDeleteClick = (id) => () => {
	// 	setRows(rows.filter((row) => row.id !== id));
	// };

	const handleCancelClick = (id) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		});

		const editedRow = rows.find((row) => row.id === id);
		if (editedRow.isNew) {
			setRows(rows.filter((row) => row.id !== id));
		}
	};

	const processRowUpdate = async (newRow) => {
		// console.log(newRow, "newRow");
		const updatedRow = { ...newRow, isNew: false };
		// console.log(updatedRow, "updatedRow");
		const {
			parentId,
			name,
			description,
			isAction,
			isPage,
			createdAt,
			updatedAt,
			path,
			icon,
		} = newRow;
		const payload = {
			parentId: parentId,
			name: name,
			description: description,
			isPage: isPage,
			isAction: isAction,
			createdAt: createdAt,
			updatedAt: updatedAt,
			path: path,
			icon: icon ? icon : "",
		};
		// console.log(payload, "payload");

		if (newRow.isNew === true) {
			setLoading(true);
			const response = await PermissionsAPI.createAccess(payload);
			if (response.status === 200) {
				setLoading(false);
				setMessage("Access created successfully");
				setSeverity("success");
				setOpen(true);
				setRows(rows.map((row) => (row.id === newRow.id ? newRow : row)));
			} else {
				setLoading(false);
				setMessage("Error creating access");
				setSeverity("error");
				setOpen(true);
			}
		}
		// console.log(updatedRow, "updatedRow Inside");
		if (!newRow.isNew) {
			setLoading(true);
			const response = await PermissionsAPI.updateAccess(
				updatedRow.id,
				payload
			);
			if (response.status === 200) {
				setLoading(false);
				setMessage("Access updated successfully");
				setSeverity("success");
				setOpen(true);
				setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
			} else {
				setLoading(false);
				setMessage("Error updating access");
				setSeverity("error");
				setOpen(true);
			}
		}
		return updatedRow;
	};

	const actions = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 100,
			cellClassName: "actions",
			getActions: ({ id }) => {
				const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

				if (isInEditMode) {
					return [
						<GridActionsCellItem
							icon={<SaveIcon />}
							label="Save"
							onClick={handleSaveClick(id)}
						/>,
						<GridActionsCellItem
							icon={<CancelIcon />}
							label="Cancel"
							className="textPrimary"
							onClick={handleCancelClick(id)}
							color="inherit"
						/>,
					];
				}

				return [
					<GridActionsCellItem
						icon={<EditIcon />}
						label="Edit"
						className="textPrimary"
						onClick={handleEditClick(id)}
						color="inherit"
					/>,
					// <GridActionsCellItem
					// 	icon={<DeleteIcon />}
					// 	label="Delete"
					// 	onClick={handleDeleteClick(id)}
					// 	color="inherit"
					// />,
				];
			},
		},
	];

	React.useEffect(() => {
		handleAccessLoad();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		const timer = setInterval(() => {
			setProgress((oldProgress) => {
				if (oldProgress === 100) {
					return 0;
				}
				const diff = Math.random() * 10;
				return Math.min(oldProgress + diff, 100);
			});
		}, 500);

		return () => {
			clearInterval(timer);
		};
	}, []);

	const handleAccessLoad = async () => {
		try {
			setLoading(true);
			const request = await PermissionsAPI.getAccess();
			const response = request.data.data.results;
			// console.log(request.data, "access");
			if (response && response.length) {
				setLoading(false);
				setRows(response);
			}
		} catch (error) {
			setLoading(false);
			if (error.response?.status === 401) {
				setMessage(
					"Sorry your sign in token has expired, please sign in again to continue using Spark Admin 🤭"
				);
				setSeverity("error");
				setOpen(true);
				localStorage.clear("@spark-session");
				window.location.replace("/sign-in");
			} else if (error.response?.status === 210) {
				setMessage(
					"Sorry, there was an error loading the accesses, please sign out and sign in again 😅"
				);
				setSeverity("error");
				setOpen(true);
				localStorage.clear("@spark-session");
				window.location.replace("/sign-in");
			} else {
				setMessage(
					"Sorry, there was an error loading the accesses, please refresh or sign out and sign in again 😅"
				);
				setSeverity("error");
				setOpen(true);
			}
		}
	};
	return (
		<div>
			<Snackbar
				open={open}
				autoHideDuration={3000}
				onClose={() => setOpen(false)}
			>
				<Alert
					onClose={() => setOpen(false)}
					severity={severity}
					sx={{ width: "60%" }}
				>
					{message}
				</Alert>
			</Snackbar>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					marginTop: "-30px",
				}}
			>
				<h2>Manage Access</h2>
			</div>
			{loading && (
				<LinearProgress
					value={progress}
					variant="determinate"
					sx={{ mt: -1, mb: 1 }}
				/>
			)}
			<Box
				sx={{
					backgroundColor: "background.paper",
					borderRadius: 3,
					height: 600,
					width: "100%",
					"& .actions": {
						color: "text.secondary",
					},
					"& .textPrimary": {
						color: "text.primary",
					},
				}}
			>
				<DataGrid
					loading={rows.length === 0}
					style={{ padding: "0px 20px", borderRadius: "10px" }}
					rows={rows}
					columns={[
						...AccessColumns.map(
							(obj) => actions.find((o) => o.field === obj.field) || obj
						),
					]}
					editMode="row"
					rowModesModel={rowModesModel}
					onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
					onRowEditStart={handleRowEditStart}
					onRowEditStop={handleRowEditStop}
					processRowUpdate={processRowUpdate}
					components={{
						Toolbar: EditToolbar,
					}}
					componentsProps={{
						toolbar: { setRows, setRowModesModel },
					}}
					experimentalFeatures={{ newEditingApi: true }}
					pageSize={numberOfItems}
					pagination={true}
					onPageSizeChange={(pageSize) => {
						setNumberOfItems(pageSize);
					}}
					rowsPerPageOptions={[10, 15, 20]}
					onProcessRowUpdateError={(error) => {
						setMessage(error.message);
						setSeverity("error");
						setOpen(true);
					}}
					initialState={{
						sorting: {
							sortModel: [{ field: "name", sort: "asc" }],
						},
					}}
				/>
			</Box>
		</div>
	);
};

export default Access;
