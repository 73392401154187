export const Columns = [
	{
		field: "id",
		headerName: "User ID",
		width: 70,
		hide: true,
		sortable: false,
	},
	{
		field: "referenceId",
		headerName: "Reference ID",
		width: 150,
	},
	{
		field: "catalogId",
		headerName: "Catalog ID",
		width: 80,
		hide: true,
	},
	{
		field: "clubId",
		headerName: "Club ID",
		width: 70,
	},
	{
		field: "clubName",
		headerName: "Club Name",
		width: 200,
	},
	{
		field: "catalogName",
		headerName: "Catalog Name",
		width: 200,
	},
	{
		field: "currency",
		headerName: "Currency",
		width: 150,
	},
	{
		field: "amount",
		headerName: "Amount",
		width: 180,
		valueFormatter: ({ value }) => value && value.toFixed(2),
	},
	{
		field: "adminCommission",
		headerName: "Admin Commission",
		width: 180,
		valueFormatter: ({ value }) => (value ? value.toFixed(2) : "Not set"),
	},
	{
		field: "cart",
		headerName: "Cart",
		width: 150,
	},
	{
		field: "deliveryDate",
		headerName: "Delivery Date",
		width: 200,
		valueFormatter: ({ value }) =>
			value ? new Date(value).toLocaleString() : "Not set",
	},
	{
		field: "status",
		headerName: "Status",
		width: 150,
		renderCell: (params) => {
			return (
				<div
					style={
						params.value === "DELIVERED"
							? {
									backgroundColor: "#8FBE0E",
									borderRadius: "10px",
									fontSize: "12px",
									padding: "5px 10px",
									textTransform: "capitalize",
									color: "#ffffff",
							  }
							: params.value === "PLACED"
							? {
									backgroundColor: "#C79544",
									borderRadius: "10px",
									fontSize: "12px",
									padding: "5px 10px",
									textTransform: "capitalize",
									color: "#ffffff",
							  }
							: params.value === "CANCELLED"
							? {
									backgroundColor: "#C74B44",
									borderRadius: "10px",
									fontSize: "12px",
									padding: "5px 10px",
									textTransform: "capitalize",
									color: "#ffffff",
							  }
							: {
									backgroundColor: " #4485C7",
									borderRadius: "10px",
									fontSize: "12px",
									padding: "5px 10px",
									textTransform: "capitalize",
									color: "#ffffff",
							  }
					}
				>
					{params.value}
				</div>
			);
		},
	},
	{
		field: "address",
		headerName: "Address",
		width: 250,
	},
	{
		field: "landmark",
		headerName: "Landmark",
		width: 200,
	},
	{
		field: "createdAt",
		headerName: "Created At",
		width: 200,
		valueFormatter: ({ value }) => value && new Date(value).toLocaleString(),
	},
	{
		field: "updatedAt",
		headerName: "Updated At",
		width: 200,
		valueFormatter: ({ value }) => value && new Date(value).toLocaleString(),
	},
	{
		field: "actions",
		headerName: "Actions",
		width: 200,
	},
];
