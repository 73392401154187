import { Avatar } from "@mui/material";

export const Columns = [
	{
		field: "id",
		headerName: "User ID",
		width: 70,
		hide: true,
		sortable: false,
	},
	{
		field: "image",
		headerName: "Image",
		width: 110,
		renderCell: (params) => {
			return (
        <Avatar
          variant="square"
					src={params.value}
					alt="random"
					sx={{ width: 70, height: 70 }}
				/>
			);
		},
	},
	{
		field: "name",
		headerName: "Name",
		width: 200,
	},
	{
		field: "description",
		headerName: "Description",
		width: 250,
	},
	{
		field: "createdAt",
		headerName: "Created At",
		width: 200,
		valueFormatter: ({ value }) => value && new Date(value).toLocaleString(),
	},
	{
		field: "updatedAt",
		headerName: "Updated At",
		width: 200,
		valueFormatter: ({ value }) => value && new Date(value).toLocaleString(),
	},
	{
		field: "actions",
		headerName: "Actions",
		width: 200,
	},
];

export const Rows = [
	{
		id: 1,
		name: "Product 1",
		description: "Lorem Ipsum something something",
		image: "",
		createdAt: "",
		updatedAt: "",
	},
	{
		id: 2,
		name: "Product 2",
		description: "Lorem Ipsum something something",
		image: "",
		createdAt: "",
		updatedAt: "",
	},
	{
		id: 3,
		name: "Product 3",
		description: "Lorem Ipsum something something",
		image: "",
		createdAt: "",
		updatedAt: "",
	},
	{
		id: 4,
		name: "Product 4",
		description: "Lorem Ipsum something something",
		image: "",
		createdAt: "",
		updatedAt: "",
	},
	{
		id: 5,
		name: "Product 5",
		description: "Lorem Ipsum something something",
		image: "",
		createdAt: "",
		updatedAt: "",
	},
	{
		id: 6,
		name: "Product 6",
		description: "Lorem Ipsum something something",
		image: "",
		createdAt: "",
		updatedAt: "",
	},
	{
		id: 7,
		name: "Product 7",
		description: "Lorem Ipsum something something",
		image: "",
		createdAt: "",
		updatedAt: "",
	},
	{
		id: 8,
		name: "Product 8",
		description: "Lorem Ipsum something something",
		image: "",
		createdAt: "",
		updatedAt: "",
	},
	{
		id: 9,
		name: "Product 9",
		description: "Lorem Ipsum something something",
		image: "",
		createdAt: "",
		updatedAt: "",
	},
	{
		id: 10,
		name: "Product 10",
		description: "Lorem Ipsum something something",
		image: "",
		createdAt: "",
		updatedAt: "",
	},
];
