import {
	Box,
	Button,
	IconButton,
	Modal,
	TextField,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { AdminDealsAPI } from "app/routes/middleware";
import { STATUSCODES } from "app/utils/appHelpers";
import { ItemImageUpload } from "../imageUpload";

const AddNewProductToCatalog = ({
	setIsOpenAddNewItems,
	isOpenAddNewItems,
	setIsNewItemCreateSuccess,
	setMessage,
	setSeverity,
	setOpenSnackbar,
	catalogId,
}) => {
	const [files, setFiles] = useState([]);
	const [fileDrop, setFileDrop] = useState("");
	const [isAddNewItems, setIsAddNewItems] = useState(null);
	const [isUploading, setIsUploading] = useState(false);
	const [newItems, setNewItems] = useState([]);
	const [newItemImage, setNewItemImage] = useState("");
	const [newItemName, setNewItemName] = useState("");
	const [newItemDescription, setNewItemDescription] = useState("");
	const [newSellingPrice, setNewSellingPrice] = useState("");
	const [newVendorPrice, setNewVendorPrice] = useState("");
	const [newAvailableItems, setNewAvailableItems] = useState("");
	const { reset } = useForm();

	const handleEditModalClose = () => {
		setFiles([]);
		setFileDrop("");
		setNewItemImage("");
		setNewItemName("");
		setNewItemDescription("");
		setNewSellingPrice("");
		setNewVendorPrice("");
		setNewAvailableItems("");
		setNewItems([]);

		reset();
		setIsOpenAddNewItems(false);
	};

	const handleDeleteItemImage = (file) => {
		setFiles((curr) => curr.filter((f) => f.name !== file.name));
		setFileDrop("");
	};

	const handleAddNewItem = async () => {
		const sellingPrice = parseInt(newSellingPrice);
		const vendorPrice = parseInt(newVendorPrice);
		const availableItems = parseInt(newAvailableItems);
		try {
			setIsUploading(true);
			const imageRequest = await AdminDealsAPI.uploadImage({
				image: newItemImage, // Use the itemImage state for image upload
			});
			if (STATUSCODES.SUCCESS.includes(imageRequest.status)) {
				setMessage("Saved successfully 🎉");
				setSeverity("success");
				setOpenSnackbar(true);
				const newItem = {
					name: newItemName,
					description: newItemDescription,
					sellingPrice: sellingPrice,
					vendorPrice: vendorPrice,
					availableItems: availableItems,
					discount: parseFloat(
						(((vendorPrice - sellingPrice) / vendorPrice) * 100).toFixed()
					),
					image: imageRequest.data.data.imageUrl, // Store the image URL in the item object
				};

				// Add the new item to the list of new items
				setNewItems([...newItems, newItem]);

				// Clear input fields
				setFileDrop("");
				setFiles([]);
				setNewItemImage(""); // Clear the itemImage state
				setNewItemName("");
				setNewItemDescription("");
				setNewSellingPrice("");
				setNewVendorPrice("");
				setNewAvailableItems("");
				setIsUploading(false);
			}
		} catch (error) {
			setIsUploading(false);
			if (STATUSCODES.CLIENT_ERROR.includes(error?.response?.status)) {
				setMessage("Please try again or please reach out to tech support");
				setSeverity("error");
				setOpenSnackbar(true);
			} else if (STATUSCODES.SERVER_ERROR.includes(error?.response?.status)) {
				setMessage(
					"Sorry this is from us, please try again later or reach out to tech support"
				);
				setSeverity("error");
				setOpenSnackbar(true);
			}
			// console.log(error);
		}
	};

	const handleUpdateCatalogProduct = async () => {
		setIsAddNewItems(true);
		try {
			const request = await AdminDealsAPI.updateCatalog({
				id: catalogId,
				payload: { newItems: newItems },
			});
			if (STATUSCODES.SUCCESS.includes(request.status)) {
				setIsAddNewItems(false);
				handleEditModalClose();
				setIsNewItemCreateSuccess(true);
				setOpenSnackbar(true);
				setMessage(`Items created successfully`);
				setSeverity("success");
			}
			setIsNewItemCreateSuccess(false);
		} catch (error) {
			// console.log( error, "error" );
			setIsAddNewItems(false);
			if (STATUSCODES.CLIENT_ERROR.includes(error?.response?.status)) {
				setMessage(
					`Error creating item(s). Please make sure you've entered the right details or please reach out to tech support`
				);
				setSeverity("error");
				setOpenSnackbar(true);
			} else if (STATUSCODES.SERVER_ERROR.includes(error?.response?.status)) {
				setMessage(
					"Sorry this is from us, please try again later or reach out to tech support"
				);
				setSeverity("error");
				setOpenSnackbar(true);
			}
		}
	};

	return (
		<Modal open={isOpenAddNewItems}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: 500,
					bgcolor: "background.paper",
					boxShadow: 24,
					p: 4,
					borderRadius: "8px",
					outline: "none",
					overflow: "auto",
					maxHeight: "95vh",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Typography variant="h5" sx={{ fontWeight: 600 }}>
						Add New Items To Catalog
					</Typography>
					<IconButton onClick={handleEditModalClose}>
						<CloseIcon />
					</IconButton>
				</div>
				<form>
					<div style={{ marginTop: "20px" }}>
						<ItemImageUpload
							setFileDrop={setNewItemImage} // Set itemImage when an image is dropped or selected
							title="Upload Item Image"
							setFiles={setFiles}
							files={files}
							handleDeleteItemImage={handleDeleteItemImage}
						/>
						<TextField
							label="Item Name"
							fullWidth
							margin="normal"
							value={newItemName}
							onChange={(e) => setNewItemName(e.target.value)}
						/>
						<TextField
							label="Item Description"
							fullWidth
							margin="normal"
							value={newItemDescription}
							onChange={(e) => setNewItemDescription(e.target.value)}
						/>
						<TextField
							label="Selling Price"
							fullWidth
							margin="normal"
							type="text"
							value={newSellingPrice}
							onChange={(e) => setNewSellingPrice(e.target.value)}
						/>
						<TextField
							label="Vendor Price"
							fullWidth
							margin="normal"
							type="text"
							value={newVendorPrice}
							onChange={(e) => setNewVendorPrice(e.target.value)}
						/>
						<TextField
							label="Available Items"
							fullWidth
							margin="normal"
							type="text"
							value={newAvailableItems}
							onChange={(e) => setNewAvailableItems(e.target.value)}
						/>
						<Button
							variant="outlined"
							color="secondary"
							// onClick={handleAddNewItem}
							onClick={() => {
								if (
									newAvailableItems &&
									newItemDescription &&
									newItemName &&
									newSellingPrice &&
									newVendorPrice
								) {
									handleAddNewItem();
								} else {
									setMessage(
										"Please fill all input areas, you may skip the image"
									);
									setSeverity("error");
									setOpenSnackbar(true);
								}
							}}
						>
							{isUploading ? "Saving..." : "Save New Item"}
						</Button>
						{/* Display added items */}
						{newItems.length > 0 && (
							<div>
								<Typography variant="subtitle1" sx={{ mt: 3, mb: -1 }}>
									Added Items 👇🏽
								</Typography>
								<ul>
									{newItems.map((item, index) => (
										<li key={index}>
											{item.itemName} - Discount: {item.discount}%
										</li>
									))}
								</ul>
							</div>
						)}
					</div>

					<Typography variant="h6" sx={{ my: 1, fontSize: "10px" }}>
						<i>
							<strong>Note: </strong>
							You can only add an item to a catalog only after saving it
						</i>
					</Typography>

					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							mt: 3,
						}}
					>
						<Button
							variant="contained"
							color="secondary"
							disabled={newItems.length === 0}
							type="submit"
							fullWidth
							sx={{ height: "50px" }}
							onClick={handleUpdateCatalogProduct}
						>
							{isAddNewItems === true
								? "Adding..."
								: "Add Saved Items To Catalog"}
						</Button>
					</Box>
				</form>
			</Box>
		</Modal>
	);
};

export default AddNewProductToCatalog;
