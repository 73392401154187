import React from "react";
import PropTypes from "prop-types";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
// import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
	Alert,
	Box,
	Button,
	Snackbar,
	Modal,
	Typography,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Chip,
	LinearProgress,
} from "@mui/material";
import {
	DataGrid,
	GridActionsCellItem,
	GridRowModes,
	GridToolbarContainer,
} from "@mui/x-data-grid";
import { AdminAPI, PermissionsAPI } from "app/routes/middleware";
import { AdminColumns } from "./data/columns";
import Div from "@jumbo/shared/Div";
import { MenuProps, ModalStyle } from "app/utils/constants/styles";

function EditToolbar(props) {
	const { setRows, setRowModesModel } = props;

	const handleClick = () => {
		const id = Math.random().toFixed(4);
		setRows((oldRows) => [
			{
				id,
				firstName: "",
				otherNames: "",
				lastName: "",
				email: "",
				msisdn: "",
				status: "Pending",
				userType: "Spark Admin",
				createdAt: new Date().toISOString(),
				updatedAt: new Date().toISOString(),
				isNew: true,
			},
			...oldRows,
		]);
		setRowModesModel((oldModel) => ({
			...oldModel,
			[id]: { mode: GridRowModes.Edit, fieldToFocus: "firstName" },
		}));
		// console.log(setRows, "setRows");
	};

	return (
		<GridToolbarContainer>
			<Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
				Add An Admin
			</Button>
		</GridToolbarContainer>
	);
}

EditToolbar.propTypes = {
	setRowModesModel: PropTypes.func.isRequired,
	setRows: PropTypes.func.isRequired,
};

const Admins = () => {
	const [loading, setLoading] = React.useState(true);
	const [progress, setProgress] = React.useState(0);
	const [rows, setRows] = React.useState([]);
	const [rowModesModel, setRowModesModel] = React.useState({});
	const [numberOfItems, setNumberOfItems] = React.useState(10);
	const [open, setOpen] = React.useState(false);
	const [message, setMessage] = React.useState("");
	const [severity, setSeverity] = React.useState("success");
	const [user, setUser] = React.useState({});
	const [openModal, setOpenModal] = React.useState(false);
	const [getPermissions, setGetPermissions] = React.useState([]);
	const [selectedPermission, setSelectedPermission] = React.useState([]);
	const [userPermissions, setUserPermissions] = React.useState([]);
	const [userPermissionsError, setUserPermissionsError] = React.useState("");

	const handleModalClose = () => setOpenModal(false);

	const handleModalOpen = async () => {
		setLoading(true);
		setOpenModal(true);
		const request = await PermissionsAPI.getPermissions();
		if (request.status === 200) {
			setLoading(false);
			setGetPermissions(request.data.data.results);
		} else {
			setLoading(false);
			setMessage("Failed to load user permissions");
			setSeverity("error");
			setOpen(true);
		}
		// console.log(request.data.data.results, "get permissions");
	};

	const handleRowEditStart = (params, event) => {
		event.defaultMuiPrevented = true;
	};

	const handleRowEditStop = (params, event) => {
		event.defaultMuiPrevented = true;
	};

	const handleEditClick = (id) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
	};

	const handleSaveClick = (id) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
	};

	// const handleDeleteClick = (id) => () => {
	// 	setRows(rows.filter((row) => row.id !== id));
	// };

	const handleCancelClick = (id) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		});

		const editedRow = rows.find((row) => row.id === id);
		// console.log(editedRow, "editedRow");
		if (editedRow.isNew) {
			setRows(rows.filter((row) => row.id !== id));
			// console.log(rows, "rows!");
		}
	};

	const handlePermissionSelectChange = (event) => {
		const value = event.target.value;
		setSelectedPermission(value);
	};

	const handleAssignPermission = async () => {
		const payload = [];
		selectedPermission.forEach((permission) => {
			payload.push({ userId: user.id, permissionId: permission });
		});
		try {
			setLoading(true);
			const request = await PermissionsAPI.assignUserPermisssions(payload);
			// console.log(request, "request");
			if (request.status === 200) {
				setLoading(false);
				handleModalClose();
				setMessage("Permissions assigned successfully");
				setSeverity("success");
				setOpen(true);
			}
		} catch (error) {
			setLoading(false);
			setMessage("An error occurred, please refresh and try again");
			setSeverity("error");
			setUserPermissionsError(
				"Sorry an error occurred while trying to assign user's permission. Please refresh and try again"
			);
			setOpen(true);
		}
	};

	const handleRemovePermission = async () => {
		const payload = [];
		selectedPermission.forEach((permission) => {
			payload.push({ userId: user.id, permissionId: permission });
		});
		const request = await PermissionsAPI.removeUserPermission(payload);
		try {
			setLoading(true);
			if (request.status === 200) {
				setLoading(false);
				handleModalClose();
				setMessage("Permissions removed successfully");
				setSeverity("success");
				setOpen(true);
			}
		} catch (error) {
			setLoading(false);
			setMessage("An error occurred, please refresh and try again");
			setSeverity("error");
			setUserPermissionsError(
				"Sorry an error occurred while trying to remove user's permission. Please refresh and try again"
			);
			setOpen(true);
		}
	};

	const processRowUpdate = async (newRow) => {
		setLoading(true);
		const updatedRow = { ...newRow, isNew: false };
		// console.log(updatedRow, "updatedRow");
		setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
		const {
			firstName,
			otherNames,
			lastName,
			email,
			msisdn,
			status,
			userType,
			createdAt,
			updatedAt,
		} = updatedRow;
		const payload = {
			firstName,
			otherNames,
			lastName,
			email,
			msisdn,
			status,
			userType,
			createdAt,
			updatedAt,
		};
		try {
			const request = await AdminAPI.addAdmin(payload);
			const response = request.data.status;
			// console.log(request.data, "add admin response");
			if (response === 200) {
				setLoading(false);
				setMessage("Admin updated successfully");
				setSeverity("success");
				setOpen(true);
			}
		} catch (error) {
			setLoading(false);
			setRows(rows.filter((row) => row.id !== newRow.id));
			const errorMessage = error.response.data.message;
			// console.log(error.response.data.message, "error");
			setMessage(errorMessage);
			setSeverity("error");
			setOpen(true);
		}
		return updatedRow;
	};

	const actions = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 100,
			cellClassName: "actions",
			getActions: (params) => {
				const isInEditMode =
					rowModesModel[params.id]?.mode === GridRowModes.Edit;

				if (isInEditMode) {
					return [
						<GridActionsCellItem
							icon={<SaveIcon />}
							label="Save"
							onClick={handleSaveClick(params.id)}
						/>,
						<GridActionsCellItem
							icon={<CancelIcon />}
							label="Cancel"
							className="textPrimary"
							onClick={handleCancelClick(params.id)}
							color="inherit"
						/>,
					];
				}

				return [
					<GridActionsCellItem
						icon={<EditIcon />}
						label="Edit"
						className="textPrimary"
						onClick={handleEditClick(params.id)}
						color="inherit"
					/>,
					<GridActionsCellItem
						icon={<KeyOutlinedIcon />}
						label="View Permissions"
						className="textPrimary"
						onClick={async () => {
							setUser({
								id: params.id,
								firstName: params.row.firstName,
								lastName: params.row.lastName,
							});
							const request = await PermissionsAPI.getPermissionsByUserId(
								params.id
							);
							// console.log(request, "request");
							setUserPermissions(request.data.data);
							const response = request.data.data;
							if (response && response.length === 0) {
								setMessage("No permissions set for this user");
								handleModalOpen();
							} else if (response && response.length > 0) {
								setMessage("Permissions set for this user");
								handleModalOpen();
							} else {
								setMessage("Error fetching permissions");
								setSeverity("error");
								setOpen(true);
							}
						}}
						color="inherit"
					/>,
					// <GridActionsCellItem
					// 	icon={<DeleteIcon />}
					// 	label="Delete"
					// 	onClick={handleDeleteClick(id)}
					// 	color="inherit"
					// />,
				];
			},
		},
	];

	React.useEffect(() => {
		handleAdminsLoad();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		const timer = setInterval(() => {
			setProgress((oldProgress) => {
				if (oldProgress === 100) {
					return 0;
				}
				const diff = Math.random() * 10;
				return Math.min(oldProgress + diff, 100);
			});
		}, 500);

		return () => {
			clearInterval(timer);
		};
	}, []);

	const handleAdminsLoad = async () => {
		try {
			setLoading(true);
			const request = await PermissionsAPI.getAdmins();
			const response = request.data.data.result;
			// console.log(request.data, "getAdmins response");
			if (response && response.length) {
				setLoading(false);
				setRows(response);
			}
		} catch (error) {
			setLoading(false);
			if (error?.response?.status === 401) {
				setMessage(
					"Sorry your sign in token has expired, please sign in again to continue using Spark Admin 🤭"
				);
				setSeverity("error");
				setOpen(true);
				localStorage.clear("@spark-session");
				window.location.replace("/sign-in");
			} else if (error?.response?.status === 210) {
				setMessage("Please check your internet connection 😅");
				setSeverity("error");
				setOpen(true);
				localStorage.clear("@spark-session");
				window.location.replace("/sign-in");
			} else {
				setMessage(
					"Sorry, there was an error loading the roles, please check your internet connection, refresh or sign out and sign in again 😅"
				);
				setSeverity("error");
				setOpen(true);
			}
		}
	};

	return (
		<div>
			<Snackbar
				open={open}
				autoHideDuration={3000}
				onClose={() => setOpen(false)}
			>
				<Alert
					onClose={() => setOpen(false)}
					severity={severity}
					sx={{ width: "60%" }}
				>
					{message}
				</Alert>
			</Snackbar>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					marginTop: "-30px",
				}}
			>
				<h2>Manage Admins</h2>
			</div>
			{loading && (
				<LinearProgress
					value={progress}
					variant="determinate"
					sx={{ mt: -1, mb: 1 }}
				/>
			)}
			<Box
				sx={{
					backgroundColor: "background.paper",
					borderRadius: 3,
					height: 500,
					width: "100%",
					"& .actions": {
						color: "text.secondary",
					},
					"& .textPrimary": {
						color: "text.primary",
					},
				}}
			>
				<DataGrid
					loading={rows.length === 0}
					style={{ padding: "0px 20px", borderRadius: "10px" }}
					rows={rows}
					columns={[
						...AdminColumns.map(
							(obj) => actions.find((o) => o.field === obj.field) || obj
						),
					]}
					editMode="row"
					rowModesModel={rowModesModel}
					onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
					onRowEditStart={handleRowEditStart}
					onRowEditStop={handleRowEditStop}
					processRowUpdate={processRowUpdate}
					components={{
						Toolbar: EditToolbar,
					}}
					componentsProps={{
						toolbar: { setRows, setRowModesModel },
					}}
					experimentalFeatures={{ newEditingApi: true }}
					pageSize={numberOfItems}
					pagination={true}
					onPageSizeChange={(pageSize) => {
						setNumberOfItems(pageSize);
					}}
					rowsPerPageOptions={[10, 15, 20]}
					onProcessRowUpdateError={(error) => {
						// console.log(error, "error");
					}}
				/>
			</Box>

			<Modal
				open={openModal}
				onClose={handleModalClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={ModalStyle}>
					<Div>
						<Typography id="modal-modal-title" variant="h6" component="h2">
							{user?.firstName + " " + user?.lastName + "'s Permissions"}
						</Typography>
						{userPermissions &&
							userPermissions.map((item) => (
								// <div style={{ display: "flex" }} key={item.id}>
								<Chip
									style={{ marginRight: "5px" }}
									key={item.id}
									label={item.name}
									title={item.name}
								/>
								// </div>
							))}
					</Div>
					{userPermissionsError && (
						<p
							style={{ color: "red", fontSize: "14px", marginBottom: "-10px" }}
						>
							{userPermissionsError}
						</p>
					)}
					<Div id="modal-modal-description" sx={{ mt: 2 }}>
						{message === "No permissions set for this user" ? (
							<div>
								<div>
									<h2
										style={{
											color: "red",
											border: "1px solid red",
											backgroundColor: "#E8E8E8",
											borderRadius: "20px",
											padding: "10px",
											fontSize: "14px",
											textAlign: "center",
											fontWeight: "500",
										}}
									>
										{message} 👇🏼
									</h2>
									<div>
										<FormControl fullWidth sx={{ mt: 2 }}>
											<InputLabel id="mutiple-select-label">
												Select Permissions
											</InputLabel>
											<Select
												labelId="mutiple-select-label"
												multiple
												fullWidth
												value={selectedPermission}
												label="Select Permissions"
												onChange={handlePermissionSelectChange}
												renderValue={(selected) => selected.join(", ")}
												MenuProps={MenuProps}
											>
												{getPermissions.map((permission) => (
													<MenuItem key={permission.id} value={permission.id}>
														{permission.name}
													</MenuItem>
												))}
											</Select>
											<div style={{ display: "flex", flexDirection: "column" }}>
												<div
													style={{ display: "flex", flexDirection: "column" }}
												>
													<Button
														sx={{ mt: 2 }}
														onClick={handleAssignPermission}
														variant="contained"
														color="secondary"
													>
														Assign Permission
													</Button>
													{/* <Button
														sx={{ mt: 2 }}
														onClick={handleRemovePermission}
														variant="contained"
														color="primary"
													>
														Remove Permission
													</Button> */}
												</div>
											</div>
										</FormControl>
									</div>
								</div>
							</div>
						) : message === "Permissions set for this user" ? (
							<div>
								<h2
									style={{
										color: "green",
										border: "1px solid green",
										backgroundColor: "#E8E8E8",
										borderRadius: "20px",
										padding: "10px",
										fontSize: "14px",
										textAlign: "center",
										fontWeight: "500",
									}}
								>
									{message} ☝🏽
								</h2>
								<div>
									<FormControl fullWidth sx={{ mt: 2 }}>
										<InputLabel id="demo-simple-select-label">
											Select Permissions
										</InputLabel>
										<Select
											labelId="mutiple-select-label"
											multiple
											fullWidth
											value={selectedPermission}
											label="Select Permissions"
											renderValue={(selected) => selected.join(", ")}
											MenuProps={MenuProps}
											onChange={handlePermissionSelectChange}
										>
											{getPermissions.map((permission) => (
												<MenuItem key={permission.id} value={permission.id}>
													{permission.name}
												</MenuItem>
											))}
										</Select>
										<div style={{ display: "flex", flexDirection: "column" }}>
											<div style={{ display: "flex", flexDirection: "column" }}>
												<Button
													sx={{ mt: 2 }}
													onClick={handleAssignPermission}
													variant="contained"
													color="secondary"
												>
													Assign Permission
												</Button>
												<Button
													sx={{ mt: 2 }}
													onClick={handleRemovePermission}
													variant="contained"
													color="primary"
												>
													Remove Permission
												</Button>
											</div>
										</div>
									</FormControl>
								</div>
							</div>
						) : null}
					</Div>
				</Box>
			</Modal>
		</div>
	);
};

export default Admins;
