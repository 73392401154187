import { config } from "../config/main";
import { Box, Icon, styled, Tooltip, tooltipClasses } from "@mui/material";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton } from "@mui/x-data-grid";

export function CustomToolbar() {
	return (
		<GridToolbarContainer>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			{/* <GridToolbarExport /> */}
		</GridToolbarContainer>
	);
}

export const getAssetPath = (url, size) => {
	if (config.useImagePlaceHolder) {
		return `https://via.placeholder.com/${size}.png`;
	}

	return url;
};

export const _paths_ = {
	HOME: "/home",
	DASHBOARD: "/dashboard",
	COMPLIANCE: "/compliance",
	USERS: "/users",
	CLUBS: "/clubs",
	MONEYREQUESTS: "/moneyrequests",
	TRANSACTIONS: "/transactions",
	KYC: "/kyc",
	VERIFICATION: "/kyc/verification",
	PERMISSIONS: "/permissions",
	NOTIFICATIONS: "/notifications",
	// Deals
	// DEALS: "/deals",
	CATALOGS: "/deals/catalogs",
	PRODUCTS: "/deals/catalogs/products",
	ORDERS: "/deals/orders",
	// Auth
	SIGN_IN: "/sign-in",
	SIGN_IN_HOME: "/",
	SIGN_UP: "/sign-up",
};

function customHeader(_header = {}) {
	const header = new Headers(_header);
	return header;
}

export const poster = async (url, data, contentType = "application/json") => {
	return fetch(url, {
		method: "POST",
		headers: customHeader({ "Content-Type": contentType }),
		body: JSON.stringify(data),
	});
};

export const updater = async (url, data, contentType = "application/json") => {
	return fetch(url, {
		method: "PUT",
		headers: customHeader({ "Content-Type": contentType }),
		body: JSON.stringify(data),
	});
};

export const getter = async (
	url,
	data = {},
	contentType = "application/json"
) => {
	return fetch(url, { headers: customHeader({ "Content-Type": contentType }) });
};

export const deleter = async (
	url,
	data = {},
	contentType = "application/json"
) => {
	return fetch(url, {
		method: "DELETE",
		headers: customHeader({ "Content-Type": contentType }),
	});
};

export const patcher = async (url, data, contentType = "application/json") => {
	return fetch(url, {
		method: "PATCH",
		headers: customHeader({ "Content-Type": contentType }),
		body: JSON.stringify(data),
	});
};

export const ResponseCodes = {
	Unauthorized: 401,
	Sucess: 200,
};

export const storage = {
	local: {
		get: (key) => {
			if (localStorage) {
				return localStorage.getItem(key)
					? JSON.parse(localStorage.getItem(key))
					: null;
			}

			return false;
		},
		set: (key, data) => {
			if (localStorage) {
				return localStorage.setItem(key, JSON.stringify(data));
			}

			return false;
		},
		remove: (key) => {
			if (localStorage && localStorage.getItem(key)) {
				localStorage.removeItem(key);
				return true;
			}

			return false;
		},
	},
};

// export const dateRange = [
// 	{
// 		key: 1,
// 		label: "1D",
// 		start: new Date().toISOString().split("T")[0] + "T00:00:00.000Z",
// 		end: new Date().toISOString(),
// 	},
// 	{
// 		key: 2,
// 		label: "5D",
// 		start:
// 			new Date(new Date().setDate(new Date().getDate() - 5))
// 				.toISOString()
// 				.split("T")[0] + "T00:00:00.000Z",
// 		end: new Date().toISOString(),
// 	},
// 	{
// 		key: 3,
// 		label: "1M",
// 		start:
// 			new Date(new Date().setMonth(new Date().getMonth() - 1))
// 				.toISOString()
// 				.split("T")[0] + "T00:00:00.000Z",
// 		end: new Date().toISOString(),
// 	},
// 	{
// 		key: 4,
// 		label: "6M",
// 		start:
// 			new Date(new Date().setMonth(new Date().getMonth() - 6))
// 				.toISOString()
// 				.split("T")[0] + "T00:00:00.000Z",
// 		end: new Date().toISOString(),
// 	},
// 	{
// 		key: 5,
// 		label: "YTD",
// 		start:
// 			new Date(new Date().getFullYear(), 0, 1).toISOString().split("T")[0] +
// 			"T00:00:00.000Z",
// 		end: new Date().toISOString(),
// 	},
// ];

export const dateRangePicker = {
	daily: () => {
		const startDate = new Date();
		const endDate = new Date();

		startDate.setDate(startDate.getDate() - 0);
		startDate.setHours(0);
		startDate.setMinutes(0);
		startDate.setSeconds(0);

		return {
			startDate: startDate.toISOString(),
			endDate: endDate.toISOString(),
		};
	},
	five_days: () => {
		const startDate = new Date();
		const endDate = new Date();

		startDate.setDate(startDate.getDate() - 5);
		startDate.setHours(0);
		startDate.setMinutes(0);
		startDate.setSeconds(0);

		return {
			startDate: startDate.toISOString(),
			endDate: endDate.toISOString(),
		};
	},
	monthly: () => {
		const startDate = new Date();
		const endDate = new Date();

		startDate.setDate(startDate.getDate() - 30);
		startDate.setHours(0);
		startDate.setMinutes(0);
		startDate.setSeconds(0);

		return {
			startDate: startDate.toISOString(),
			endDate: endDate.toISOString(),
		};
	},
	six_months: () => {
		const startDate = new Date();
		const endDate = new Date();

		startDate.setDate(startDate.getDate() - 180);
		startDate.setHours(0);
		startDate.setMinutes(0);
		startDate.setSeconds(0);

		return {
			startDate: startDate.toISOString(),
			endDate: endDate.toISOString(),
		};
	},
	ytd: () => {
		const startDate = new Date();
		const endDate = new Date();

		startDate.setDate(startDate.getDate() - 365);
		startDate.setHours(0);
		startDate.setMinutes(0);
		startDate.setSeconds(0);

		return {
			startDate: startDate.toISOString(),
			endDate: endDate.toISOString(),
		};
	},
};

export function recursive(accesses, parent) {
	const nodes = [];

	accesses = accesses.filter(
		(x) => x.id !== null || x.id !== undefined || x.id !== ""
	);

	accesses
		.filter(function (d) {
			return d.parentId === parent;
		})
		.forEach(function (d) {
			const cd = d;
			cd.uri = cd.path;
			cd.label = cd.name;
			cd.icon = (
				<Icon
					style={{
						fontSize: "1.1rem",
					}}
				>
					{cd.icon}
				</Icon>
			);
			cd.children = recursive(accesses, d.id);
			cd.type = cd.children.length ? "collapsible" : "nav-item";
			return nodes.push(cd);
		});

	return nodes;
}

/**
 * Function to restruecture accesses
 */
export async function resturctureAccess(accesses, permissions, type = "page") {
	// const request = await PermissionsAPI.getAccess();
	// const response = request.data?.data;
	// console.log("accesses", accesses);
	const response =
		type === "page"
			? accesses?.filter((x) => x.isPage)
			: accesses?.filter((x) => x.isAction);

	if (response) {
		const temp = [];

		if (permissions && permissions.length) {
			for (const permission of permissions) {
				if (permission.accesses && permission.accesses.length) {
					for (const access of permission.accesses) {
						// console.log(
						// 	{
						// 		access,
						// 		isMatch: response.map((x) => x.id).includes(access.id),
						// 		accessId: access.id,
						// 	},
						// 	"access match"
						// );
						if (response.map((x) => x.id).includes(access.id)) {
							if (!temp.map((x) => x.id).includes(access.id)) {
								temp.push(access);
							}
						}
					}
				}
			}
		}

		const result = recursive(temp, null);
		return {
			structuredUserMenus: result,
			unstructuredUserMenus: temp,
			originalAccesses: accesses,
		};
	}

	// console.log(response);
}

export const ACTIONS_IDENTIFIERS = {
	MANUAL_OVERRIDE: "manual_kyc_override",
	APPROVE_KYC: "approve_kyc",
	REMOVE_APPROVERS: "remove_approvers",
};

export const STATUSCODES = {
	SUCCESS: [200, 201, 204],
	REDIRECT: [301, 302],
	CLIENT_ERROR: [400, 401, 403, 404, 405, 408, 409, 410, 429],
	SERVER_ERROR: [500, 501, 502, 503, 504, 505],
};

export function dateDiffInDays(a, b) {
	try {
		const _MS_PER_DAY = 1000 * 60 * 60 * 24;
		// Discard the time and time-zone information.
		const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
		const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

		return Math.floor((utc2 - utc1) / _MS_PER_DAY);
	} catch (error) {
		return "An error occured [dateDiffInDays]";
	}
}

export const StyledDataGrid = styled(DataGrid)`
	&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
	&.MuiDataGrid-root .MuiDataGrid-cell:focus {
		outline: none;
	}
`;

export const HtmlTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "#f5f5f9",
		color: "rgba(0, 0, 0, 0.87)",
		maxWidth: 210,
		fontSize: theme.typography.pxToRem(12),
		border: "1px solid #dadde9",
	},
}));

export function stringToColor(string) {
	let hash = 0;
	let i;

	/* eslint-disable no-bitwise */
	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = "#";

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.substr(-2);
	}
	/* eslint-enable no-bitwise */

	return color;
}

export function stringAvatar(name) {
	return {
		sx: {
			bgcolor: stringToColor(name),
		},
		children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
	};
}

export const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};

const StyledGridOverlay = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	height: "100%",
	"& .ant-empty-img-1": {
		fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
	},
	"& .ant-empty-img-2": {
		fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
	},
	"& .ant-empty-img-3": {
		fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
	},
	"& .ant-empty-img-4": {
		fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
	},
	"& .ant-empty-img-5": {
		fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
		fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
	},
}));

export function CustomNoRowsOverlay() {
	return (
		<StyledGridOverlay>
			<svg
				width="120"
				height="100"
				viewBox="0 0 184 152"
				aria-hidden
				focusable="false"
			>
				<g fill="none" fillRule="evenodd">
					<g transform="translate(24 31.67)">
						<ellipse
							className="ant-empty-img-5"
							cx="67.797"
							cy="106.89"
							rx="67.797"
							ry="12.668"
						/>
						<path
							className="ant-empty-img-1"
							d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
						/>
						<path
							className="ant-empty-img-2"
							d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
						/>
						<path
							className="ant-empty-img-3"
							d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
						/>
					</g>
					<path
						className="ant-empty-img-3"
						d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
					/>
					<g className="ant-empty-img-4" transform="translate(149.65 15.383)">
						<ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
						<path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
					</g>
				</g>
			</svg>
			<Box sx={{ mt: 1 }}>No Rows</Box>
		</StyledGridOverlay>
	);
}