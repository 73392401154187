import React, { useEffect, useState } from "react";
import { CloseOutlined } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";

const ContentUpload = ({ setFileDrop, fileDrop, title }) => {
	const [files, setFiles] = useState([]);

	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/jpg, image/jpeg, image/png",
		maxFiles: 1,
		maxSize: 1 * 1048576, //approximately 1mb
		onDrop: (acceptedFiles) => {
			setFiles((existingFiles) => [
				...existingFiles,
				...acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				),
			]);
			// setFileDrop( acceptedFiles[0] );
			const reader = new FileReader();
			reader.readAsDataURL(acceptedFiles[0]);
			reader.onloadend = () => {
				const base64 = reader.result;
				setFileDrop(base64);
			};
		},
	});

	const handleDelete = (file) => {
		setFiles((curr) => curr.filter((f) => f.name !== file.name));
	};

	useEffect(
		() => () => {
			// To avoid memory leaks
			files.forEach((file) => URL.revokeObjectURL(file.preview));
		},
		[files]
	);
	return (
		<div className="dropzone-main-wrapper">
			<div className="dropzone-wrapper drop1">
				<div className="" {...getRootProps({ className: "dropzone" })}>
					<input {...getInputProps()} required />
					<div
						style={{
							display: "flex",
							alignItems: "center",
							flexDirection: "column",
						}}
					>
						<div style={{ textAlign: "center" }}>
							<p style={{ fontSize: "18px" }}>{title}</p>
							<p style={{ marginTop: "10px", color: "grey" }}>Drag n Drop</p>
							<div style={{ fontSize: "10px" }}>
								<em>png, jpg, jpegs</em>
								<p style={{ fontStyle: "italic", fontSize: "10px" }}>
									max size 1mb
								</p>
							</div>

							<p style={{ marginTop: "10px", color: "grey" }}>or</p>
						</div>
						<div>
							<p
								style={{
									backgroundColor: "#62A933",
									color: "white",
									fontWeight: "500",
									padding: "10px 20px",
									borderRadius: "20px",
									marginTop: "10px",
									cursor: "pointer",
									fontSize: "14px",
								}}
							>
								Click to select file
							</p>
						</div>
					</div>
				</div>
				<div>
					{files.map((file) => (
						<div key={file.name}>
							<div>
								<div>
									{file.type === "application/pdf" ? (
										<div className="preview-div">
											<div>
												<PictureAsPdfOutlinedIcon width={40} />
												<p style={{ fontSize: "12px" }}>
													{(file.size / 1000000).toFixed(2)}mb
												</p>
											</div>
											<div style={{ display: "flex" }}>
												<p>{file.name}</p>
											</div>
											<div>
												<span
													style={{
														cursor: "pointer",
													}}
												>
													<CloseOutlined onClick={() => handleDelete(file)} />
												</span>
											</div>
										</div>
									) : (
										<div className="preview-div">
											<div>
												<img
													src={file.preview}
													alt="preview"
													height={30}
													width={30}
													className="rounded-md"
													onLoad={() => {
														URL.revokeObjectURL(file.preview);
													}}
												/>
												<p style={{ fontSize: "12px" }}>
													{(file.size / 1000000).toFixed(2)}mb
												</p>
											</div>
											<div style={{ display: "flex" }}>
												<p>{file.name}</p>
											</div>
											<div>
												<span
													style={{
														cursor: "pointer",
													}}
												>
													<CloseOutlined onClick={() => handleDelete(file)} />
												</span>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};;;;

export default ContentUpload;
