import { useAuthState } from "app/providers/AuthContext";
import React from "react";

const Home = () => {
	const { user } = useAuthState();

	return (
		<div>
			<h1 style={{ fontSize: "28px", textAlign: "center" }}>
				Welcome {user.firstName} 🚀
			</h1>

			<div>
				<h2 style={{ fontSize: "20px", textAlign: "center" }}>
					To get started, please select a page from the sidebar.
				</h2>
				<div
					style={{
						width: "100%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<img
						src={"/images/welcome-img.svg"}
						alt="Welcome"
						style={{ width: "90%", height: "auto" }}
					/>
				</div>
			</div>
		</div>
	);
};

export default Home;
