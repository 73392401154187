import React from "react";
import * as yup from "yup";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import { Alert, Card, CardContent, Snackbar } from "@mui/material";
import { Form, Formik } from "formik";
import { UserAPI } from "app/routes/middleware";
import Verification from "./verification";
import { Helmet } from "react-helmet-async";

const validationSchema = yup.object({
	msisdn: yup
		.string("Enter your phone number")
		.required("Phone number is required"),
});

const Kyc = () => {
	const [show, setShow] = React.useState(true);
	const [user, setUser] = React.useState(null);
	const [error, setError] = React.useState(null);
	const [open, setOpen] = React.useState(false);
	const [message, setMessage] = React.useState("");
	const [severity, setSeverity] = React.useState("success");
	const [userExists, setUserExists] = React.useState(null);
	const onSubmit = async (msisdn, setSubmitting) => {
		let phone = parseInt(msisdn);

		try {
			const request = await UserAPI.getById(phone);
			setSubmitting(true);
			const response = request?.data?.data?.result[0];
			const existence = request.data.data.result.length > 0 ? true : false;
			setUserExists(existence);
			console.log(request, "response");
			setUser(response);
			setShow(false);
		} catch (error) {
			setSubmitting(false);
			setError(error);
			if (error?.response?.status === 401) {
				setMessage(
					"Sorry your sign in token has expired, please sign in again to continue using Spark Admin 🤭"
				);
				setSeverity("error");
				setOpen(true);
				localStorage.clear("@spark-session");
				window.location.replace("/sign-in");
			}
			// console.log(error.message);
		}
	};
	return (
		<>
			<Helmet>
				<title>Manual KYC - Spark Dashboard</title>
			</Helmet>
			<Snackbar
				open={open}
				autoHideDuration={2000}
				onClose={() => setOpen(false)}
			>
				<Alert
					onClose={() => setOpen(false)}
					severity={severity}
					sx={{ width: "60%" }}
				>
					{message}
				</Alert>
			</Snackbar>
			{show === true ? (
				<div>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							marginTop: "-30px",
						}}
					>
						<h2>KYC</h2>
					</div>
					<Div
						sx={{
							flex: 1,
							flexWrap: "wrap",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
							p: (theme) => theme.spacing(4),
						}}
					>
						<Card sx={{ maxWidth: "100%", width: 360, mb: 4 }}>
							<CardContent sx={{ pt: 0 }}>
								<Formik
									validateOnChange={true}
									initialValues={{
										msisdn: "",
									}}
									validationSchema={validationSchema}
									onSubmit={(data, { setSubmitting }) => {
										onSubmit(data.msisdn, setSubmitting);
									}}
								>
									{({ isSubmitting }) => (
										<Form
											style={{ textAlign: "left" }}
											noValidate
											autoComplete="off"
										>
											<Div sx={{ mb: 3, mt: 5 }}>
												<p
													style={{
														color: "red",
														fontSize: "12px",
														marginBottom: "30px",
														textAlign: "center",
														fontWeight: "500",
													}}
												>
													{error
														? "😞 Sorry an error occurred, please refresh and try again"
														: null}
												</p>
												<JumboTextField
													fullWidth
													name="msisdn"
													label="Phone Number (+233123456789)"
												/>
											</Div>
											<LoadingButton
												fullWidth
												type="submit"
												variant="contained"
												size="large"
												color="secondary"
												sx={{ mb: 2 }}
												loading={isSubmitting}
											>
												VALIDATE
											</LoadingButton>
										</Form>
									)}
								</Formik>
							</CardContent>
						</Card>
					</Div>
				</div>
			) : (
				<Verification user={user} existence={userExists} />
			)}
		</>
	);
};;

export default Kyc;
