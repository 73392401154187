import React from "react";
import {
	Alert,
	Card,
	CardContent,
	Checkbox,
	FormControlLabel,
	IconButton,
	InputAdornment,
	Typography,
} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Stack from "@mui/material/Stack";
import { Facebook, Google, Twitter } from "@mui/icons-material";
import Link from "@mui/material/Link";
import Avatar from "@mui/material/Avatar";
import shadows from "@mui/material/styles/shadows";
import { alpha } from "@mui/material/styles";
import { auth } from "@jumbo/services/auth/firebase/firebase";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { useAuthSignInWithEmailAndPassword } from "@react-query-firebase/auth";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import Div from "@jumbo/shared/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";
import { ASSET_AVATARS } from "../../../utils/constants/paths";
import { getAssetPath, storage, _paths_ } from "../../../utils/appHelpers";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
	contextSignIn,
	useAuthDispatch,
	useAuthState,
} from "app/providers/AuthContext";
// import { Modal } from "@mui/material";
import { AdminAPI } from "app/routes/middleware";
import { CreatePasswordModal, SignUpModal } from "app/components/Modals";

const validationSchema = yup.object({
	email: yup
		.string("Enter your email")
		.email("Enter a valid email")
		.required("Email is required"),
	password: yup.string("Enter your password").required("Password is required"),
});

const SignIn = () => {
	const { user, error } = useAuthState();
	const dispatch = useAuthDispatch();
	const navigate = useNavigate();
	const { setActiveLayout } = useJumboApp();
	const [showPassword, setShowPassword] = React.useState(false);
	const [openSignUpModal, setOpenSignupModal] = React.useState(false);
	const [errorModalMessage, setErrorModalMessage] = React.useState("");
	const [openCreatePasswordModal, setOpenCreatePasswordModal] =
		React.useState(false);
	const [createPasswordModalMessage, setCreatePasswordModalMessage] =
		React.useState("");
	const [email, setEmail] = React.useState("");
	const [alertText, setAlertText] = React.useState("");
	const [accessToken, setAccessToken] = React.useState({});
	const [successMessage, setSuccessMessage] = React.useState("");

	React.useEffect(() => {
		dispatch({
			status: "idle",
			user: null,
			token: null,
			error: null,
			allAccesses: null,
		});
		if (user) {
			navigate("/home", { replace: true });
		}

		setActiveLayout(LAYOUT_NAMES.SOLO_PAGE);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setActiveLayout]);

	// const mutation = useAuthSignInWithEmailAndPassword(auth, {
	// 	onError(error) {
	// 		console.log(error, "signin error");
	// 	},
	// 	onSuccess(data) {
	// 		navigate("/home", { replace: true });
	// 	},
	// });

	const onSignIn = async (email, password, setSubmitting) => {
		storage.local.set("@spark-session", {
			status: "idle",
			user: null,
			token: null,
			error: null,
			allAccesses: null,
		});
		await contextSignIn(
			dispatch,
			{ email, password },
			setSubmitting,
			navigate,
			_paths_.HOME
		);
		// return <Navigate to={_paths_.DASHBOARD} />
	};
	// console.log(error, "error");

	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleSignUpModalOpen = () => setOpenSignupModal(true);
	const handleSignUpModalClose = () => setOpenSignupModal(false);

	const handleCreatePasswordModalOpen = () => setOpenCreatePasswordModal(true);
	const handleCreatePasswordModalClose = () =>
		setOpenCreatePasswordModal(false);

	const handleOtpConfirmation = async (email, otp, setSubmitting) => {
		setSubmitting(true);
		const data = {
			email: email,
			OTP: otp,
		};

		try {
			setSubmitting(true);
			const request = await AdminAPI.confirmOtp(data);
			const response = request.data;
			if (response.status === 200) {
				setAccessToken(response.data.auth);
				setSubmitting(false);
				setEmail(email);
				setOpenSignupModal(false);
				setOpenCreatePasswordModal(true);
			}
		} catch (error) {
			setSubmitting(false);
			// console.log(error.response?.data, "otp confirmation error");
			setErrorModalMessage(error.response?.data.message);
		}
	};

	const handleCreatePassword = async (
		email,
		password,
		confirmPassword,
		setSubmitting
	) => {
		setSubmitting(true);
		const data = {
			email: email,
			password: password,
			confirmPassword: confirmPassword,
		};
		const token = accessToken.accessToken;
		const query = {
			data: data,
			token: token,
		};
		try {
			setSubmitting(true);
			const request = await AdminAPI.createPassword(query);
			const response = request.data;
			if (request.status === 200) {
				setSubmitting(false);
				setSuccessMessage(response.data.message);
				setOpenCreatePasswordModal(false);
				setAlertText(`${response.message} You can sign in now 🚀`);
			}
		} catch (error) {
			setSubmitting(false);
			// console.log(error.response?.data, "create password error");
			setCreatePasswordModalMessage(error.response?.data.message);
		}
	};

	return (
		<Div
			sx={{
				flex: 1,
				flexWrap: "wrap",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				backgroundColor: "#62A933",
				p: (theme) => theme.spacing(4),
			}}
		>
			<Div sx={{ mb: 3, display: "inline-flex" }}>
				<Link href="#" underline="none" sx={{ display: "inline-flex" }}>
					<img src={"/images/logos/icon.png"} width={50} alt="Spark Inc" />
					<img
						src={"/images/logos/spark-logo.svg"}
						width={60}
						alt="Spark Inc"
					/>
				</Link>
			</Div>
			<Card sx={{ maxWidth: "100%", width: 360, mb: 4 }}>
				<Div sx={{ position: "relative", height: "200px" }}>
					<CardMedia
						component="img"
						alt="green iguana"
						height="200"
						image={
							"https://imgs.search.brave.com/26XrHQfJKdLq7IuFbaxiBi3MNnWrbblVLU5YOvU2SoU/rs:fit:909:225:1/g:ce/aHR0cHM6Ly90c2Uy/Lm1tLmJpbmcubmV0/L3RoP2lkPU9JUC4w/cU5UUUdPOVVPdXJQ/NjFHR1BTRFJRSGFE/MyZwaWQ9QXBp"
						}
					/>
					<Div
						sx={{
							flex: 1,
							inset: 0,
							position: "absolute",
							display: "flex",
							alignItems: "center",
							backgroundColor: (theme) =>
								alpha(theme.palette.common.black, 0.5),
							p: (theme) => theme.spacing(3),
						}}
					>
						<Typography
							variant={"h2"}
							sx={{
								color: "common.white",
								fontSize: "1.5rem",
								mb: 0,
							}}
						>
							Sign In
						</Typography>
					</Div>
				</Div>
				<CardContent sx={{ pt: 0 }}>
					<Formik
						validateOnChange={true}
						initialValues={{
							email: "",
							password: "",
						}}
						validationSchema={validationSchema}
						onSubmit={(data, { setSubmitting }) => {
							onSignIn(data.email, data.password, setSubmitting);
						}}
					>
						{({ isSubmitting }) => (
							<Form style={{ textAlign: "left" }} noValidate autoComplete="off">
								<Avatar
									alt="Remy Sharp"
									src={getAssetPath(`${ASSET_AVATARS}/avatar5.jpg`)}
									sx={{
										width: 56,
										height: 56,
										marginLeft: "auto",
										boxShadow: shadows[3],
										transform: "translateY(-50%)",
									}}
								/>
								{error && (
									<Alert
										severity="error"
										sx={{
											mb: 3,
											mt: -2,
											fontSize: "14px",
											fontFamily: "inherit",
										}}
									>
										{error}
									</Alert>
								)}
								{alertText && <Div sx={{ mb: 3 }}>{alertText}</Div>}
								<Div sx={{ mb: 3, mt: 1 }}>
									<JumboTextField fullWidth name="email" label="Email" />
								</Div>
								<Div style={{ position: "relative" }} sx={{ mb: 2, mt: 1 }}>
									<div>
										<JumboTextField
											fullWidth
											name="password"
											label="Password"
											type={`${showPassword ? "text" : "password"}`}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton onClick={handleShowPassword}>
															{!showPassword ? (
																<VisibilityOffIcon />
															) : (
																<RemoveRedEyeIcon />
															)}
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
									</div>
								</Div>

								{/* <Div sx={{ mb: 2, mt: 1 }}>
									<Typography
										textAlign={"right"}
										variant={"body1"}
										sx={{ fontSize: "12px" }}
									>
										<Link underline="none" href="#">
											Forgot your password?
										</Link>
									</Typography>
								</Div> */}
								<LoadingButton
									fullWidth
									type="submit"
									variant="contained"
									size="large"
									sx={{ mb: 3 }}
									// loading={isSubmitting || mutation.isLoading}
									loading={isSubmitting}
								>
									Login
								</LoadingButton>
								<Typography textAlign={"center"} variant={"body1"} mb={1}>
									Don't have access?
								</Typography>
								<Typography
									onClick={handleSignUpModalOpen}
									textAlign={"center"}
									color={"primary"}
									sx={{ cursor: "pointer", mb: 3 }}
								>
									Sign up with your otp and Email
								</Typography>
							</Form>
						)}
					</Formik>
				</CardContent>
			</Card>
			<SignUpModal
				open={openSignUpModal}
				handleClose={handleSignUpModalClose}
				handleOpen={handleSignUpModalOpen}
				handleOtpConfirmation={handleOtpConfirmation}
				errorModalMessage={errorModalMessage}
			/>

			<CreatePasswordModal
				openCreatePasswordModal={openCreatePasswordModal}
				handleCreatePasswordModalClose={handleCreatePasswordModalClose}
				handleCreatePasswordModalOpen={handleCreatePasswordModalOpen}
				handleCreatePassword={handleCreatePassword}
				errorModalMessage={createPasswordModalMessage}
				email={email}
				setAlertText={setAlertText}
				alertText={alertText}
				successMessage={successMessage}
			/>
		</Div>
	);
};

export default SignIn;
