import React from "react";
import Box from "@mui/material/Box";
import {
	DataGrid,
	GridActionsCellItem,
} from "@mui/x-data-grid";
import UnfoldMoreOutlinedIcon from "@mui/icons-material/UnfoldMoreOutlined";
import { Modal, Typography } from "@mui/material";
import { ModalStyle } from "app/utils/constants/styles";
import { CustomNoRowsOverlay, CustomToolbar } from "app/utils/appHelpers";

const Table = ({
	rows,
	page,
	rowsPerPageOptions,
	columns,
	rowModesModel,
	handleRowEditStart,
	handleRowEditStop,
	processRowUpdate,
	setRows,
	setRowModesModel,
	pagination,
	paginationMode,
	pageSize,
	onPageChange,
	onPageSizeChange,
	rowCount,
	disableSelectionOnClick,
	isLoading,
}) => {
	const [open, setOpen] = React.useState(false);
	const [state, setState] = React.useState();
	const handleOpen = (id) => {
		setState(id);
		setOpen(true);
	};
	const handleClose = () => setOpen(false);
	const actions = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 100,
			cellClassName: "actions",
			getActions: (params) => {
				return [
					<GridActionsCellItem
						icon={<UnfoldMoreOutlinedIcon />}
						label="Edit"
						className="textPrimary"
						onClick={() => {
							let id = params.id;
							// console.log("params", params.id);
							handleOpen(id);
						}}
						// onClick={handlePreviewClick(params.id)}
						color="inherit"
					/>,
				];
			},
		},
	];
	return (
		<Box
			sx={{
				backgroundColor: "background.paper",
				borderRadius: 3,
				height: 500,
				width: "100%",
				"& .actions": {
					color: "text.secondary",
				},
				"& .textPrimary": {
					color: "text.primary",
				},
			}}
		>
			<DataGrid
				style={{ padding: "0px 20px" }}
				rowCount={rowCount}
				loading={isLoading}
				page={page}
				pagination
				paginationMode={paginationMode}
				pageSize={pageSize}
				onPageChange={onPageChange}
				onPageSizeChange={onPageSizeChange}
				disableSelectionOnClick={disableSelectionOnClick}
				rows={rows}
				columns={[
					...columns.map(
						(obj) => actions.find((o) => o.field === obj.field) || obj
					),
				]}
				rowsPerPageOptions={rowsPerPageOptions}
				editMode="row"
				rowModesModel={rowModesModel}
				onRowEditStart={handleRowEditStart}
				onRowEditStop={handleRowEditStop}
				processRowUpdate={processRowUpdate}
				components={{
					Toolbar: CustomToolbar,
					NoRowsOverlay: CustomNoRowsOverlay,
				}}
				componentsProps={{
					toolbar: { setRows, setRowModesModel },
				}}
				experimentalFeatures={{ newEditingApi: true }}
				sx={{
					boxShadow: 2,
					"& .MuiDataGrid-cell:hover": {
						color: "primary.main",
					},
				}}
				initialState={{
					sorting: {
						sortModel: [{ field: "firstName", sort: "asc" }],
					},
				}}
			/>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={ModalStyle}>
					<Typography id="modal-modal-title" variant="h6" component="h2">
						User ID -&gt; {state}
					</Typography>
					<Typography id="modal-modal-description" sx={{ mt: 2 }}>
						Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
					</Typography>
				</Box>
			</Modal>
		</Box>
	);
};

export default Table;
