import React from 'react'
import "./style/index.css";
import CustomTab from "app/components/customtab/CustomTab";
import { CatalogsColumns } from "app/components/customtab/tabColumns";
import AccordionTable from "./tables/AccordionTable";
import { AdminDealsAPI } from "app/routes/middleware";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import CreateCatalog from "./modals/createCatalog";
import { Helmet } from "react-helmet-async";

const Catalogs = () => {
	const [isLoading, setIsLoading] = React.useState(false);
	const [fetchedItems, setFetchedItems] = React.useState([]);
	const [fetchedActiveCatalogs, setFetchedActiveCatalogs] = React.useState([]);
	const [fetchedDraftCatalogs, setFetchedDraftCatalogs] = React.useState([]);
	const [fetchedPastCatalogs, setFetchedPastCatalogs] = React.useState([]);
	const [view, setView] = React.useState("ACTIVE");
	const [createOpen, setCreateOpen] = React.useState(false);
	const [openSnackbar, setOpenSnackbar] = React.useState(false);
	const [message, setMessage] = React.useState("");
	const [severity, setSeverity] = React.useState("success");
	const [isCatalogCreateSuccess, setIsCatalogCreateSuccess] =
		React.useState(false);
	const [isCatalogUpdateSuccess, setIsCatalogUpdateSuccess] =
		React.useState(false);
	const [isItemUpdateSuccess, setIsItemUpdateSuccess] = React.useState(false);
	const [isNewItemCreateSuccess, setIsNewItemCreateSuccess] =
		React.useState(false);
	const [isItemInACatalogDeleted, setIsItemInACatalogDeleted] =
		React.useState(false);

	React.useEffect(() => {
		handleGetCatalogs();
		handleGetItems();
		if (
			isCatalogCreateSuccess === true ||
			isCatalogUpdateSuccess === true ||
			isItemUpdateSuccess === true ||
			isNewItemCreateSuccess === true ||
			isItemInACatalogDeleted === true
		) {
			handleGetCatalogs();
			handleGetItems();
		}
	}, [
		isCatalogCreateSuccess,
		isCatalogUpdateSuccess,
		isItemUpdateSuccess,
		isNewItemCreateSuccess,
		isItemInACatalogDeleted,
	]);

	const handleGetCatalogs = async () => {
		setIsLoading(true);
		try {
			const request = await AdminDealsAPI.getCatalogs();
			const response = request.data.data.catalogs;
			// sort the response and make the catalogs with updated at and active statuses be at the top
			const sortedByUpdatedAt = response.sort(
				(a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
			);

			const sortedByIsClosed = sortedByUpdatedAt.sort((a, b) => {
				if (a.isClosed === false) {
					return -1;
				}
				if (b.isClosed === false) {
					return 1;
				}
				return 0;
			});

			setFetchedActiveCatalogs(
				sortedByIsClosed.filter(
					(catalog) => catalog.status === "ACTIVE" && catalog.isClosed === false
				)
			);
			setFetchedDraftCatalogs(
				sortedByIsClosed.filter(
					(catalog) => catalog.status === "DRAFT" && catalog.isClosed === false
				)
			);
			setFetchedPastCatalogs(
				sortedByIsClosed.filter(
					(catalog) =>
						catalog.status === "DELETED" ||
						catalog.status === "EXPIRED" ||
						catalog.isClosed === true
				)
			);
			setIsLoading(false);
			console.log("Catalogs:", response);
		} catch (error) {
			console.log(error);
			setIsLoading(false);
		}
	};

	console.log({ fetchedPastCatalogs });

	const handleGetItems = async () => {
		setIsLoading(true);
		try {
			const request = await AdminDealsAPI.getItems();
			const response = request.data.data;
			setFetchedItems(response);
			setIsLoading(false);
			console.log("Items:", response);
		} catch (error) {
			console.log(error);
			setIsLoading(false);
		}
	};

	return (
		<div>
			<Helmet>
				<title>Deals Catalogs - Spark Dashboard</title>
			</Helmet>
			<Snackbar
				open={openSnackbar}
				autoHideDuration={3000}
				onClose={() => setOpenSnackbar(false)}
			>
				<Alert
					onClose={() => setOpenSnackbar(false)}
					severity={severity}
					sx={{ width: "100%" }}
				>
					{message}
				</Alert>
			</Snackbar>
			<CustomTab
				view={view}
				setView={setView}
				tabColumns={CatalogsColumns}
				setModalOpen={setCreateOpen}
			>
				{view === "ACTIVE" ? (
					isLoading && view === "ACTIVE" ? (
						<div style={{ display: "flex", justifyContent: "center" }}>
							<CircularProgress />
						</div>
					) : isLoading === false &&
					  view === "ACTIVE" &&
					  fetchedActiveCatalogs?.length > 0 ? (
						<AccordionTable
							data={fetchedActiveCatalogs}
							setOpenSnackbar={setOpenSnackbar}
							setMessage={setMessage}
							setSeverity={setSeverity}
							setIsCatalogUpdateSuccess={setIsCatalogUpdateSuccess}
							setIsItemUpdateSuccess={setIsItemUpdateSuccess}
							setIsNewItemCreateSuccess={setIsNewItemCreateSuccess}
							setIsItemInACatalogDeleted={setIsItemInACatalogDeleted}
							handleGetItems={handleGetItems}
						/>
					) : (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								fontWeight: "700",
							}}
						>
							<h3> No catalogs found </h3>
						</div>
					)
				) : null}
				{view === "DRAFT" ? (
					!isLoading &&
					view === "DRAFT" &&
					fetchedDraftCatalogs?.length === 0 ? (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								fontWeight: "700",
							}}
						>
							<h3> No catalogs found </h3>
						</div>
					) : isLoading === false &&
					  view === "DRAFT" &&
					  fetchedDraftCatalogs?.length > 0 ? (
						<AccordionTable
							data={fetchedDraftCatalogs}
							setOpenSnackbar={setOpenSnackbar}
							setMessage={setMessage}
							setSeverity={setSeverity}
							setIsCatalogUpdateSuccess={setIsCatalogUpdateSuccess}
							setIsItemUpdateSuccess={setIsItemUpdateSuccess}
							setIsNewItemCreateSuccess={setIsNewItemCreateSuccess}
							handleGetItems={handleGetItems}
						/>
					) : (
						<div style={{ display: "flex", justifyContent: "center" }}>
							<CircularProgress />
						</div>
					)
				) : null}
				{view === "PAST" ? (
					!isLoading && view === "PAST" && fetchedPastCatalogs?.length === 0 ? (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								fontWeight: "700",
							}}
						>
							<h3> No catalogs found </h3>
						</div>
					) : isLoading === false &&
					  view === "PAST" &&
					  fetchedPastCatalogs?.length > 0 ? (
						<AccordionTable
							data={fetchedPastCatalogs}
							setOpenSnackbar={setOpenSnackbar}
							setMessage={setMessage}
							setSeverity={setSeverity}
							setIsCatalogUpdateSuccess={setIsCatalogUpdateSuccess}
							setIsItemUpdateSuccess={setIsItemUpdateSuccess}
							setIsNewItemCreateSuccess={setIsNewItemCreateSuccess}
							handleGetItems={handleGetItems}
						/>
					) : (
						<div style={{ display: "flex", justifyContent: "center" }}>
							<CircularProgress />
						</div>
					)
				) : null}
			</CustomTab>

			<CreateCatalog
				open={createOpen}
				setCreateOpen={setCreateOpen}
				fetchedItems={fetchedItems}
				setOpenSnackbar={setOpenSnackbar}
				setMessage={setMessage}
				setSeverity={setSeverity}
				setIsCatalogCreateSuccess={setIsCatalogCreateSuccess}
				isCatalogCreateSuccess={isCatalogCreateSuccess}
			/>
		</div>
	);
};

export default Catalogs