export const Columns = [
	{
		field: "id",
		headerName: "ID",
		width: 90,
		editable: true,
		hide: true,
	},
	{
		field: "uuid",
		headerName: "UID",
		width: 90,
		hide: true,
	},
	{
		field: "key",
		headerName: "Key",
		width: 90,
		hide: true,
	},
	{
		field: "owner",
		headerName: "Owner",
		width: 90,
		hide: true,
	},
	{
		field: "properties",
		headerName: "Properties",
		width: 90,
		hide: true,
	},
	{
		field: "scope",
		headerName: "Scope",
		width: 180,
		hide: true,
	},
	{
		field: "type",
		headerName: "Type",
		width: 200,
	},
	{
		field: "status",
		headerName: "Status",
		width: 100,
	},
	{
		field: "title",
		headerName: "Title",
		width: 100,
	},
	{
		field: "description",
		headerName: "Description",
		width: 180,
	},
	{
		field: "value",
		headerName: "Value",
		width: 200,
		// hide: true,
	},
	{
		field: "createdAt",
		headerName: "Created At",
		type: "dateTime",
		valueFormatter: ({ value }) => {
			return value ? value && new Date(value).toUTCString() : "N/A";
		},
	},
	{
		field: "updatedAt",
		headerName: "Updated At",
		type: "dateTime",
		valueFormatter: ({ value }) => {
			return value ? value && new Date(value).toUTCString() : "N/A";
		},
	},
	{
		field: "actions",
		type: "actions",
		headerName: "Actions",
		width: 100,
		cellClassName: "actions",
	},
];
