import { Chip } from "@mui/material";
import { dateRangePicker } from "app/utils/appHelpers";
import React from "react";

const DatePicker = ({ setDateRange }) => {
	const [state, setState] = React.useState({ name: "YTD" });
	const buttons = [
		{ name: "1D", data: dateRangePicker.daily() },
		{ name: "5D", data: dateRangePicker.five_days() },
		{ name: "1M", data: dateRangePicker.monthly() },
		{ name: "6M", data: dateRangePicker.six_months() },
		{ name: "YTD", data: dateRangePicker.ytd() },
	];

	const handleSetDateRange = (n) => {
		// console.log(n);
		const active = buttons.find(({ name }) => name === n);
		setState(active);
		setDateRange(active.data);
	};

	return (
		<>
			{buttons.map((x) => {
				return (
					<Chip
						key={x.name}
						onClick={() => handleSetDateRange(x.name)}
						label={x.name}
						style={{ marginLeft: "5px", cursor: "pointer" }}
						color={state.name === x.name ? "primary" : "default"}
					/>
				);
			})}
		</>
	);
};

export default DatePicker;