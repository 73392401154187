import { Avatar, Chip } from "@mui/material";


export const AdminColumns = [
	{
		field: "id",
		headerName: "ID",
		width: 90,
		editable: true,
		hide: true,
	},
	{
		field: "uuid",
		headerName: "UID",
		width: 90,
		hide: true,
	},
	{
		field: "imageURL",
		headerName: "Avatar",
		width: 80,
		renderCell: (params) => <Avatar src={params?.value || ""} alt="Avatar" />,
	},
	{
		field: "firstName",
		headerName: "First Name",
		width: 180,
		editable: true,
	},
	{
		field: "otherNames",
		headerName: "Other Names",
		width: 180,
		editable: true,
	},
	{
		field: "lastName",
		headerName: "Last Name",
		width: 180,
		editable: true,
	},
	{
		field: "email",
		headerName: "Email",
		width: 180,
		editable: true,
	},
	{
		field: "msisdn",
		headerName: "Phone Number",
		width: 180,
		editable: true,
	},
	{
		field: "status",
		headerName: "Status",
		width: 100,
		editable: true,
		hide: true,
	},
	{
		field: "userType",
		headerName: "User Type",
		width: 180,
		editable: true,
		hide: true,
	},
	// {
	// 	field: "permissions",
	// 	headerName: "Permissions",
	// 	width: 180,
	// 	editable: true,
	// 	type: "number",
	// 	valueFormatter: (params) => {
	// 		return;
	// 	},
	// },
	{
		field: "createdAt",
		headerName: "Created At",
		type: "dateTime",
		valueFormatter: ({ value }) =>
			value ? value && new Date(value).toUTCString() : "N/A",
		width: 250,
		editable: true,
	},
	{
		field: "updatedAt",
		headerName: "Updated At",
		type: "dateTime",
		valueFormatter: ({ value }) =>
			value ? value && new Date(value).toUTCString() : "N/A",
		width: 250,
		editable: true,
	},
	{
		field: "actions",
		type: "actions",
		headerName: "Actions",
		width: 100,
		cellClassName: "actions",
	},
];

export const AccessColumns = [
	{
		field: "id",
		headerName: "ID",
		width: 90,
		// editable: true,
		// hide: true,
	},
	{
		field: "uuid",
		headerName: "UID",
		width: 90,
		hide: true,
	},
	{
		field: "parentId",
		headerName: "Parent ID",
		width: 80,
		editable: true,
		type: "number",
		align: "left",
	},
	{
		field: "name",
		headerName: "Name",
		width: 180,
		editable: true,
		type: "string",
	},
	{
		field: "description",
		headerName: "Description",
		width: 180,
		editable: true,
		type: "string",
	},
	{
		field: "isAction",
		headerName: "Is Action?",
		width: 180,
		editable: true,
		type: "boolean",
		renderCell: (params) => {
			return params.value === true ? (
				<span style={{ marginLeft: 40, color: "#44D600" }}>Yes</span>
			) : (
				<span style={{ marginLeft: 40, color: "red" }}>No</span>
			);
		},
	},
	{
		field: "isPage",
		headerName: "Is Page?",
		width: 180,
		editable: true,
		type: "boolean",
		renderCell: (params) => {
			return params.value === true ? (
				<span style={{ marginLeft: 40, color: "#44D600" }}>Yes</span>
			) : (
				<span style={{ marginLeft: 40, color: "red" }}>No</span>
			);
		},
	},
	{
		field: "path",
		headerName: "Page/Action Path",
		width: 180,
		editable: true,
		type: "string",
	},
	{
		field: "icon",
		headerName: "Icon Name",
		width: 180,
		editable: true,
		type: "string",
	},
	{
		field: "createdAt",
		headerName: "Created At",
		type: "dateTime",
		valueFormatter: ({ value }) =>
			value ? value && new Date(value).toUTCString() : "N/A",
		width: 250,
		editable: true,
	},
	{
		field: "updatedAt",
		headerName: "Updated At",
		type: "dateTime",
		valueFormatter: ({ value }) =>
			value ? value && new Date(value).toUTCString() : "N/A",
		width: 250,
		editable: true,
	},
	{
		field: "actions",
		type: "actions",
		headerName: "Actions",
		width: 100,
		cellClassName: "actions",
	},
];

export const RolesColumns = [
	{
		field: "id",
		headerName: "ID",
		width: 90,
		editable: true,
		hide: true,
	},
	{
		field: "uuid",
		headerName: "UID",
		width: 90,
		hide: true,
	},
	{
		field: "name",
		headerName: "Name",
		width: 180,
		editable: true,
	},
	{
		field: "description",
		headerName: "Description",
		width: 180,
		editable: true,
	},
	{
		field: "isActive",
		headerName: "Is Active?",
		width: 180,
		editable: true,
		type: "boolean",
		renderCell: (params) => {
			return params.value === true ? (
				<span style={{ marginLeft: 40, color: "#44D600" }}>Yes</span>
			) : (
				<span style={{ marginLeft: 40, color: "red" }}>No</span>
			);
		},
	},
	{
		field: "status",
		headerName: "Status",
		width: 100,
		editable: true,
	},
	{
		field: "createdAt",
		headerName: "Created At",
		type: "dateTime",
		valueFormatter: ({ value }) =>
			value ? value && new Date(value).toUTCString() : "N/A",
		width: 250,
		editable: true,
	},
	{
		field: "updatedAt",
		headerName: "Updated At",
		type: "dateTime",
		valueFormatter: ({ value }) =>
			value ? value && new Date(value).toUTCString() : "N/A",
		width: 250,
		editable: true,
	},
	{
		field: "actions",
		type: "actions",
		headerName: "Actions",
		width: 100,
		cellClassName: "actions",
	},
];
