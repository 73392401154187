import {
	Box,
	Button,
	Checkbox,
	FormControl,
	FormHelperText,
	IconButton,
	InputLabel,
	ListItemText,
	MenuItem,
	Modal,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import Div from "@jumbo/shared/Div/Div";
import { CatalogImageUpload, ItemImageUpload } from "../imageUpload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { AdminDealsAPI } from "app/routes/middleware";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { STATUSCODES } from "app/utils/appHelpers";

const CreateCatalog = ({
	open,
	setCreateOpen,
	fetchedItems,
	setOpenSnackbar,
	setMessage,
	setSeverity,
	isCatalogCreateSuccess,
	setIsCatalogCreateSuccess,
}) => {
	const [files1, setFiles1] = useState([]);
	const [files2, setFiles2] = useState([]);
	const [isUploading, setIsUploading] = useState(false);
	const [isUploadSuccess, setIsUploadSuccess] = useState(false);
	const [isUploadError, setIsUploadError] = useState("");
	const [fileUrl, setFileUrl] = useState("");
	const [fileDrop, setFileDrop] = useState("");
	const [selectedItems, setSelectedItems] = useState([]);
	const [selectedStatus, setSelectedStatus] = useState("");
	const [isAddExistingItems, setIsAddExistingItems] = useState(null);
	const [isAddNewItems, setIsAddNewItems] = useState(null);
	const [newItems, setNewItems] = useState([]);
	const [newItemImage, setNewItemImage] = useState("");
	const [newItemName, setNewItemName] = useState("");
	const [newItemDescription, setNewItemDescription] = useState("");
	const [newSellingPrice, setNewSellingPrice] = useState("");
	const [newVendorPrice, setNewVendorPrice] = useState("");
	const [newAvailableItems, setNewAvailableItems] = useState("");
	const [editedItemVendorPrice, setEditedItemVendorPrice] = useState({});
	const [editedItemSellingPrice, setEditedItemSellingPrice] = useState({});
	const [editedItemAvailableItems, setEditedItemAvailableItems] = useState({});
	const [isCreatingCatalog, setIsCreatingCatalog] = useState(false);
	const [dateValue, setDateValue] = useState({
		startDate: "",
		endDate: "",
	});

	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
	} = useForm();

	const handleDelete1 = (file) => {
		setFiles1((curr) => curr.filter((f) => f.name !== file.name));
		setFileDrop("");
	};

	const handleDeleteItemImage = (file) => {
		setFiles2((curr) => curr.filter((f) => f.name !== file.name));
		setFileDrop("");
	};

	const mergeEditedAndSelectedItems = () => {
		return selectedItems.map((item) => {
			const editedVendorPrice = parseInt(editedItemVendorPrice[item.id]);
			const editedSellingPrice = parseInt(editedItemSellingPrice[item.id]);
			const editedAvailableItems = parseInt(editedItemAvailableItems[item.id]);

			return {
				id: item.id,
				vendorPrice: editedVendorPrice ? editedVendorPrice : item.vendorPrice,
				sellingPrice: editedSellingPrice
					? editedSellingPrice
					: item.sellingPrice,
				availableItems: editedAvailableItems
					? editedAvailableItems
					: item.availableItems,
				discount: editedVendorPrice
					? parseFloat(
							(
								((editedVendorPrice - editedSellingPrice) / editedVendorPrice) *
								100
							).toFixed()
					  )
					: parseFloat(
							(
								((item.vendorPrice - item.sellingPrice) / item.vendorPrice) *
								100
							).toFixed()
					  ),
			};
		});
	};

	const toggleEditMode = (itemId) => {
		setSelectedItems((prevSelectedItems) =>
			prevSelectedItems.map((item) =>
				item.id === itemId
					? {
							...item,
							editMode: !item.editMode,
					  }
					: item
			)
		);
	};

	// Add this function to update edited values
	const handleEditedItemChange = (itemId, field, value) => {
		if (field === "vendorPrice") {
			setEditedItemVendorPrice((prev) => ({
				...prev,
				[itemId]: value,
			}));
		} else if (field === "sellingPrice") {
			setEditedItemSellingPrice((prev) => ({
				...prev,
				[itemId]: value,
			}));
		} else if (field === "availableItems") {
			setEditedItemAvailableItems((prev) => ({
				...prev,
				[itemId]: value,
			}));
		}
	};

	const handleAddNewItem = async () => {
		try {
			setIsUploading(true);
			const imageRequest = await AdminDealsAPI.uploadImage({
				image: newItemImage, // Use the itemImage state for image upload
			});
			if (STATUSCODES.SUCCESS.includes(imageRequest.status)) {
				setMessage("Upload successful 🎉");
				setSeverity("success");
				setOpenSnackbar(true);
				const newItem = {
					newItemName,
					newItemDescription,
					newSellingPrice,
					newVendorPrice,
					newAvailableItems,
					discount: (
						((newVendorPrice - newSellingPrice) / newVendorPrice) *
						100
					).toFixed(),
					image: imageRequest.data.data.imageUrl, // Store the image URL in the item object
				};

				// Add the new item to the list of new items
				setNewItems([...newItems, newItem]);

				// Clear input fields
				setFileDrop("");
				setFiles2([]);
				setNewItemImage(""); // Clear the itemImage state
				setNewItemName("");
				setNewItemDescription("");
				setNewSellingPrice("");
				setNewVendorPrice("");
				setNewAvailableItems("");
				setIsUploadSuccess(true);
				setIsUploading(false);
			}
		} catch (error) {
			setIsUploading(false);
			setIsUploadSuccess(false);
			if (STATUSCODES.CLIENT_ERROR.includes(error?.response?.status)) {
				setMessage("Please try again or please reach out to tech support");
				setSeverity("error");
				setOpenSnackbar(true);
			} else if (STATUSCODES.SERVER_ERROR.includes(error?.response?.status)) {
				setMessage(
					"Sorry this is from us, please try again later or reach out to tech support"
				);
				setSeverity("error");
				setOpenSnackbar(true);
			}
			console.log(error);
		}
	};

	const handleUploadImage = async () => {
		try {
			setIsUploading(true);
			const request = await AdminDealsAPI.uploadImage({
				image: fileDrop,
			});
			if (request.status === 200) {
				setFileDrop("");
				setFileUrl(request.data.data.imageUrl);
				setIsUploadSuccess(true);
				setIsUploading(false);
			}
			// console.log(request, "request");
		} catch (error) {
			// console.log(error);
			setIsUploading(false);
			setIsUploadSuccess(false);
		}
	};

	const handleModalClose = () => {
		setIsUploadError("");
		setFileDrop("");
		setFileUrl("");
		setSelectedItems([]);
		setSelectedStatus("");
		setIsAddExistingItems(false);
		setIsAddNewItems(false);
		setNewItems([]);
		setNewItemImage("");
		setNewItemName("");
		setNewItemDescription("");
		setNewSellingPrice("");
		setNewVendorPrice("");
		setNewAvailableItems("");
		setEditedItemVendorPrice({});
		setEditedItemSellingPrice({});
		setEditedItemAvailableItems({});
		setFiles1([]);
		setFiles2([]);
		setIsUploadSuccess(false);
		setIsUploading(false);
		setIsCreatingCatalog(false);
		setIsCatalogCreateSuccess(false);
		setCreateOpen(false);
		setDateValue(() => {
			return {
				startDate: "",
				endDate: "",
			};
		});
		reset();
	};

	const handleCreateCatalog = async (newCatalog) => {
		setIsCreatingCatalog(true);
		// Create an array for new items
		const newItemsArray = newItems.map((item) => ({
			name: item.newItemName,
			description: item.newItemDescription,
			image: item.image,
			sellingPrice: parseInt(item.newSellingPrice),
			vendorPrice: parseInt(item.newVendorPrice),
			discount: parseFloat(item.discount),
			availableItems: parseInt(item.newAvailableItems),
		}));

		const startInputDateTime = new Date(newCatalog.startDate);
		const endInputDateTime = new Date(newCatalog.endDate);

		// Create the payload in the desired format
		const payload = {
			name: newCatalog.name,
			image: fileUrl,
			description: newCatalog.description,
			startDate: startInputDateTime.toISOString(),
			endDate: endInputDateTime.toISOString(),
			status: newCatalog.status,
			minimumOrderValue: newCatalog.minimumOrderValue,
			newItems: newItemsArray,
			items: mergeEditedAndSelectedItems(),
		};

		try {
			const request = await AdminDealsAPI.createCatalog(payload);
			if (STATUSCODES.SUCCESS.includes(request.status)) {
				setIsCatalogCreateSuccess(true);
				setMessage(`Catalog ${newCatalog.name} created successfully! 🎉`);
				setSeverity("success");
				setOpenSnackbar(true);
				setIsCreatingCatalog(false);
				handleModalClose();
			}
		} catch (error) {
			setIsCreatingCatalog(false);
			// console.log(error);
			if (STATUSCODES.CLIENT_ERROR.includes(error?.response?.status)) {
				setMessage(
					"Please make sure you've entered the right details or please reach out to tech support"
				);
				setSeverity("error");
				setOpenSnackbar(true);
			} else if (STATUSCODES.SERVER_ERROR.includes(error?.response?.status)) {
				setMessage(
					"Sorry this is from us, please try again later or reach out to tech support"
				);
				setSeverity("error");
				setOpenSnackbar(true);
			}
		}

		// console.log("Creating new catalog:", payload);
	};

	return (
		<Modal open={open}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: 500,
					bgcolor: "background.paper",
					boxShadow: 24,
					p: 4,
					borderRadius: "8px",
					outline: "none",
					overflow: "auto",
					maxHeight: "95vh",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Typography variant="h5" sx={{ fontWeight: 600 }}>
						Create A Catalog
					</Typography>
					<IconButton onClick={handleModalClose}>
						<CloseIcon />
					</IconButton>
				</div>
				<form>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							flexDirection: "column",
							width: "100%",
							margin: "20px 0px",
						}}
					>
						<CatalogImageUpload
							setFileDrop={setFileDrop}
							title={"Upload catalog image"}
							isUploadSuccess={isUploadSuccess}
							handleDelete={handleDelete1}
							setFiles={setFiles1}
							files={files1}
						/>
						<Button
							color="secondary"
							variant="contained"
							disabled={fileDrop.length === 0}
							sx={{
								mt: 1,
								borderRadius: "10px",
								fontSize: "10px",
								display: "flex",
								alignItems: "center",
							}}
							onClick={handleUploadImage}
						>
							<CloudUploadIcon sx={{ mr: 1 }} />{" "}
							{isUploading && fileDrop.length > 0
								? "Uploading..."
								: isUploading === false &&
								  fileDrop.length === 0 &&
								  isUploadSuccess === true
								? "Done"
								: "Upload Image"}
						</Button>
						{fileUrl.length === 0 && isUploadError && (
							<FormHelperText error>
								Please upload chosen image to proceed
							</FormHelperText>
						)}
					</div>
					<Controller
						name="name"
						control={control}
						defaultValue=""
						rules={{
							required: "Please enter catalog name",
							maxLength: {
								value: 50,
								message: "Catalog name should not exceed 50 characters",
							},
						}}
						render={({ field }) => (
							<>
								<TextField
									fullWidth
									margin="normal"
									label="Name"
									{...field}
									inputProps={{
										maxLength: 50,
									}}
									InputLabelProps={{ shrink: true }}
									sx={{
										mb: 1,
									}}
								/>
								<FormHelperText error>
									{errors.name && errors.name.message}
								</FormHelperText>
							</>
						)}
					/>
					<Controller
						name="description"
						control={control}
						defaultValue=""
						rules={{
							required: "Please enter catalog description",
							maxLength: {
								value: 250,
								message: "Catalog description should not exceed 250 characters",
							},
						}}
						render={({ field }) => (
							<>
								<TextField
									fullWidth
									label="Description"
									margin="normal"
									{...field}
									inputProps={{
										maxLength: 250,
									}}
									InputLabelProps={{ shrink: true }}
									sx={{
										mb: 1,
									}}
								/>
								<FormHelperText error>
									{errors.description && errors.description.message}
								</FormHelperText>
							</>
						)}
					/>
					<Div sx={{ display: "flex", alignItems: "center", my: 1 }}>
						<Controller
							name="startDate"
							control={control}
							rules={{
								required: "Please enter catalog start date",
							}}
							render={({ field }) => (
								<Div
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "flex-start",
									}}
								>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DemoContainer components={["DatePicker"]}>
											<DatePicker
												sx={{ mr: 1 }}
												label="Start Date"
												fullWidth
												format="DD-MM-YYYY"
												margin="normal"
												{...field}
												value={dateValue.startDate}
												InputLabelProps={{ shrink: true }}
												onChange={(newValue) => {
													setDateValue((prev) => ({
														startDate: newValue,
														...prev,
													}));
													field.onChange(dayjs(newValue).format("YYYY-MM-DD"));
												}}
											/>
										</DemoContainer>
									</LocalizationProvider>
									<FormHelperText error>
										{errors.startDate && errors.startDate.message}
									</FormHelperText>
								</Div>
							)}
						/>
						<Controller
							name="endDate"
							control={control}
							rules={{
								required: "Please enter catalog end date",
							}}
							render={({ field }) => (
								<Div
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "flex-start",
									}}
								>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DemoContainer components={["DatePicker"]}>
											<DatePicker
												sx={{ mr: 1 }}
												label="End Date"
												fullWidth
												format="DD-MM-YYYY"
												margin="normal"
												{...field}
												// value={dayjs("DD-MM-YYYY")}
												value={dateValue.endDate}
												InputLabelProps={{ shrink: true }}
												onChange={(newValue) => {
													setDateValue((prev) => ({
														...prev,
														endDate: newValue,
													}));
													field.onChange(dayjs(newValue).format("YYYY-MM-DD"));
												}}
											/>
										</DemoContainer>
									</LocalizationProvider>
									<FormHelperText error>
										{errors.endDate && errors.endDate.message}
									</FormHelperText>
								</Div>
							)}
						/>
					</Div>
					<Div
						sx={{
							display: "flex",
							alignItems: "center",
							mb: 1,
						}}
					>
						<Controller
							name="minimumOrderValue"
							control={control}
							defaultValue={""}
							rules={{ required: "Please enter minimum order value" }}
							render={({ field }) => (
								<Div
									sx={{
										display: "flex",
										flexDirection: "column",
										width: "100%",
										mr: 1,
									}}
								>
									<TextField
										label="Minimum Order Value"
										fullWidth
										margin="normal"
										{...field}
										type="text"
										InputLabelProps={{ shrink: true }}
										onChange={(e) =>
											field.onChange(parseInt(e.target.value) || "")
										} // Parse input to a number or use 0 as the default value
									/>
									<FormHelperText error>
										{errors.minimumOrderValue &&
											errors.minimumOrderValue.message}
									</FormHelperText>
								</Div>
							)}
						/>
						<Controller
							name="status"
							control={control}
							rules={{
								required: "Please select catalog status",
							}}
							render={({ field }) => (
								<Div
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										width: "100%",
										mt: "8px",
									}}
								>
									<FormControl fullWidth>
										<InputLabel id="demo-simple-select-label">
											Status
										</InputLabel>
										<Select
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={selectedStatus}
											label="Status"
											onChange={(e) => {
												const value = e.target.value;
												setSelectedStatus(value);
												field.onChange(value); // Update the form field value
											}}
										>
											<MenuItem value={"ACTIVE"}>Active</MenuItem>
											<MenuItem value={"DRAFT"}>Draft</MenuItem>
											<MenuItem value={"EXPIRED"}>Expired</MenuItem>
											<MenuItem value={"DELETED"}>Deleted</MenuItem>
										</Select>
										<FormHelperText error>
											{errors.status && errors.status.message}
										</FormHelperText>
									</FormControl>
								</Div>
							)}
						/>
					</Div>
					<Div
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							mb: 2,
						}}
					>
						<Button
							variant={
								isAddExistingItems === false && isAddNewItems === true
									? "contained"
									: "outlined"
							}
							color="secondary"
							sx={{ width: "45%", mr: 1, fontSize: "13px" }}
							onClick={() => {
								setIsAddExistingItems(false);
								setIsAddNewItems(!isAddNewItems);
							}}
						>
							Add New Items
						</Button>
						{/* Add Existing Items button */}
						<Button
							variant={
								isAddNewItems === false && isAddExistingItems === true
									? "contained"
									: "outlined"
							}
							color="secondary"
							sx={{ width: "45%", fontSize: "13px" }}
							onClick={() => {
								setIsAddNewItems(false);
								setIsAddExistingItems(!isAddExistingItems);
							}}
						>
							Add Existing Items
						</Button>
					</Div>

					{/* New Items section */}
					{isAddNewItems === true && (
						<div style={{ marginTop: "20px" }}>
							<Typography variant="h6" sx={{ fontWeight: "600" }}>
								Add New Items
							</Typography>
							<ItemImageUpload
								setFileDrop={setNewItemImage} // Set itemImage when an image is dropped or selected
								title="Upload Item Image"
								setFiles={setFiles2}
								files={files2}
								handleDeleteItemImage={handleDeleteItemImage}
							/>
							<TextField
								label="Item Name"
								fullWidth
								margin="normal"
								value={newItemName}
								onChange={(e) => setNewItemName(e.target.value)}
							/>
							<TextField
								label="Item Description"
								fullWidth
								margin="normal"
								value={newItemDescription}
								onChange={(e) => setNewItemDescription(e.target.value)}
							/>
							<TextField
								label="Selling Price"
								fullWidth
								margin="normal"
								type="text"
								value={newSellingPrice}
								onChange={(e) => setNewSellingPrice(e.target.value)}
							/>
							<TextField
								label="Vendor Price"
								fullWidth
								margin="normal"
								type="text"
								value={newVendorPrice}
								onChange={(e) => setNewVendorPrice(e.target.value)}
							/>
							<TextField
								label="Available Items"
								fullWidth
								margin="normal"
								type="text"
								value={newAvailableItems}
								onChange={(e) => setNewAvailableItems(e.target.value)}
							/>
							<Button
								variant="outlined"
								color="secondary"
								onClick={handleAddNewItem}
							>
								{isUploading ? "Uploading Image..." : "Save New Item"}
							</Button>
							{/* Display added items */}
							{newItems.length > 0 && (
								<div>
									<Typography variant="subtitle1" sx={{ mt: 3, mb: -1 }}>
										Added Items 👇🏽
									</Typography>
									<ul>
										{newItems.map((item, index) => (
											<li key={index}>
												{item.itemName} - Discount: {item.discount}%
											</li>
										))}
									</ul>
								</div>
							)}
						</div>
					)}

					{/* Existing Items section */}
					{isAddExistingItems === true && (
						<Controller
							name="items"
							control={control}
							render={({ field }) => (
								<FormControl fullWidth>
									<InputLabel id="demo-multiple-checkbox-label">
										Add existing items to catalog
									</InputLabel>
									<Select
										labelId="demo-multiple-checkbox-label"
										id="demo-multiple-checkbox"
										multiple
										label="	Add existing items to catalog"
										value={selectedItems.map((item) => item.id)} // Use IDs as values
										onChange={(e) => {
											const selectedIds = e.target.value;
											const selectedItemsData = selectedIds.map(
												(selectedId) => {
													const selectedItem = fetchedItems.find(
														(item) => item.id === selectedId
													);
													const itemCatalog = selectedItem.itemCatalog[0]; // Assuming it's an array with one item
													return {
														id: selectedItem.id,
														name: selectedItem.name,
														description: selectedItem.description,
														sellingPrice: itemCatalog.sellingPrice,
														vendorPrice: itemCatalog.vendorPrice,
														availableItems: itemCatalog.availableItems,
														discount: (
															((itemCatalog.vendorPrice -
																itemCatalog.sellingPrice) /
																itemCatalog.vendorPrice) *
															100
														).toFixed(),
													};
												}
											);
											setSelectedItems(selectedItemsData);
											field.onChange(selectedIds); // Update the form field value
										}}
										renderValue={(selected) =>
											selected
												.map(
													(selectedId) =>
														fetchedItems.find((item) => item.id === selectedId)
															.name
												)
												.join(", ")
										}
									>
										{fetchedItems.map((option) => (
											<MenuItem key={option.id} value={option.id}>
												{/* Use option.id as value */}
												<Checkbox
													checked={selectedItems.some(
														(item) => item.id === option.id
													)}
												/>
												<ListItemText primary={option.name} />
											</MenuItem>
										))}
									</Select>
								</FormControl>
							)}
						/>
					)}
					{isAddNewItems === false && isAddExistingItems === true && (
						<div>
							<Typography variant="h5" sx={{ fontWeight: "600", mt: 2 }}>
								Selected Existing Items 👇🏽
							</Typography>
							{selectedItems.map((item, index) => (
								<div key={item.id} style={{ marginTop: "20px" }}>
									<Typography variant="subtitle2">{item.name}</Typography>
									{item.editMode ? (
										<>
											<TextField
												label="Vendor Price"
												fullWidth
												margin="normal"
												type="text"
												value={editedItemVendorPrice[item.id] || ""}
												onChange={(e) =>
													handleEditedItemChange(
														item.id,
														"vendorPrice",
														e.target.value
													)
												}
											/>
											<TextField
												label="Selling Price"
												fullWidth
												margin="normal"
												type="text"
												value={editedItemSellingPrice[item.id] || ""}
												onChange={(e) =>
													handleEditedItemChange(
														item.id,
														"sellingPrice",
														e.target.value
													)
												}
											/>
											<TextField
												label="Available Items"
												fullWidth
												margin="normal"
												type="text"
												value={editedItemAvailableItems[item.id] || ""}
												onChange={(e) =>
													handleEditedItemChange(
														item.id,
														"availableItems",
														e.target.value
													)
												}
											/>
											<Button
												variant="outlined"
												color="secondary"
												onClick={() => toggleEditMode(item.id)}
											>
												Save
											</Button>
										</>
									) : (
										<>
											{/* Display item details here */}
											<Button
												variant="outlined"
												color="secondary"
												onClick={() => toggleEditMode(item.id)}
											>
												Edit
											</Button>
										</>
									)}
								</div>
							))}
						</div>
					)}
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							mt: 4,
						}}
					>
						<Button
							variant="outlined"
							color="secondary"
							sx={{ height: "50px" }}
							// disabled={isUploadSuccess === false}
							fullWidth
							// onClick={(e) => {
							// 	e.preventDefault();
							// 	fileDrop.length === 0 && isUploadSuccess === false
							// 		? setIsUploadError("Please upload an image")
							// 		: fileDrop.length > 0 && isUploadSuccess === true
							// 		? handleSubmit(handleCreateCatalog)
							// 		: handleSubmit(handleCreateCatalog);
							// }}
							onClick={handleSubmit(handleCreateCatalog)}
						>
							{isCreatingCatalog === true && isCatalogCreateSuccess === false
								? "Creating..."
								: isCreatingCatalog === false && isCatalogCreateSuccess === true
								? "Catalog created 🎉"
								: "Create Catalog"}
						</Button>
					</Box>
				</form>
			</Box>
		</Modal>
	);
};

export default CreateCatalog;
