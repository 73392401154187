import Div from "@jumbo/shared/Div";
import { Avatar } from "@mui/material";
import React from "react";
import "../style/kyc.css";

const KycStatus = ({ user, existence }) => {
	console.log(user, "kyc");
	return (
		<div>
			{existence ? (
				<>
					{user?.firstName && user?.lastName ? (
						<Div className="user-info-div">
							<Avatar
								src={user?.imageURL}
								alt="user info"
								className="user-info-avatar"
							/>
							<div className="user-info">
								<div className="name-kyc">
									<div>
										<h3 className="h3_header">First Name</h3>
										<p className="paragraph">{user?.firstName}</p>
									</div>
								</div>
								<div className="name-kyc1">
									<div className="name-kyc2">
										<h3 className="h3_header">Last Name</h3>
										<p className="paragraph">{user?.lastName}</p>
									</div>
									<div className="name-kyc3">
										<h3 className="h3_header">Phone Number</h3>
										<p className="paragraph">{user?.msisdn}</p>
									</div>
								</div>
							</div>
							<div className="kyc-div">
								<h3 className="h3_header">Kyc Status</h3>
								{user?.hasAVerifiedId === false ? (
									<p className="paragraph" style={{ color: "red" }}>
										KYC Incomplete
									</p>
								) : (
									<p className="paragraph" style={{ color: "green" }}>
										KYC Complete
									</p>
								)}
							</div>
						</Div>
					) : (
						<Div
							className="user-info-div"
							sx={{
								display: "flex",
								flexDirection: "column",
								textAlign: "center",
							}}
						>
							<h2 style={{ color: "red" }}>
								Sorry this user isn't entirely onboarded on the app.
							</h2>
							<p>
								{" "}
								Please reach out to the user to facilitate and aid the process.
								Kindly note that the database only saves the user's additional details aside their phone number when
								they've completed the process of creating a pin.
							</p>
						</Div>
					)}
				</>
			) : (
				<Div
					className="user-info-div"
					sx={{ display: "flex", flexDirection: "column" }}
				>
					<h2 style={{ color: "red" }}>
						Sorry this phone number doesn't exist in our database as a user.
					</h2>
					<p> Please confirm you've typed in the correct details.</p>
				</Div>
			)}
		</div>
	);
};

export default KycStatus;
