import React, { useState } from "react";
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Box,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Avatar,
	IconButton,
	Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import Div from "@jumbo/shared/Div/Div";
import EditProduct from "../modals/editCatalogProduct";
import EditCatalog from "../modals/editCatalog";
import AddNewProductToCatalog from "../modals/addNewProductToCatalog";
import { DeleteOutline } from "@mui/icons-material";
import { AdminDealsAPI } from "app/routes/middleware";
import { STATUSCODES } from "app/utils/appHelpers";
import AddExistingProductToCatalog from "../modals/addExistingProductToCatalog";

const AccordionTable = ({
	data,
	setIsCatalogUpdateSuccess,
	setIsItemUpdateSuccess,
	setMessage,
	setSeverity,
	setOpenSnackbar,
	setIsNewItemCreateSuccess,
	setIsItemInACatalogDeleted,
	handleGetItems,
}) => {
	const [editOpen, setEditOpen] = useState(false);
	const [editedCatalog, setEditedCatalog] = useState(null);
	const [editProductOpen, setEditProductOpen] = useState(false);
	const [editedProduct, setEditedProduct] = useState(null);
	const [isOpenAddNewItems, setIsOpenAddNewItems] = useState(false);
	const [catalogId, setCatalogId] = useState("");
	const [isOpenAddExistingItems, setIsOpenAddExistingItems] = useState(false);
	const [isSaving, setIsSaving] = useState({
		value: false,
		item: "",
	});
	const [isDeleting, setIsDeleting] = useState({
		value: false,
		item: "",
	});

	const handleEditProduct = (event, product) => {
		event.stopPropagation(); // Prevent accordion from toggling
		setEditedProduct(product);
		setEditProductOpen(true);
	};

	const calculateRemainingDays = (startDate, endDate) => {
		const now = new Date();
		startDate = new Date(startDate);
		endDate = new Date(endDate);

		if (now < startDate) {
			const timeRemaining = startDate - now;
			const daysRemaining = Math.ceil(timeRemaining / (1000 * 60 * 60 * 24));
			return `${daysRemaining} days till start`;
		} else if (now < endDate) {
			const timeRemaining = endDate - now;
			const daysRemaining = Math.ceil(timeRemaining / (1000 * 60 * 60 * 24));
			return `${daysRemaining} days till end`;
		} else {
			return "Ended";
		}
	};

	const handleEditCatalog = (event, catalog) => {
		event.stopPropagation(); // Prevent accordion from toggling
		setEditedCatalog(catalog);
		setEditOpen(true);
	};

	const handleEditModalClose = () => {
		setEditedCatalog(null);
		setEditOpen(false);
	};

	const handleAddNewItemsToCatalog = (event, catalog) => {
		event.stopPropagation(); // Prevent accordion from toggling
		// console.log(catalog.id, "catalog_Id");
		setCatalogId(catalog.id);
		setIsOpenAddNewItems(true);
	};
	const handleAddExistingItemsToCatalog = (event, catalog) => {
		event.stopPropagation(); // Prevent accordion from toggling
		// console.log(catalog.id, "catalog_Id");
		setCatalogId(catalog.id);
		setIsOpenAddExistingItems(true);
	};

	const handleDeleteItemFromCatalog = async (event, item) => {
		console.log({ item });
		event.stopPropagation();
		setIsDeleting({
			value: true,
			item: item.id,
		});
		try {
			const request = await AdminDealsAPI.deleteItemFromCatalog({
				itemId: item.id,
				catalogId: item.catalog_id,
			});
			// console.log(request, "request");
			if (STATUSCODES.SUCCESS.includes(request.status)) {
				setIsDeleting({
					value: false,
					item: "",
				});
				setIsItemUpdateSuccess(true);
				// setIsItemInACatalogDeleted(true);
				setOpenSnackbar(true);
				setMessage(`Catalog updated successfully`);
				setSeverity("success");
			}
			setIsItemUpdateSuccess(false);
			// setIsItemInACatalogDeleted(false);
		} catch (error) {
			setIsDeleting({
				value: false,
				item: "",
			});
			// console.log(error, "error");
			if (STATUSCODES.CLIENT_ERROR.includes(error?.response?.status)) {
				setMessage(
					`Error updating catalog. Please make sure you've entered the right details or please reach out to tech support`
				);
				setSeverity("error");
				setOpenSnackbar(true);
			} else if (STATUSCODES.SERVER_ERROR.includes(error?.response?.status)) {
				setMessage(
					"Sorry this is from us, please try again later or reach out to tech support"
				);
				setSeverity("error");
				setOpenSnackbar(true);
			}
		}
	};

	const handleActivateCatalogItem = async (event, product) => {
		event.stopPropagation();
		setIsSaving({
			value: true,
			item: product.id,
		});

		// console.log({ product });
		try {
			const request = await AdminDealsAPI.updateItemInACatalog({
				itemId: product?.id,
				catalogId: product?.catalogId,
				payload: {
					status: "ACTIVE",
				},
			});
			// console.log(request, "request");
			if (STATUSCODES.SUCCESS.includes(request.status)) {
				setIsSaving({
					value: false,
					item: "",
				});
				setIsItemUpdateSuccess(true);
				setOpenSnackbar(true);
				setMessage(`Item activated successfully`);
				setSeverity("success");
			}
			setIsItemUpdateSuccess(false);
		} catch (error) {
			// console.log(error, "error");
			if (STATUSCODES.CLIENT_ERROR.includes(error?.response?.status)) {
				setMessage(
					`Error activating item. Please make sure you've entered the right details or please reach out to tech support`
				);
				setSeverity("error");
				setOpenSnackbar(true);
			} else if (STATUSCODES.SERVER_ERROR.includes(error?.response?.status)) {
				setMessage(
					"Sorry this is from us, please try again later or reach out to tech support"
				);
				setSeverity("error");
				setOpenSnackbar(true);
			}
		}
	};

	return (
		<div>
			<Div
				sx={{
					display: "flex",
					alignItems: "center",
					p: 2,
					width: "100%",
					justifyContent: "space-between",
					backgroundColor: "#62A933",
					borderTopLeftRadius: "10px",
					borderTopRightRadius: "10px",
				}}
			>
				<Typography
					width={95}
					variant="body2"
					sx={{
						color: "#ffffff",
					}}
				>
					Image
				</Typography>
				<Typography variant="body2" sx={{ width: "80px", color: "#ffffff" }}>
					Name
				</Typography>
				<Typography variant="body2" sx={{ width: "80px", color: "#ffffff" }}>
					Status
				</Typography>
				<Typography variant="body2" sx={{ width: "80px", color: "#ffffff" }}>
					MOV
				</Typography>
				<Typography variant="body2" sx={{ width: "80px", color: "#ffffff" }}>
					Days Left
				</Typography>
				<Typography variant="body2" sx={{ width: "98px", color: "#ffffff" }}>
					Order Count
				</Typography>
				<Typography
					variant="body2"
					sx={{
						color: "#ffffff",
					}}
				>
					Actions
				</Typography>
			</Div>
			{data.map((catalog) => (
				<Accordion
					key={catalog.id}
					sx={{
						backgroundColor: catalog.isClosed ? "#DDBDBD" : "", // Set background color based on isClosed
						opacity: catalog.isClosed ? "50%" : "",
					}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls={`panel-content-${catalog.id}`}
						id={`panel-header-${catalog.id}`}
					>
						<Box
							sx={{
								display: "flex",
								width: "100%",
								alignItems: "center",
								justifyContent: "space-between",
							}}
						>
							<Avatar
								variant="square"
								src={catalog?.image}
								alt="Avatar"
								sx={{ width: 100, height: 100, marginRight: "10px" }}
							/>
							<Typography
								variant="body2"
								style={{
									fontWeight: "bold",
									marginRight: "10px",
									width: "80px",
								}}
							>
								{catalog.name}
							</Typography>
							<Typography
								variant="body2"
								style={{ marginRight: "10px", width: "80px" }}
							>
								{catalog.status.toUpperCase()}
							</Typography>
							<Typography
								variant="body2"
								style={{ marginRight: "10px", width: "80px" }}
							>
								{catalog.minimumOrderValue}
							</Typography>
							<Typography
								variant="body2"
								style={{ marginRight: "10px", width: "80px" }}
							>
								{calculateRemainingDays(catalog.startDate, catalog.endDate)}
							</Typography>
							<Typography
								variant="body2"
								style={{ marginRight: "10px", width: "80px" }}
							>
								{catalog.orderCount} Orders
							</Typography>

							<IconButton
								onClick={(event) => handleEditCatalog(event, catalog)}
							>
								<EditIcon />
							</IconButton>
						</Box>
					</AccordionSummary>

					<AccordionDetails>
						<Typography
							variant="h6"
							color={"secondary"}
							style={{ fontWeight: 500 }}
						>
							Catalog Description
						</Typography>
						<Div
							variant="body2"
							style={{
								// marginRight: "10px",
								display: "flex",
								minHeight: "30px",
								backgroundColor: "#EEECEC",
								borderRadius: "5px",
							}}
						>
							<span style={{ padding: "10px" }}>{catalog.description}</span>
						</Div>

						<div
							style={{
								display: "flex",
								alignItems: "center",
								marginTop: "10px",
							}}
						>
							<Typography
								variant="h6"
								style={{ marginRight: "10px", fontWeight: 500 }}
							>
								Number of users who shared this catalog:
							</Typography>
							<Typography variant="h6">{catalog.numberOfShare}</Typography>
						</div>
						<Div
							sx={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								my: 1,
								pt: 2,
								borderTop: "1px dashed #BDBDBD",
							}}
						>
							<Typography
								variant="h4"
								style={{ marginTop: "10px", fontWeight: 600 }}
								color={"secondary"}
							>
								Catalog Products
							</Typography>
							<div>
								<Button
									variant="outlined"
									color="primary"
									type="submit"
									sx={{ fontSize: "12px", mr: 2 }}
									onClick={(event) =>
										handleAddExistingItemsToCatalog(event, catalog)
									}
								>
									Add Existing Products
								</Button>
								<Button
									variant="outlined"
									color="secondary"
									type="submit"
									sx={{ fontSize: "12px" }}
									onClick={(event) =>
										handleAddNewItemsToCatalog(event, catalog)
									}
								>
									Add New Products
								</Button>
							</div>
						</Div>
						<TableContainer component={Paper}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Image</TableCell>
										<TableCell>Name</TableCell>
										<TableCell>Status</TableCell>

										<TableCell>Selling Price</TableCell>
										<TableCell>Vendor Price</TableCell>
										<TableCell>Discount(%)</TableCell>
										<TableCell align="right">Actions</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{catalog.items.map((product) => (
										<TableRow
											key={product.id}
											sx={
												product.status === "DELETED"
													? {
															backgroundColor: "#DDBDBD",
													  }
													: { backgroundColor: "#ffffff" }
											}
										>
											<TableCell>
												<Avatar
													variant="square"
													src={product.item.image}
													alt="Avatar"
													sx={{ width: 50, height: 50 }}
												/>
											</TableCell>
											<TableCell>{product.item.name}</TableCell>
											<TableCell>{product?.status}</TableCell>

											<TableCell>
												{catalog.countryOfOrigin === "Nigeria"
													? `NGN ${product.sellingPrice}`
													: `GHS ${product.sellingPrice}`}
											</TableCell>
											<TableCell>
												{catalog.countryOfOrigin === "Nigeria"
													? `NGN ${product.vendorPrice}`
													: `GHS ${product.vendorPrice}`}
											</TableCell>
											<TableCell>
												{(
													((product.vendorPrice - product.sellingPrice) /
														product.vendorPrice) *
													100
												).toFixed()}
												%
											</TableCell>
											<TableCell align="right">
												{product.status === "DELETED" ? (
													<div
														style={{
															display: "inline-flex",
															alignItems: "center",
														}}
													>
														<Button
															variant="contained"
															color="secondary"
															onClick={(event) =>
																handleActivateCatalogItem(event, product)
															}
														>
															{product.id === isSaving.item && isSaving.value
																? "Trying..."
																: "Reactivate"}
														</Button>
													</div>
												) : (
													<>
														{product.id === isDeleting.item &&
														isDeleting.value === true ? (
															"Deleting"
														) : (
															<IconButton
																onClick={(event) => {
																	setIsDeleting({
																		value: true,
																		item: product.id,
																	});
																	handleDeleteItemFromCatalog(event, {
																		id: product?.id,
																		catalog_id: product?.catalogId,
																	});
																}}
															>
																<DeleteOutline />
															</IconButton>
														)}
														<IconButton
															onClick={(event) =>
																handleEditProduct(event, {
																	id: product.id,
																	name: product.item.name,
																	description: product.item.description,
																	image: product.item.image,
																	sellingPrice: product.sellingPrice,
																	vendorPrice: product.vendorPrice,
																	availableItems: product.availableItems,
																	catalogId: product.catalogId,
																})
															}
														>
															<EditIcon />
														</IconButton>
													</>
												)}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</AccordionDetails>
				</Accordion>
			))}
			{/* Edit Product Price Modal */}
			<EditProduct
				setEditProductOpen={setEditProductOpen}
				editProductOpen={editProductOpen}
				editedProduct={editedProduct}
				setIsItemUpdateSuccess={setIsItemUpdateSuccess}
				setMessage={setMessage}
				setSeverity={setSeverity}
				setOpenSnackbar={setOpenSnackbar}
			/>
			{/* Edit Catalog Modal */}
			<EditCatalog
				editOpen={editOpen}
				handleEditModalClose={handleEditModalClose}
				editedCatalog={editedCatalog}
				setIsCatalogUpdateSuccess={setIsCatalogUpdateSuccess}
				setMessage={setMessage}
				setSeverity={setSeverity}
				setOpenSnackbar={setOpenSnackbar}
			/>
			<AddExistingProductToCatalog
				setIsOpenAddExistingItems={setIsOpenAddExistingItems}
				isOpenAddExistingItems={isOpenAddExistingItems}
				catalogId={catalogId}
				setMessage={setMessage}
				setSeverity={setSeverity}
				setOpenSnackbar={setOpenSnackbar}
				setIsCatalogUpdateSuccess={setIsCatalogUpdateSuccess}
				handleGetItems={handleGetItems}
			/>
			{/* Add New Product To Catalog Modal */}
			<AddNewProductToCatalog
				setIsNewItemCreateSuccess={setIsNewItemCreateSuccess}
				setMessage={setMessage}
				setSeverity={setSeverity}
				setOpenSnackbar={setOpenSnackbar}
				isOpenAddNewItems={isOpenAddNewItems}
				setIsOpenAddNewItems={setIsOpenAddNewItems}
				catalogId={catalogId}
			/>
		</div>
	);
};

export default AccordionTable;
