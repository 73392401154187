import React from "react";
import "./index.css";

const CustomTab = ({ view, setView, children, tabColumns, setModalOpen }) => {
	return (
		<div>
			<div
				style={{
					display: "flex",
					// flexDirection: "column",
					justifyContent: "space-between",
					borderBottom: "1px solid #E0E0E0",
				}}
			>
				<div
					className="tab_scrollbar_hidden"
					style={{
						display: "flex",
						overflowX: "auto",
						whiteSpace: "nowrap",
						width: "100%",
					}}
				>
					{tabColumns.map((item) => (
						<button
							key={item.id}
							onClick={() => {
								setView(item.view);
							}}
							className="hover-action"
							style={
								view === item.view
									? {
											borderBottom: "2px solid #FFB600",
											color: "#000000",
											paddingBottom: "10px",
											marginRight: "10px",
											borderTop: "none",
											borderRight: "none",
											borderLeft: "none",
											backgroundColor: "white",
											fontSize: "15px",
											borderTopLeftRadius: "10px",
											borderTopRightRadius: "10px",
									  }
									: {
											color: "#717070",
											paddingBottom: "10px",
											marginRight: "10px",
											border: "none",
											fontSize: "15px",
											backgroundColor: "#D9D7D7",
											borderTopLeftRadius: "10px",
											borderTopRightRadius: "10px",
									  }
							}
						>
							{item.name}
						</button>
					))}
				</div>
				<div className="button-container">
					<button onClick={() => setModalOpen(true)} className="button">
						Create New Catalog
					</button>
				</div>
			</div>
			<div style={{ marginTop: "20px" }}>{children}</div>
		</div>
	);
};

export default CustomTab;
