import { Popover, Typography } from "@mui/material";
import React from 'react'

export const Popovers = ({open, anchorEl, handlePopoverClose, details}) => {
  return (
		<Popover
			id="mouse-over-popover"
			sx={{
				pointerEvents: "none",
			}}
			open={open}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "left",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "left",
			}}
			onClose={handlePopoverClose}
			disableRestoreFocus
		>
			<Typography
				variant="h6"
				sx={{ p: 2, width: "200px", letterSpacing: "0.1em" }}
			>
				{details}
			</Typography>
		</Popover>
	);
}
