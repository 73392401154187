import React from 'react';
import Div from "@jumbo/shared/Div";
import Link from "@mui/material/Link";
import {ASSET_IMAGES} from "../../utils/constants/paths";


const Logo = ({mini, mode, sx}) => {
    return (
			<Div sx={{ display: "inline-flex", ...sx }}>
				{/* <Link href={"/home"}>
					{!mini ? (
						<div>
							<img
								src={
									mode === "light"
										? `/images/logos/logo.svg`
										: `/images/logos/icon.png`
								}
								width={100}
								alt="Jumbo React"
							/>
						</div>
					) : (
						<div>
							<img
								src={
									mode === "light"
										? `/images/logos/icon.png`
										: `/images/logos/logo.svg`
								}
								width={100}
								alt="Jumbo React"
							/>
						</div>
					)}
				</Link> */}
				<div>Web Dashboard</div>
			</Div>
		);
};

Logo.defaultProps = {
    mode: "light"
};

export default Logo;
