import React from "react";

import routes from "./routes";
import { useJumboRoutes } from "@jumbo/hooks";
import { _paths_ } from "./utils/appHelpers";

const AppRoutes = () => {
    const appRoutes = useJumboRoutes([...routes.unsecured, ...routes.secured]);
    return (
        <React.Fragment>
            {
                appRoutes
            }
        </React.Fragment>
    );
};
export default AppRoutes;
