import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

export const CatalogImageUpload = ({
	setFileDrop,
	isUploadSuccess,
	title,
	handleDelete,
	setFiles,
	files,
}) => {
	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/jpg, image/jpeg, image/png",
		maxFiles: 1,
		maxSize: 1 * 1048576, //approximately 1mb
		onDrop: (acceptedFiles) => {
			// console.log(acceptedFiles, "acceptedFiles");
			setFiles((existingFiles) => [
				...existingFiles,
				...acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				),
			]);
			// setFileDrop( acceptedFiles[0] );
			const reader = new FileReader();
			reader.readAsDataURL(acceptedFiles[0]);
			reader.onloadend = () => {
				const base64 = reader.result;
				setFileDrop(base64);
			};
		},
	});

	useEffect(
		() => () => {
			// To avoid memory leaks
			files.forEach((file) => URL.revokeObjectURL(file.preview));
		},
		[files]
	);
	return (
		<div className="dropzone-main-wrapper">
			<div className="dropzone-wrapper drop1">
				<div className="" {...getRootProps({ className: "dropzone" })}>
					{files.length === 0 ? (
						<>
							<input {...getInputProps()} required />
							<div
								style={{
									display: "flex",
									alignItems: "center",
									flexDirection: "column",
								}}
							>
								<div style={{ textAlign: "center" }}>
									<p style={{ fontSize: "11px" }}>{title}</p>
									<p
										style={{
											marginTop: "5px",
											color: "grey",
											fontSize: "10px",
										}}
									>
										Drag n Drop
									</p>
									<div style={{ fontSize: "9px" }}>
										<em>png, jpg, jpegs</em>
										<p style={{ fontStyle: "italic", fontSize: "9px" }}>
											max size 1mb
										</p>
									</div>

									<p
										style={{
											marginTop: "3px",
											color: "grey",
											fontSize: "10px",
										}}
									>
										or
									</p>
								</div>
								<div>
									<p
										style={{
											backgroundColor: "#62A933",
											color: "white",
											fontWeight: "500",
											padding: "6px 20px",
											borderRadius: "20px",
											marginTop: "3px",
											cursor: "pointer",
											fontSize: "10px",
										}}
									>
										Click to select file
									</p>
								</div>
							</div>
						</>
					) : (
						files.map((file, key) => (
							<div
								key={key}
								style={{
									position: "relative",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									flexDirection: "column",
								}}
							>
								<img
									src={file.preview}
									alt="preview"
									height={120}
									width="100%"
									className="preview-image"
									onLoad={() => {
										URL.revokeObjectURL(file.preview);
									}}
								/>
								{isUploadSuccess ? (
									""
								) : (
									<button
										className="remove-btn"
										style={{
											position: "relative",
											bottom: "5px",
											right: "0px",
										}}
										onClick={(e) => {
											e.preventDefault();
											handleDelete(file);
										}}
									>
										Remove
									</button>
								)}
							</div>
						))
					)}
				</div>
			</div>
		</div>
	);
};

export const ItemImageUpload = ({
	setFileDrop,
	title,
	setFiles,
	handleDeleteItemImage,
	files,
}) => {
	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/jpg, image/jpeg, image/png",
		maxFiles: 1,
		maxSize: 1 * 1048576, //approximately 1mb
		onDrop: (acceptedFiles) => {
			// console.log(acceptedFiles, "acceptedFiles");
			setFiles((existingFiles) => [
				...existingFiles,
				...acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				),
			]);
			// setFileDrop( acceptedFiles[0] );
			const reader = new FileReader();
			reader.readAsDataURL(acceptedFiles[0]);
			reader.onloadend = () => {
				const base64 = reader.result;
				setFileDrop(base64);
			};
		},
	});

	useEffect(
		() => () => {
			// To avoid memory leaks
			files.forEach((file) => URL.revokeObjectURL(file.preview));
		},
		[files]
	);
	return (
		<div className="dropzone-main-wrapper">
			<div className="dropzone-wrapper drop1">
				<div className="" {...getRootProps({ className: "dropzone" })}>
					{files.length === 0 ? (
						<>
							<input {...getInputProps()} required />
							<div
								style={{
									display: "flex",
									alignItems: "center",
									flexDirection: "column",
								}}
							>
								<div style={{ textAlign: "center" }}>
									<p style={{ fontSize: "11px" }}>{title}</p>
									<p
										style={{
											marginTop: "5px",
											color: "grey",
											fontSize: "10px",
										}}
									>
										Drag n Drop
									</p>
									<div style={{ fontSize: "9px" }}>
										<em>png, jpg, jpegs</em>
										<p style={{ fontStyle: "italic", fontSize: "9px" }}>
											max size 1mb
										</p>
									</div>

									<p
										style={{
											marginTop: "3px",
											color: "grey",
											fontSize: "10px",
										}}
									>
										or
									</p>
								</div>
								<div>
									<p
										style={{
											backgroundColor: "#62A933",
											color: "white",
											fontWeight: "500",
											padding: "6px 20px",
											borderRadius: "20px",
											marginTop: "3px",
											cursor: "pointer",
											fontSize: "10px",
										}}
									>
										Click to select file
									</p>
								</div>
							</div>
						</>
					) : (
						files.map((file, key) => (
							<div
								key={key}
								style={{
									position: "relative",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									flexDirection: "column",
								}}
							>
								<img
									src={file.preview}
									alt="preview"
									height={120}
									width="100%"
									className="preview-image"
									onLoad={() => {
										URL.revokeObjectURL(file.preview);
									}}
								/>
								{/* {isUploadSuccess ? (
									""
								) : ( */}
								<button
									className="remove-btn"
									style={{
										position: "relative",
										bottom: "5px",
										right: "0px",
									}}
									onClick={(e) => {
										e.preventDefault();
										handleDeleteItemImage(file);
									}}
								>
									Remove
								</button>
								{/* )} */}
							</div>
						))
					)}
				</div>
			</div>
		</div>
	);
};
