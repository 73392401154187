import React, { useState } from "react";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { LoadingButton } from "@mui/lab";
import { Box, styled, IconButton, InputAdornment, Modal } from "@mui/material";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Input } from "./Input";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { ModalStyle } from "app/utils/constants/styles";
import CircularProgress from "@mui/material/CircularProgress";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import Div from "@jumbo/shared/Div";
import clsx from "clsx";
import PropTypes from "prop-types";

const customStyles = {
	overlay: {
		position: "fixed",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		// backgroundColor: "rgba(0,0,0,0.6)",
		opacity: 0,
		zIndex: 0,
		display: "none",
	},
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
	},
};

const BackdropUnstyled = React.forwardRef((props, ref) => {
	const { open, className, ...other } = props;
	return (
		<div
			className={clsx({ "MuiBackdrop-open": open }, className)}
			ref={ref}
			{...other}
		/>
	);
});

BackdropUnstyled.propTypes = {
	className: PropTypes.string.isRequired,
	open: PropTypes.bool,
};

const Modall = styled(ModalUnstyled)`
	position: fixed;
	z-index: 1300;
	right: 0;
	bottom: 0;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Backdrop = styled(BackdropUnstyled)`
	z-index: -1;
	position: fixed;
	right: 0;
	bottom: 0;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	-webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
	width: 400,
	bgcolor: theme.palette.mode === "dark" ? "#0A1929" : "white",
	border: "none",
	// padding: "16px 32px 24px 32px",
});

const signUpValidationSchema = yup.object({
	email: yup
		.string("*Enter your email")
		.email("*Enter a valid email")
		.required("*Email is required"),
	otp: yup.string("*Enter your otp").required("*OTP is required"),
});

const createPasswordValidationSchema = yup.object({
	password: yup
		.string()
		.min(16, "*Password must have at least 16 characters")
		.max(100, "*Password can't be longer than 100 characters")
		.label("*Password")
		.required("*Password required")
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
			"*Password must contain at least one uppercase, one lowercase, one number and one special case character"
		),
	confirmPassword: yup
		.string()
		.label("*Confirm password")
		.required()
		.oneOf([yup.ref("password"), null], "*Passwords must match"),
});

export const SignUpModal = ({
	open,
	handleClose,
	errorModalMessage,
	handleOtpConfirmation,
}) => {
	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={ModalStyle}>
				<h3 style={{ textAlign: "center", marginBottom: "30px" }}>
					Sign up with your Email and otp
				</h3>
				{errorModalMessage && (
					<p
						style={{
							textAlign: "center",
							color: "red",
							fontSize: "14px",
							marginBottom: "15px",
							marginTop: "-20px",
						}}
					>
						{"*" + errorModalMessage}
					</p>
				)}
				<Formik
					validateOnChange={true}
					initialValues={{
						email: "",
						otp: "",
					}}
					validationSchema={signUpValidationSchema}
					onSubmit={(data, { setSubmitting }) => {
						handleOtpConfirmation(data.email, data.otp, setSubmitting);
					}}
				>
					{({
						isSubmitting,
						handleChange,
						errors,
						values,
						handleBlur,
						touched,
					}) => (
						<Form noValidate autoComplete="off">
							<div style={{ display: "flex", flexDirection: "column" }}>
								<div style={{ display: "flex", flexDirection: "column" }}>
									<Input
										autoFocus
										name="email"
										type="email"
										placeholder="Enter your Email"
										value={values.email}
										onChange={handleChange}
										onBlur={handleBlur}
										style={{
											borderRadius: "10px",
											height: "50px",
											width: "100%",
											border: "1px solid #ccc",
											padding: "0 20px",
											fontFamily: "Inherit",
											marginBottom: "20px",
										}}
									/>
									{errors.email && touched.email && (
										<span
											style={{
												color: "red",
												fontSize: "12px",
												marginBottom: "15px",
												marginTop: "-10px",
											}}
										>
											{errors.email}
										</span>
									)}
								</div>
								<div style={{ display: "flex", flexDirection: "column" }}>
									<Input
										name="otp"
										type="text"
										placeholder="Enter your otp"
										value={values.otp}
										onChange={handleChange}
										onBlur={handleBlur}
										style={{
											borderRadius: "10px",
											height: "50px",
											width: "100%",
											border: "1px solid #ccc",
											padding: "0 20px",
											fontFamily: "Inherit",
											marginBottom: "10px",
										}}
									/>
									{errors.otp && touched.otp && (
										<span
											style={{
												color: "red",
												fontSize: "12px",
												marginBottom: "15px",
											}}
										>
											{errors.otp}
										</span>
									)}
								</div>
							</div>

							<LoadingButton
								fullWidth
								type="submit"
								variant="contained"
								size="large"
								color="secondary"
								sx={{ my: 3, textTransform: "capitalize" }}
								loading={isSubmitting}
							>
								{/* {isSubmitting ? isSubmitting : "Continue"} */}
								Continue
							</LoadingButton>
						</Form>
					)}
				</Formik>
			</Box>
		</Modal>
	);
};

export const CreatePasswordModal = ({
	openCreatePasswordModal,
	handleCreatePasswordModalClose,
	errorModalMessage,
	handleCreatePassword,
	email,
	successMessage,
}) => {
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleShowConfirmPassword = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};
	return (
		<Modal
			open={openCreatePasswordModal}
			onClose={handleCreatePasswordModalClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={ModalStyle}>
				<h3 style={{ textAlign: "center", marginBottom: "30px" }}>
					Create New Password
				</h3>
				{errorModalMessage && (
					<p
						style={{
							textAlign: "center",
							color: "red",
							fontSize: "14px",
							marginBottom: "15px",
							marginTop: "-20px",
						}}
					>
						{"*" + errorModalMessage}
					</p>
				)}
				{successMessage && (
					<p
						style={{
							textAlign: "center",
							color: "green",
							fontSize: "14px",
							marginBottom: "15px",
							marginTop: "-20px",
						}}
					>
						{"*" + successMessage + "🚀"}
					</p>
				)}

				<Formik
					validateOnChange={true}
					initialValues={{
						email: email,
						password: "",
						confirmPassword: "",
					}}
					validationSchema={createPasswordValidationSchema}
					onSubmit={(data, { setSubmitting }) => {
						handleCreatePassword(
							data.email,
							data.password,
							data.confirmPassword,
							setSubmitting
						);
					}}
				>
					{({ isSubmitting, handleChange, values, handleBlur }) => (
						<Form noValidate autoComplete="off">
							<div style={{ display: "flex", flexDirection: "column" }}>
								<div style={{ display: "flex", flexDirection: "column" }}>
									<Input
										name="email"
										type="email"
										disabled
										placeholder="Enter your Email"
										value={values.email}
										style={{
											borderRadius: "10px",
											height: "50px",
											width: "100%",
											border: "1px solid #ccc",
											padding: "0 20px",
											fontFamily: "Inherit",
											marginBottom: "20px",
										}}
									/>
								</div>
								<div style={{ display: "flex", flexDirection: "column" }}>
									<JumboTextField
										fullWidth
										autoFocus
										name="password"
										placeholder="Enter your Password"
										value={values.password}
										onChange={handleChange}
										onBlur={handleBlur}
										type={`${showPassword ? "text" : "password"}`}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton onClick={handleShowPassword}>
														{!showPassword ? (
															<VisibilityOffIcon />
														) : (
															<RemoveRedEyeIcon />
														)}
													</IconButton>
												</InputAdornment>
											),
										}}
										style={{
											marginBottom: "20px",
										}}
									/>
								</div>
								<div style={{ display: "flex", flexDirection: "column" }}>
									<JumboTextField
										fullWidth
										name="confirmPassword"
										placeholder="Confirm Password"
										value={values.confirmPassword}
										onChange={handleChange}
										onBlur={handleBlur}
										type={`${showConfirmPassword ? "text" : "password"}`}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton onClick={handleShowConfirmPassword}>
														{!showConfirmPassword ? (
															<VisibilityOffIcon />
														) : (
															<RemoveRedEyeIcon />
														)}
													</IconButton>
												</InputAdornment>
											),
										}}
										style={{
											marginBottom: "20px",
										}}
									/>
								</div>
							</div>

							<LoadingButton
								fullWidth
								type="submit"
								variant="contained"
								size="large"
								color="secondary"
								sx={{ mt: 2, mb: 3, textTransform: "capitalize" }}
								loading={isSubmitting}
							>
								{/* {isSubmitting ? isSubmitting : "Continue"} */}
								Proceed
							</LoadingButton>
						</Form>
					)}
				</Formik>
			</Box>
		</Modal>
	);
};

export const UpdatePassword = ({
	openCreatePasswordModal,
	handleCreatePasswordModalClose,
	errorModalMessage,
	handleCreatePassword,
	email,
}) => {
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleShowConfirmPassword = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};
	return (
		<Modal
			open={openCreatePasswordModal}
			onClose={handleCreatePasswordModalClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={ModalStyle}>
				<h3 style={{ textAlign: "center", marginBottom: "30px" }}>
					Create New Password
				</h3>
				{errorModalMessage && (
					<p
						style={{
							textAlign: "center",
							color: "red",
							fontSize: "14px",
							marginBottom: "15px",
							marginTop: "-20px",
						}}
					>
						{"*" + errorModalMessage}
					</p>
				)}
				<Formik
					validateOnChange={true}
					initialValues={{
						email: "",
						oldPassword: "",
						password: "",
						confirmPassword: "",
					}}
					validationSchema={createPasswordValidationSchema}
					onSubmit={(data, { setSubmitting }) => {
						handleCreatePassword(
							data.email,
							data.password,
							data.confirmPassword,
							setSubmitting
						);
					}}
				>
					{({ isSubmitting, handleChange, values, handleBlur }) => (
						<Form noValidate autoComplete="off">
							<div style={{ display: "flex", flexDirection: "column" }}>
								<div style={{ display: "flex", flexDirection: "column" }}>
									<Input
										name="email"
										type="email"
										disabled
										placeholder="Enter your Email"
										value={values.email}
										style={{
											borderRadius: "10px",
											height: "50px",
											width: "100%",
											border: "1px solid #ccc",
											padding: "0 20px",
											fontFamily: "Inherit",
											marginBottom: "20px",
										}}
									/>
								</div>
								<div style={{ display: "flex", flexDirection: "column" }}>
									<JumboTextField
										fullWidth
										autoFocus
										name="password"
										placeholder="Enter your Password"
										value={values.password}
										onChange={handleChange}
										onBlur={handleBlur}
										type={`${showPassword ? "text" : "password"}`}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton onClick={handleShowPassword}>
														{!showPassword ? (
															<VisibilityOffIcon />
														) : (
															<RemoveRedEyeIcon />
														)}
													</IconButton>
												</InputAdornment>
											),
										}}
										style={{
											marginBottom: "20px",
										}}
									/>
								</div>
								<div style={{ display: "flex", flexDirection: "column" }}>
									<JumboTextField
										fullWidth
										name="confirmPassword"
										placeholder="Confirm Password"
										value={values.confirmPassword}
										onChange={handleChange}
										onBlur={handleBlur}
										type={`${showConfirmPassword ? "text" : "password"}`}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton onClick={handleShowConfirmPassword}>
														{!showConfirmPassword ? (
															<VisibilityOffIcon />
														) : (
															<RemoveRedEyeIcon />
														)}
													</IconButton>
												</InputAdornment>
											),
										}}
										style={{
											marginBottom: "20px",
										}}
									/>
								</div>
							</div>

							<LoadingButton
								fullWidth
								type="submit"
								variant="contained"
								size="large"
								color="secondary"
								sx={{ mt: 2, mb: 3, textTransform: "capitalize" }}
								loading={isSubmitting}
							>
								{/* {isSubmitting ? isSubmitting : "Continue"} */}
								Continue
							</LoadingButton>
						</Form>
					)}
				</Formik>
			</Box>
		</Modal>
	);
};

export const LoadingModal = ({ loading }) => {
	return (
		<Modall open={loading} slots={{ backdrop: Backdrop }}>
			{/* <Div
				// sx={{
				// 	width: "50vw",
				// 	height: "50vh",
				// 	display: "flex",
				// 	justifyContent: "center",
				// 	alignItems: "center",
				// 	backgroundColor: "rgba(0,0,0,0.6)",
				// 	zIndex: 20,
				// }}
				sx={{ style }}
			>
		</Div> */}
			<>
				<CircularProgress sx={{ border: "none", outline: "none" }} />
				<p style={{ color: "white" }}>Loading...</p>
			</>
		</Modall>
	);
};
