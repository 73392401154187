import { Button } from "@mui/material";
import { moneyFormat } from "app/components/MoneyFormat";
import {
	TransactionTypes,
} from "app/components/Statuses";
import { userDetailsFormat } from "app/components/UserDetailsTooltip";

export const columns = [
	{ field: "id", headerName: "ID", width: 70, hide: true },
	{
		field: "sender",
		headerName: "Sender",
		width: 200,
		renderCell: (params) => userDetailsFormat(params, "Sender"),
	},
	{
		field: "receiver",
		headerName: "Receiver",
		width: 200,
		renderCell: (params) => userDetailsFormat(params, "Receiver"),
	},
	{
		field: "amount",
		headerName: "Amount",
		width: 160,
		renderCell: (params) => {
			const currency = params?.row?.currency ? params?.row?.currency : "GHS";
			const amount = params?.row?.amount ? Number(params?.row?.amount) : null;
			return (
				<span style={{ fontSize: 12, fontWeight: "600" }}>
					{amount ? (
						<>
							{currency}
							{moneyFormat(amount, "en", 2)}
						</>
					) : (
						`No amount detected`
					)}
				</span>
			);
		},
	},
	{
		field: "transactionType",
		headerName: "Type",
		width: 150,
		renderCell: (params) => {
			const color = TransactionTypes?.[params?.value]?.color || "secondary";
			if (params?.value) {
				return (
					<>
						<Button variant="outlined" color={color} style={{ fontSize: 11 }}>
							{params?.value}
						</Button>
					</>
				);
			}

			return (
				<Button variant="outlined" color={"error"} style={{ fontSize: 11 }}>
					No type found
				</Button>
			);
		},
	},
	{
		field: "status",
		headerName: "Status",
		width: 150,
		renderCell: (params) => {
			// console.log(params, "params");
			return params.value === null || "" ? (
				<span style={{ fontSize: 12, fontWeight: "400" }}>
					No status detected
				</span>
			) : (
				<span style={{ fontSize: 12, fontWeight: "400" }}>{params.value}</span>
			);
		},
	},
	{
		field: "club",
		headerName: "Club",
		width: 180,
		renderCell: (params) => {
			if (params?.value) {
				const _name = params?.value?.name;
				return (
					<>
						<Button
							variant="outlined"
							color={"success"}
							style={{ fontSize: 11 }}
						>
							{_name}
						</Button>
					</>
				);
			}

			return (
				<Button variant="outlined" color={"warning"} style={{ fontSize: 11 }}>
					No club
				</Button>
			);
		},
	},
	{
		field: "createdAt",
		headerName: "Date Created",
		width: 230,
		type: "dateTime",
		renderCell: (params) => {
			const date = params?.row?.createdAt
				? new Date(params?.row?.createdAt).toUTCString()
				: "No date detected";
			return <span style={{ fontSize: 12, fontWeight: "500" }}> {date} </span>;
		},
	},
	{
		field: "updatedAt",
		headerName: "Date Updated",
		width: 230,
		type: "dateTime",
		renderCell: (params) => {
			const date = params?.row?.updatedAt
				? new Date(params?.row?.updatedAt).toUTCString()
				: "No date detected";
			return <span style={{ fontSize: 12, fontWeight: "500" }}> {date} </span>;
		},
	},
];
