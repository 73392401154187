import { Avatar, Button, FormControl, MenuItem, Select } from "@mui/material";
// import { CLUB_STATUSES, statusPatchKeys } from "app/components/Statuses";
// import { ClubAPI } from "app/routes/middleware";

const currencyFormatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "GHS",
});

const ghsPrice = {
	type: "number",
	width: 130,
	valueFormatter: ({ value }) => currencyFormatter.format(null),
	cellClassName: "font-tabular-nums",
};

// const handleChange = async (event, params) => {
// 	const state = statusPatchKeys["Club " + event.target.value?.trim()] || null;
// 	const userId = params.row.id;

// 	if (state && userId) {
// 		try {
// 			await ClubAPI.patchClubStatus(userId, state);
// console.log(request, "status");
// 		} catch (error) {
// 			console.log(error);
// 		}
// 	}
// };

export const Columns = [
	{
		field: "id",
		headerName: "User ID",
		width: 70,
		hide: true,
		sortable: false,
	},
	{
		field: "avatar",
		headerName: "Avatar",
		width: 80,
		renderCell: (params) => <Avatar src={params?.value || ""} alt="Avatar" />,
	},
	{
		field: "name",
		headerName: "Club Name",
		width: 180,
	},
	{
		field: "isArchived",
		headerName: "Archived?",
		width: 200,
	},
	{
		field: "clubType",
		headerName: "Club Type",
		width: 100,
		singleSelect: true,
		selectOptions: ["Personal", "Business"],
		renderCell: (params) => <span>{!params.value ? "N/A" : params.value}</span>,
	},
	{
		field: "clubLeader",
		headerName: "Club Leader",
		width: 180,
		renderCell: (params) => {
			if (
				params?.value?.firstName === undefined &&
				params?.value?.lastName === undefined
			) {
				return (
					<>
						<Button
							variant="outlined"
							color="error"
							style={{ fontSize: 11, color: "#D32802" }}
						>
							No Club Leader
						</Button>
					</>
				);
			} else if (
				params?.value?.firstName !== undefined &&
				params?.value?.lastName !== undefined
			) {
				const name = params?.value.firstName + " " + params?.value.lastName;
				return (
					<>
						<Button variant="outlined" style={{ fontSize: 11 }}>
							{name}
						</Button>
					</>
				);
			}
		},
	},
	{
		field: "approvers",
		headerName: "Approvers?",
		width: 140,
		// renderCell: (params) => {
		// 	console.log(params, "approvers");
		// 	if (!params?.value) {
		// 		return (
		// 			<>
		// 				<Button
		// 					variant="outlined"
		// 					color="error"
		// 					style={{ fontSize: 11, color: "#D32802" }}
		// 				>
		// 					No Approvers
		// 				</Button>
		// 			</>
		// 		);
		// 	} else if (params?.value) {
		// 		return (
		// 			<>
		// 				<Button variant="outlined" style={{ fontSize: 11 }}>
		// 					{params?.value.approvers.length} Approvers
		// 				</Button>
		// 			</>
		// 		);
		// 	}
		// },
	},
	{
		field: "clubMembers",
		headerName: "Club Members",
		editable: true,
		width: 150,
	},
	{
		field: "clubStatus",
		headerName: "Club Status",
		editable: true,
		width: 100,
		renderCell: (params) => {
			// console.log(params, "params");
			return params.value === null || "" ? (
				<span style={{ fontSize: 12, fontWeight: "400" }}>
					No status detected
				</span>
			) : (
				<span style={{ fontSize: 12, fontWeight: "400" }}>{params.value}</span>
			);
		},
	},
	{
		field: "mode",
		headerName: "Club Tiers",
		editable: true,
		width: 140,
	},
	// {
	// 	field: "messagessent",
	// 	headerName: "Messages Sent",
	// 	editable: true,
	// 	width: 140,
	// 	renderCell: (params) => <span>{!params.value ? "N/A" : params.value}</span>,
	// },
	{
		field: "lastTransaction",
		headerName: "Last Transaction",
		editable: true,
		width: 140,
		renderCell: (params) => {
			// console.log(params, "last transaction");
			return params?.value.length > 0
				? params?.value[0]?.currency + " " + params?.value[0]?.amount
				: "N/A";
		},
	},
	// {
	// 	field: "lastTransactionAmount",
	// 	headerName: "(₵) Last Transaction Amount",
	// 	editable: true,
	// 	width: 140,
	// 	type: "number",
	// 	...ghsPrice,
	// 	align: "left",
	// 	renderCell: (params) => <span>{!params.value ? "N/A" : params.value}</span>,
	// },
	// {
	// 	field: "lastTransactionType",
	// 	headerName: "Last Transaction Type",
	// 	editable: true,
	// 	width: 140,
	// 	singleSelect: true,
	// 	selectOptions: ["Deposit", "Withdrawal"],
	// 	renderCell: (params) => <span>{!params.value ? "N/A" : params.value}</span>,
	// },
	// {
	// 	field: "lastTransactionStatus",
	// 	headerName: "Last Transaction Status",
	// 	editable: true,
	// 	width: 140,
	// 	singleSelect: true,
	// 	selectOptions: ["Success", "Pending", "Failed"],
	// 	renderCell: (params) => <span>{!params.value ? "N/A" : params.value}</span>,
	// },
	// {
	// 	field: "totalHeldInClubWallet",
	// 	headerName: "Total (₵) in Club Wallet",
	// 	editable: true,
	// 	width: 140,
	// 	type: "number",
	// 	...ghsPrice,
	// 	align: "left",
	// },
	// {
	// 	field: "lastWalletFundingDate",
	// 	headerName: "Last Wallet Funding",
	// 	editable: true,
	// 	width: 120,
	// 	renderCell: (params) => <span>{!params.value ? "N/A" : params.value}</span>,
	// 	type: "dateTime",
	// 	valueFormatter: ({ value }) => value && new Date(value).toLocaleString(),
	// },
	// {
	// 	field: "lastWalletFundingAmount",
	// 	headerName: "Last (₵) Wallet Funding Amount",
	// 	editable: true,
	// 	width: 140,
	// 	type: "number",
	// 	...ghsPrice,
	// 	align: "left",
	// 	renderCell: (params) => <span>{!params.value ? "N/A" : params.value}</span>,
	// },
	// {
	// 	field: "lastWalletFundingStatus",
	// 	headerName: "Last Wallet Funding Status",
	// 	editable: true,
	// 	width: 140,
	// 	singleSelect: true,
	// 	selectOptions: ["Success", "Pending", "Failed"],
	// 	renderCell: (params) => <span>{!params.value ? "N/A" : params.value}</span>,
	// },
	// {
	// 	field: "whoFundedLast",
	// 	headerName: "Who Funded Last?",
	// 	editable: true,
	// 	width: 140,
	// 	renderCell: (params) => <span>{!params.value ? "N/A" : params.value}</span>,
	// },
	// {
	// 	field: "warnings",
	// 	headerName: "Warnings",
	// 	editable: true,
	// 	width: 100,
	// 	renderCell: (params) => <span>{!params.value ? "N/A" : params.value}</span>,
	// },
	{
		field: "createdAt",
		headerName: "Date Created",
		width: 200,
		type: "dateTime",
		valueFormatter: ({ value }) => value && new Date(value).toLocaleString(),
	},
	{
		field: "updatedAt",
		headerName: "Last Activity",
		type: "dateTime",
		width: 200,
		valueFormatter: ({ value }) => value && new Date(value).toLocaleString(),
	},
];
