import React from "react";
import Table from "./data/table";
import { Columns } from "./data/columns";
import { ClubAPI } from "app/routes/middleware";
import { Helmet } from "react-helmet-async";
import { Alert, Snackbar } from "@mui/material";

const Clubs = () => {
	// const [loading, setLoading] = React.useState(true);
	const [rowsData, setRowsData] = React.useState([]);
	const [rowModesModel, setRowModesModel] = React.useState({});
	const [numberOfItems, setNumberOfItems] = React.useState(10);
	const [open, setOpen] = React.useState(false);
	const [message, setMessage] = React.useState("");
	const [severity, setSeverity] = React.useState("success");

	React.useEffect(() => {
		handleClubsLoad();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleClubsLoad = async () => {
		try {
			const request = await ClubAPI.get();
			const response = request.data.data.result;
			// console.log(request.data.data.result, "clubs");
			if (response && response.length) {
				setRowsData(response);
			}
		} catch (error) {
			// setLoading(false);
			if (error?.response?.status === 401) {
				setMessage(
					"Sorry your sign in token has expired, please sign in again to continue using Spark Admin 🤭"
				);
				setSeverity("error");
				setOpen(true);
				localStorage.clear("@spark-session");
				window.location.replace("/sign-in");
			} else if (error?.response?.status === 210) {
				setMessage("Please check your internet connection 😅");
				setSeverity("error");
				setOpen(true);
				localStorage.clear("@spark-session");
				window.location.replace("/sign-in");
			} else {
				setMessage(
					"Sorry, there was an error loading the roles, please check your internet connection, refresh or sign out and sign in again 😅"
				);
				setSeverity("error");
				setOpen(true);
			}
		}
	};

	return (
		<div>
			<Helmet>
				<title>Clubs - Spark Dashboard</title>
			</Helmet>
			<Snackbar
				open={open}
				autoHideDuration={2000}
				onClose={() => setOpen(false)}
			>
				<Alert
					onClose={() => setOpen(false)}
					severity={severity}
					sx={{ width: "60%" }}
				>
					{message}
				</Alert>
			</Snackbar>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					marginTop: "-30px",
				}}
			>
				<h2>Clubs</h2>
			</div>
			<Table
				rows={rowsData}
				setRows={setRowsData}
				columns={Columns}
				rowModesModel={rowModesModel}
				setRowModesModel={setRowModesModel}
				pageSize={numberOfItems}
				pagination={true}
				disableSelectionOnClick={true}
				rowCount={rowsData?.length ? rowsData?.length : 0}
				onPageSizeChange={(pageSize) => {
					setNumberOfItems(pageSize);
				}}
			/>
		</div>
	);
};

export default Clubs;
