import React from "react";
import { Helmet } from "react-helmet-async";
import OrdersTable from "./data/table";
import { AdminDealsAPI } from "app/routes/middleware";
import { Alert, Snackbar } from "@mui/material";
import { STATUSCODES } from "app/utils/appHelpers";

const Orders = () => {
	const [isLoading, setIsLoading] = React.useState(false);
	const [fetchedOrders, setFetchedOrders] = React.useState([]);
	const [isOrderUpdateSuccess, setIsOrderUpdateSuccess] = React.useState(false);
	const [isOrderLoadSuccess, setIsOrderLoadSuccess] = React.useState(false);
	const [message, setMessage] = React.useState("");
	const [severity, setSeverity] = React.useState("success");
	const [openSnackbar, setOpenSnackbar] = React.useState(false);

	React.useEffect(() => {
		handleGetOrders();
		if (isOrderUpdateSuccess === true || isOrderLoadSuccess === true) {
			handleGetOrders();
		}
	}, [isOrderLoadSuccess, isOrderUpdateSuccess]);

	const handleGetOrders = async () => {
		setFetchedOrders([]);
		try {
			const request = await AdminDealsAPI.getAllOrders();
			if (STATUSCODES.SUCCESS.includes(request.status)) {
				const response = request.data.data;
				setIsOrderLoadSuccess(true);
				setFetchedOrders(response);
				setIsLoading(false);
				setMessage("Orders Loaded 🎉");
				setSeverity("success");
				setOpenSnackbar(true);
				// console.log("Order list:", response);
			}
		} catch (error) {
			// console.log(error);
			if (STATUSCODES.CLIENT_ERROR.includes(error?.response?.status)) {
				setMessage(`Error loading order. Kindly reach out to tech support`);
				setSeverity("error");
				setOpenSnackbar(true);
			} else if (STATUSCODES.SERVER_ERROR.includes(error?.response?.status)) {
				setMessage(
					"Sorry this is from us, please try again later or reach out to tech support"
				);
				setSeverity("error");
				setOpenSnackbar(true);
			}
		}
	};
	return (
		<div>
			<Helmet>
				<title>Deals Order List - Spark Dashboard</title>
			</Helmet>
			<Snackbar
				open={openSnackbar}
				autoHideDuration={3000}
				onClose={() => setOpenSnackbar(false)}
			>
				<Alert
					onClose={() => setOpenSnackbar(false)}
					severity={severity}
					sx={{ width: "100%" }}
				>
					{message}
				</Alert>
			</Snackbar>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<h2>Deals Order List</h2>
			</div>
			<OrdersTable
				fetchedOrders={fetchedOrders}
				setIsOrderUpdateSuccess={setIsOrderUpdateSuccess}
				setMessage={setMessage}
				setSeverity={setSeverity}
				setOpenSnackbar={setOpenSnackbar}
			/>
		</div>
	);
};

export default Orders;
