import React from "react";
import Avatar from "@mui/material/Avatar";
import { authUser } from "./fake-db";
import {
	ListItemIcon,
	ListItemText,
	ThemeProvider,
	Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RepeatOutlinedIcon from "@mui/icons-material/RepeatOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import { useJumboTheme } from "@jumbo/hooks";
import { contextSignOut, useAuthDispatch } from "app/providers/AuthContext";
import { storage, _paths_ } from "app/utils/appHelpers";

const AuthUserDropdown = () => {
	// const mutation = useAuthSignOut(auth);
	const dispatch = useAuthDispatch();
	const navigate = useNavigate();
	const { theme } = useJumboTheme();
	const localStorage = storage.local.get("@spark-session");

	const onLogout = async () => {
		// localStorage.clear();
		// window.location.reload();
		await contextSignOut(dispatch, navigate, _paths_.SIGN_IN);
	};

	return (
		<ThemeProvider theme={theme}>
			<JumboDdPopover
				triggerButton={
					<Avatar
						src={localStorage?.user?.imageURL || ""}
						sizes={"small"}
						sx={{ boxShadow: 25, cursor: "pointer" }}
					/>
				}
			>
				<Div
					sx={{
						display: "flex",
						alignItems: "center",
						flexDirection: "column",
						width: 250,
						p: (theme) => theme.spacing(2.5),
					}}
				>
					<Avatar
						src={localStorage?.user?.imageURL || ""}
						alt={localStorage?.user?.firstName + " " + localStorage?.user?.lastName}
						sx={{ width: 60, height: 60, mb: 2 }}
					/>
					<Typography variant={"h5"}>
						{localStorage?.user?.firstName + " " + localStorage?.user?.lastName}
					</Typography>
					<Typography variant={"body1"} color="text.secondary">
						{localStorage?.user?.email}
					</Typography>
				</Div>
				<Divider />
				<nav
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<List
						disablePadding
						sx={{
							pb: 1,
						}}
					>
						{/* <ListItemButton>
							<ListItemIcon sx={{ minWidth: 36 }}>
								<PersonOutlineIcon />
							</ListItemIcon>
							<ListItemText primary="Profile" sx={{ my: 0 }} />
						</ListItemButton>
						<ListItemButton>
							<ListItemIcon sx={{ minWidth: 36 }}>
								<EditOutlinedIcon />
							</ListItemIcon>
							<ListItemText primary="Edit Profile" sx={{ my: 0 }} />
						</ListItemButton> */}
						{/* <ListItemButton>
							<ListItemIcon sx={{ minWidth: 36 }}>
								<RepeatOutlinedIcon />
							</ListItemIcon>
							<ListItemText
								onClick={() => navigate("/samples/content-layout")}
								primary="Switch User"
								sx={{ my: 0 }}
							/>
						</ListItemButton> */}
						<ListItemButton onClick={onLogout}>
							<ListItemIcon sx={{ minWidth: 36 }}>
								<LogoutIcon />
							</ListItemIcon>
							<ListItemText primary="Logout" sx={{ my: 0 }} />
						</ListItemButton>
					</List>
				</nav>
			</JumboDdPopover>
		</ThemeProvider>
	);
};

export default AuthUserDropdown;
