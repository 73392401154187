import { LoadingButton } from "@mui/lab";
import { UploadKycAPI } from "app/routes/middleware";
import { STATUSCODES } from "app/utils/appHelpers";
import React from "react";

const ManualOverride = ({ user, setState, loading }) => {
	// console.log(user, "user");

	const handleOverrideAction = async (e) => {
		e.preventDefault();
		try {
			setState((prevState) => {
				return {
					...prevState,
					isLoading: true,
				};
			});
			const request = await UploadKycAPI.overrideKYC(user.id);
			setState((prevState) => {
				return {
					...prevState,
					isLoading: false,
				};
			});
			const response = request.data;
			if (STATUSCODES.SUCCESS.includes(response.status)) {
				setState((prevState) => {
					return {
						...prevState,
						isIdVerified: response?.data?.hasAVerifiedId,
					};
				});
			}
		} catch (error) {
			setState((prevState) => {
				return {
					...prevState,
          isLoading: false,
          isError: true,
					errorMessage:
						error.response.data.message ||
						"Sorry an error occurred. Please try again",
				};
			});
		}
		// console.log(response, "response");
	};
	return (
		<div>
			<LoadingButton
				type="submit"
				variant="outlined"
				size="large"
				color="secondary"
				sx={{  textTransform: "capitalize", borderRadius: "30px", fontSize: "14px" }}
        onClick={ handleOverrideAction }
        loading={loading}
			>
				👉🏽 Manual Override 👈🏽
			</LoadingButton>
		</div>
	);
};

export default ManualOverride;
