import { Button } from "@mui/material";

export const columns = [
	{
		field: "id",
		headerName: "Id",
		width: 180,
		hide: true,
	},
	{
		field: "activity",
		headerName: "Activity",
		width: 180,
		renderCell: (params) =>
			!params?.value ? (
				<Button
					variant="outlined"
					color="error"
					style={{ fontSize: 11, color: "#D32802" }}
				>
					Unidentified Activity
				</Button>
			) : (
				<Button variant="outlined" style={{ fontSize: 11, color: "#51b848" }}>
					{params?.value}
				</Button>
			),
	},
	{
		field: "level",
		headerName: "Level",
		width: 180,
	},
	{
		field: "currency",
		headerName: " Currency",
		width: 180,
	},
	{
		field: "transactionVolume",
		headerName: "Transaction Volume",
		width: 180,
		valueFormatter: (params) => {
			return params.value.toLocaleString();
		},
	},
	{
		field: "dailyTransactionVolume",
		headerName: "Daily Transaction Volume",
		width: 180,
		valueFormatter: (params) => {
			return params.value.toLocaleString();
		},
	},
	{
		field: "weeklyTransactionVolume",
		headerName: "Weekly Transaction Volume",
		width: 180,
		valueFormatter: (params) => {
			return params.value.toLocaleString();
		},
	},
	{
		field: "monthlyTransactionVolume",
		headerName: "Monthly Transaction Volume",
		width: 180,
		valueFormatter: (params) => {
			return params.value.toLocaleString();
		},
	},
	{
		field: "yearlyTransactionVolume",
		headerName: "Yearly Transaction Volume",
		width: 180,
		valueFormatter: (params) => {
			return params.value.toLocaleString();
		},
	},
	{
		field: "dailyTransactionCount",
		headerName: "Daily Transaction Count",
		width: 180,
	},
	{
		field: "weeklyTransactionCount",
		headerName: "Weekly Transaction Count",
		width: 180,
	},
	{
		field: "monthlyTransactionCount",
		headerName: "Monthly Transaction Count",
		width: 180,
	},
	{
		field: "yearlyTransactionCount",
		headerName: "Yearly Transaction Count",
		width: 180,
	},
	{
		field: "createdAt",
		headerName: "Created At",
		type: "dateTime",
		width: 200,
		valueFormatter: ({ value }) =>
			value ? value && new Date(value).toLocaleString() : "N/A",
	},
	{
		field: "DeletedAt",
		headerName: "Deleted At",
		type: "dateTime",
		width: 200,
		valueFormatter: ({ value }) =>
			value ? value && new Date(value).toLocaleString() : "N/A",
	},
];
