import React from "react";
import Table from "./data/table";
import { columns } from "./data/columns";
import { UserAPI } from "app/routes/middleware";
import { Helmet } from "react-helmet-async";
import { Alert, Box, InputAdornment, Snackbar, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import { SearchComp } from "app/components/Search";

const Users = () => {
	const [rowsData, setRowsData] = React.useState([]);
	const [rowModesModel, setRowModesModel] = React.useState({});
	const [numberOfItems, setNumberOfItems] = React.useState(10);
	const [pageNumber, setPageNumber] = React.useState(0);
	const [open, setOpen] = React.useState(false);
	const [message, setMessage] = React.useState("");
	const [severity, setSeverity] = React.useState("success");
	const [search, setSearch] = React.useState("");
	const [isLoading, setIsLoading] = React.useState(false);
	const [totalCount, setTotalCount] = React.useState(0);

	React.useEffect(() => {
		handleAPISearch("!", 10, 1);
		return () => {
			setRowsData([]);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSearch = (event) => setSearch(event.target.value);

	const handleAPISearch = async (_search, _numberOfItems, _pageNumber) => {
		try {
			setIsLoading(true);
			_search = _search === "!" ? "" : _search;
			const request = await UserAPI.get(_search, _numberOfItems, _pageNumber);
			const count = request.data.data.totalCount;
			// console.log(count, "count");
			setTotalCount(count);
			const response = request.data.data.result;
			// console.log(request.data.data.result, "users");
			setIsLoading(false);
			if (response && response.length) {
				setRowsData(response);
			}

			if (response && response.length === 0) {
				// console.log(request, "users");
				setMessage("No users found");
				setSeverity("info");
				setOpen(true);
				setRowsData([]);
			}
		} catch (error) {
			// console.log(error.response.data, "user error");
			setIsLoading(false);
			if (error.response.status === 401) {
				setMessage(
					"Sorry your sign in token has expired, please sign in again to continue using Spark Admin 🤭"
				);
				setSeverity("error");
				setOpen(true);
				localStorage.clear("@spark-session");
				window.location.replace("/sign-in");
			} else if (error.response.status === 404) {
				setMessage(
					"Sorry, there was an error loading the users, please sign out and sign in again 😅"
				);
				setRowsData([]);
				setSeverity("error");
				setOpen(true);
			} else {
				setMessage(
					"Sorry, there was an error loading the users, please refresh or sign out and try again 😅"
				);
				setSeverity("error");
				setOpen(true);
			}
		}
	};

	return (
		<div>
			<Helmet>
				<title>Users - Spark Dashboard</title>
			</Helmet>
			<Snackbar
				open={open}
				autoHideDuration={2000}
				onClose={() => setOpen(false)}
			>
				<Alert
					onClose={() => setOpen(false)}
					severity={severity}
					sx={{ width: "60%" }}
				>
					{message}
				</Alert>
			</Snackbar>
			<SearchComp
				search={search}
				handleSearch={handleSearch}
				handleAPISearch={handleAPISearch}
				numberOfItems={numberOfItems}
				pageNumber={pageNumber}
				placeholder={"Search for specific users"}
			/>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					marginTop: "-30px",
				}}
			>
				<h2>Users</h2>
			</div>
			<Table
				isLoading={isLoading}
				rows={rowsData}
				setRows={setRowsData}
				columns={columns}
				disableSelectionOnClick={true}
				rowsPerPageOptions={[10, 20, 30]}
				rowModesModel={rowModesModel}
				setRowModesModel={setRowModesModel}
				page={pageNumber}
				pageSize={numberOfItems}
				pagination={true}
				paginationMode="server"
				// rowCount={rowsData?.length ? rowsData?.length : 0}
				rowCount={totalCount}
				onPageChange={(_page, _gridDets) => {
					setPageNumber(_page);
					handleAPISearch(search, numberOfItems, _page + 1);
				}}
				onPageSizeChange={(_size, _gridDets) => {
					setNumberOfItems(_size);
					handleAPISearch(search, _size, pageNumber);
				}}
			/>
		</div>
	);
};

export default Users;
