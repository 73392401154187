import {
	Box,
	Button,
	IconButton,
	Modal,
	TextField,
	Typography,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { AdminDealsAPI } from "app/routes/middleware";
import { STATUSCODES } from "app/utils/appHelpers";

const EditProduct = ({
	setEditProductOpen,
	editProductOpen,
	editedProduct,
	setIsItemUpdateSuccess,
	setMessage,
	setSeverity,
	setOpenSnackbar,
}) => {
	const { handleSubmit, control, reset } = useForm();
	const [isSaving, setIsSaving] = React.useState();

	React.useEffect(() => {
		if (editedProduct) {
			reset({
				vendorPrice: editedProduct.vendorPrice || "",
				sellingPrice: editedProduct.sellingPrice || "",
				availableItems: editedProduct.availableItems || "",
			});
		}
	}, [editedProduct, reset]);

	const handleEditModalClose = () => {
		setEditProductOpen(false);
	};

	const handleUpdateCatalogProduct = async (data) => {
		setIsSaving(false);
		// console.log({ editedProduct });
		const payload = {
			vendorPrice: parseInt(data.vendorPrice),
			sellingPrice: parseInt(data.sellingPrice),
			availableItems: parseInt(data.availableItems),
			discount: parseFloat(
				(
					((data.vendorPrice - data.sellingPrice) / data.vendorPrice) *
					100
				).toFixed()
			),
		};
		// console.log("Saved Update: ", {
		// 	payload,
		// 	catalogId: editedProduct?.catalogId,
		// 	id: editedProduct?.id,
		// });
		try {
			const request = await AdminDealsAPI.updateItemInACatalog({
				itemId: editedProduct?.id,
				catalogId: editedProduct?.catalogId,
				payload,
			});
			// console.log(request, "request");
			if (STATUSCODES.SUCCESS.includes(request.status)) {
				setIsSaving(false);
				handleEditModalClose();
				setIsItemUpdateSuccess(true);
				setOpenSnackbar(true);
				setMessage(`Item ${editedProduct?.name} updated successfully`);
				setSeverity("success");
			}
			setIsItemUpdateSuccess(false);
		} catch (error) {
			// console.log(error, "error");
			if (STATUSCODES.CLIENT_ERROR.includes(error?.response?.status)) {
				setMessage(
					`Error updating item ${editedProduct?.name}. Please make sure you've entered the right details or please reach out to tech support`
				);
				setSeverity("error");
				setOpenSnackbar(true);
			} else if (STATUSCODES.SERVER_ERROR.includes(error?.response?.status)) {
				setMessage(
					"Sorry this is from us, please try again later or reach out to tech support"
				);
				setSeverity("error");
				setOpenSnackbar(true);
			}
		}
	};

	return (
		<Modal open={editProductOpen}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: 500,
					bgcolor: "background.paper",
					boxShadow: 24,
					p: 4,
					borderRadius: "8px",
					outline: "none",
					overflow: "auto",
					maxHeight: "95vh",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Typography variant="h5" sx={{ fontWeight: 600 }}>
						Editing{" "}
						<span style={{ color: "#E88C41" }}>{editedProduct?.name}'s</span>{" "}
					</Typography>
					<IconButton onClick={() => setEditProductOpen(false)}>
						<CloseIcon />
					</IconButton>
				</div>
				<form onSubmit={handleSubmit(handleUpdateCatalogProduct)}>
					{/* TO DO */}
					{/* <Controller
						name="image"
						control={control}
						defaultValue={editedProduct?.image || ""}
						render={({ field }) => (
							<TextField
								fullWidth
								margin="normal"
								label="Image"
								{...field}
								type="file"
							/>
						)}
					/> */}
					{/* <Controller
						name="name"
						control={control}
						defaultValue={editedProduct?.name || ""}
						render={({ field }) => (
							<TextField
								fullWidth
								margin="normal"
								label="Product Name"
								{...field}
								inputProps={{
									type: "text",
								}}
							/>
						)}
					/>
					<Controller
						name="description"
						control={control}
						defaultValue={editedProduct?.description || ""}
						render={({ field }) => (
							<TextField
								fullWidth
								margin="normal"
								label="Product Description"
								{...field}
								inputProps={{
									type: "text",
								}}
							/>
						)}
					/> */}
					<Controller
						name="vendorPrice"
						control={control}
						defaultValue={editedProduct?.vendorPrice || ""}
						render={({ field }) => (
							<TextField
								fullWidth
								margin="normal"
								label="Vendor Price"
								{...field}
								inputProps={{
									type: "text",
								}}
							/>
						)}
					/>
					<Controller
						name="sellingPrice"
						control={control}
						defaultValue={editedProduct?.sellingPrice || ""}
						render={({ field }) => (
							<TextField
								fullWidth
								margin="normal"
								label="Selling Price"
								{...field}
								inputProps={{
									type: "text",
								}}
							/>
						)}
					/>
					<Controller
						name="availableItems"
						control={control}
						defaultValue={editedProduct?.availableItems || ""}
						render={({ field }) => (
							<TextField
								fullWidth
								margin="normal"
								label="Available Items"
								{...field}
								inputProps={{
									type: "text",
								}}
							/>
						)}
					/>

					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							mt: 2,
						}}
					>
						<Button variant="outlined" color="primary" type="submit">
							{isSaving === true ? "Saving..." : "Save Changes"}
						</Button>
					</Box>
				</form>
			</Box>
		</Modal>
	);
};

export default EditProduct;
