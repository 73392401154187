export const CatalogsColumns = [
	{
		id: 1,
		view: "ACTIVE",
		name: "Published Catalogs",
	},
	{
		id: 2,
		view: "DRAFT",
		name: "Catalogs in Draft",
	},
	{
		id: 3,
		view: "PAST",
		name: "Past Catalogs",
	},
];
