import React from "react";
import Table from "./data/table";
import { columns } from "./data/columns";
import { ComplianceAPI } from "app/routes/middleware";
import { Helmet } from "react-helmet-async";
import { Alert, Snackbar } from "@mui/material";

const Compliance = () => {
	const [rowsData, setRowsData] = React.useState([]);
	const [rowModesModel, setRowModesModel] = React.useState({});
	const [numberOfItems, setNumberOfItems] = React.useState(50);
	const [open, setOpen] = React.useState(false);
	const [message, setMessage] = React.useState("");
	const [severity, setSeverity] = React.useState("success");

	React.useEffect(() => {
		handleComplianceLoad();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleComplianceLoad = async () => {
		try {
			const request = await ComplianceAPI.get();
			const response = request.data.data;
			// console.log(request.data.data);

			if (response && response.length) {
				setRowsData(response);
			}
		} catch (error) {
			// console.log(error);
			if (error.response.status === 401) {
				setMessage(
					"Sorry your sign in token has expired, please sign in again to continue using Spark Admin 🤭"
				);
				setSeverity("error");
				setOpen(true);
				localStorage.clear("@spark-session");
				window.location.replace("/sign-in");
			} else {
				setMessage("Sorry, an error occurred while fetching compliance data. Please try again later 😭");
				setSeverity("error");
				setOpen(true);
			}
		}
	};

	return (
		<div>
			<Helmet>
				<title>Compliance - Spark Dashboard</title>
			</Helmet>
			<Snackbar
				open={open}
				autoHideDuration={2000}
				onClose={() => setOpen(false)}
			>
				<Alert
					onClose={() => setOpen(false)}
					severity={severity}
					sx={{ width: "60%" }}
				>
					{message}
				</Alert>
			</Snackbar>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					marginTop: "-30px",
				}}
			>
				<h2>User Compliance</h2>
			</div>
			<Table
				rows={rowsData}
				setRows={setRowsData}
				columns={columns}
				rowModesModel={rowModesModel}
				setRowModesModel={setRowModesModel}
				pageSize={numberOfItems}
				pagination={true}
				disableSelectionOnClick={true}
				rowCount={rowsData?.length ? rowsData?.length : 0}
				onPageSizeChange={(pageSize) => {
					setNumberOfItems(pageSize);
				}}
			/>
		</div>
	);
};

export default Compliance;
