import React from "react";
import { Helmet } from "react-helmet-async";
import Access from "./access";
import Admins from "./admins";
import Roles from "./roles";

const Permissions = () => {
	return (
		<div>
			<Helmet>
				<title>Permissions - Spark Dashboard</title>
			</Helmet>
			<div style={{ marginBottom: "6rem" }}>
				<Admins />
			</div>
			<div style={{ marginBottom: "6rem" }}>
				<Roles />
			</div>
			<div>
				<Access />
			</div>
		</div>
	);
};

export default Permissions;
