import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";

const currencyFormatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "GHS",
});

export const Table1 = ( { rows, columns, viewState, setViewState } ) => {
  const [pageSize, setPageSize] = useState(5)

	const extraColumns = [
		{
			field: "amount",
			headerName: "Amount",
			width: 180,
			valueFormatter: ({ value }) => currencyFormatter.format(value),
			cellClassName: "font-tabular-nums",
			hideable: true,
			// hide: false,
		},
		{
			field: "numberOfTimes",
			headerName: "Frequency",
			width: 100,
			hideable: true,
			// hide: viewState === true ? "true" : "false",
		},
	];

	// const [columnVisibilityModel, setColumnVisibilityModel] = useState({
	// 	id: false,
	// 	brokerId: false,
	// 	status: false,
	// });

	return (
		<Box
			sx={{
				backgroundColor: "background.paper",
				borderRadius: 3,
				height: 400,
				width: "100%",
				"& .actions": {
					color: "text.secondary",
				},
				"& .textPrimary": {
					color: "text.primary",
				},
			}}
		>
			{/* <button onClick={() => setViewState(!viewState)}>
				{viewState === true ? "Hide Friquency" : "View Frequency"}
			</button> */}
			<DataGrid
				style={{ padding: "0px 20px", borderRadius: "10px" }}
				loading={rows.length === 0}
				rows={rows}
				pageSize={pageSize}
				rowsPerPageOptions={[5, 10]}
				rowCount={rows?.length ? rows?.length : 0}
				onPageSizeChange={(pageSize) => {
					setPageSize(pageSize);
				}}
				columns={[
					...columns?.map(
						(obj) => extraColumns?.find((o) => o.field === obj?.field) || obj
					),
				]}
				// columnVisibilityModel={columnVisibilityModel}
				// onColumnVisibilityModelChange={(newModel) => {
				// 	console.log(newModel, "newModel");
				// 	setColumnVisibilityModel(newModel);
				// }}
				initialState={{
					sorting: {
						sortModel: [
							viewState === false
								? { field: "amount", sort: "desc" }
								: {
										field: "numberOfTimes",
										sort: "desc",
								  },
						],
					},
				}}
			/>
		</Box>
	);
};
export const Table2 = () => {};
