import React from "react";
import Box from "@mui/material/Box";
import {
	DataGrid
} from "@mui/x-data-grid";
import { CustomToolbar } from "app/utils/appHelpers";


const Table = ({
	rows,
	columns,
	rowModesModel,
	handleRowEditStart,
	handleRowEditStop,
	processRowUpdate,
	setrows,
	setrowmodesmodel,
	pagination,
	pageSize,
	onPageSizeChange,
	rowCount,
	disableSelectionOnClick,
	loading,
}) => {
	return (
		<Box
			sx={{
				backgroundColor: "background.paper",
				height: 500,
				width: "100%",
				"& .actions": {
					color: "text.secondary",
				},
				"& .textPrimary": {
					color: "text.primary",
				},
			}}
		>
			<DataGrid
				style={{ padding: "0px 20px", borderRadius: "10px" }}
				loading={rows.length === 0}
				pagination={pagination}
				pageSize={pageSize}
				onPageSizeChange={onPageSizeChange}
				disableSelectionOnClick={disableSelectionOnClick}
				rows={rows ? rows : ["No data"]}
				columns={columns}
				rowsPerPageOptions={[50, 100, 150]}
				editMode="row"
				rowModesModel={rowModesModel}
				onRowEditStart={handleRowEditStart}
				onRowEditStop={handleRowEditStop}
				processRowUpdate={processRowUpdate}
				components={{
					Toolbar: CustomToolbar,
				}}
				componentsProps={{
					toolbar: { setrows, setrowmodesmodel },
				}}
				experimentalFeatures={{ newEditingApi: true }}
				sx={{
					boxShadow: 2,
					"& .MuiDataGrid-cell:hover": {
						color: "primary.main",
					},
				}}
				initialState={{
					sorting: {
						sortModel: [{ field: "firstName", sort: "asc" }],
					},
				}}
			/>
		</Box>
	);
};

export default Table;
