import React from 'react'
import { Helmet } from "react-helmet-async";
import ProductTable from "./data/table";
import { Alert, Snackbar } from "@mui/material";

const Products = () => {
	const [message, setMessage] = React.useState("");
	const [severity, setSeverity] = React.useState("success");
	const [openSnackbar, setOpenSnackbar] = React.useState(false);
	return (
		<div>
			<Helmet>
				<title>Deals Product List - Spark Dashboard</title>
			</Helmet>
			<Snackbar
				open={openSnackbar}
				autoHideDuration={3000}
				onClose={() => setOpenSnackbar(false)}
			>
				<Alert
					onClose={() => setOpenSnackbar(false)}
					severity={severity}
					sx={{ width: "100%" }}
				>
					{message}
				</Alert>
			</Snackbar>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<h2>Deals Product List</h2>
			</div>
			<ProductTable
				setMessage={setMessage}
				setSeverity={setSeverity}
				setOpenSnackbar={setOpenSnackbar}
			/>
		</div>
	);
};

export default Products