export const Button = ({ className, style, title, type, onClick }) => {
	return (
		<button
			className={className}
			style={style}
			type={type ? type : "submit"}
			onClick={onClick}
		>
			{title}
		</button>
	);
};
