export const USER_STATUSES = [
	{ id: 1, name: "Active", code: "activate" },
	{ id: 2, name: "Inactive", code: "inactivate" },
	{ id: 3, name: "Suspended", code: "suspend" },
	{ id: 4, name: "Pending", code: "pending" },
];

export const CLUB_STATUSES = [
	{ id: 1, name: "Active", code: "activate" },
	{ id: 2, name: "Inactive", code: "inactivate" },
	{ id: 3, name: "Suspended", code: "suspend" },
	{ id: 4, name: "Pending", code: "pending" },
];

export const MONEY_REQUEST_STATUSES = [
	{ id: 1, name: "Pending Approval", code: "PENDING", color: "info" },
	{ id: 2, name: "Pending OTP", code: "PENDING_OTP", color: "info" },
	{ id: 3, name: "Approved", code: "APPROVED", color: "success" },
	{ id: 4, name: "Canceled", code: "CANCELED", color: "error" },
	{ id: 5, name: "Declined", code: "DECLINED", color: "secondary" },
	{ id: 6, name: "Successful", code: "SUCCESSFUL", color: "success" },
	{ id: 7, name: "Failed", code: "FAILED", color: "error" },
	{ id: 8, name: "Expired", code: "EXPIRED", color: "warning" },
];

export const statusPatchKeys = {
	"User Active": "activate",
	"User Inactive": "inactivate",
	"User Suspended": "suspend",
	"User Pending": "pending",

	"Club Active": "activate",
	"Club Inactive": "inactivate",
	"Club Suspended": "suspend",
	"Club Pending": "pending",
};

export const TRANSACTION_STATUSES = {
	PENDING: "Pending Approval",
	PENDING_OTP: "Pending OTP",
	PENDING_USER_SIGN_UP: "Pending User Sign Up",
	APPROVED: "Approved",
	EXPIRED: "Expired",
	CANCELED: "Canceled",
	DECLINED: "Declined",
	SUCCESSFUL: "Successful",
	FAILED: "Failed",
};

export const TransactionTypes = {
	"Send Money": { name: "Send Money", color: "success" },
	"Request Money": { name: "Request Money", color: "primary" },
	Loan: { name: "Loan", color: "info" },
	"Cash Out": { name: "Cash Out", color: "warning" },
	"Cash In": { name: "Cash In", color: "secondary" },
};
