import React from "react";
import PropTypes from "prop-types";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SensorDoorOutlinedIcon from "@mui/icons-material/SensorDoorOutlined";
// import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
	Alert,
	Box,
	Button,
	Chip,
	FormControl,
	InputLabel,
	LinearProgress,
	MenuItem,
	Modal,
	Select,
	Snackbar,
	Typography,
} from "@mui/material";
import {
	DataGrid,
	GridActionsCellItem,
	GridRowModes,
	GridToolbarContainer,
} from "@mui/x-data-grid";
import { PermissionsAPI } from "app/routes/middleware";
import { MenuProps, ModalStyle } from "app/utils/constants/styles";
import Div from "@jumbo/shared/Div";
import { RolesColumns } from "./data/columns";

function EditToolbar(props) {
	const { setRows, setRowModesModel } = props;

	const handleClick = () => {
		const id = Math.random().toFixed(4);
		setRows((oldRows) => [
			...oldRows,
			{
				id,
				name: "",
				description: "",
				isActive: false,
				status: "Active",
				createdAt: new Date().toISOString(),
				updatedAt: new Date().toISOString(),
				isNew: true,
			},
		]);
		setRowModesModel((oldModel) => ({
			...oldModel,
			[id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
		}));
		// console.log(setRows, "setRows");
	};

	return (
		<GridToolbarContainer>
			<Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
				Add A Permission
			</Button>
		</GridToolbarContainer>
	);
}

EditToolbar.propTypes = {
	setRowModesModel: PropTypes.func.isRequired,
	setRows: PropTypes.func.isRequired,
};

const Roles = () => {
	const [progress, setProgress] = React.useState(0);
	const [loading, setLoading] = React.useState(true);
	const [rows, setRows] = React.useState([]);
	const [rowModesModel, setRowModesModel] = React.useState({});
	const [numberOfItems, setNumberOfItems] = React.useState(10);
	const [openSnackbar, setOpenSnackbar] = React.useState(false);
	const [message, setMessage] = React.useState("");
	const [severity, setSeverity] = React.useState("success");
	const [accessList, setAccessList] = React.useState([]);
	const [openModal, setOpenModal] = React.useState(false);
	const [viewSelectedRow, setViewSelectedRow] = React.useState({});
	const [editSelectedRow, setEditSelectedRow] = React.useState({});
	const [selectedAccess, setSelectedAccess] = React.useState([]);
	const [openAccessModal, setOpenAccessModal] = React.useState(false);

	const handleClose = () => setOpenModal(false);

	const handleAccessModalClose = () => setOpenAccessModal(false);

	const handleRowEditStart = (params, event) => {
		event.defaultMuiPrevented = true;
	};

	const handleRowEditStop = (params, event) => {
		event.defaultMuiPrevented = true;
	};

	const handleEditClick = (id) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
	};

	const handleSaveClick = (id) => () => {
		if (selectedAccess.length > 0) {
			setOpenAccessModal(false);
			setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
		} else {
			setOpenAccessModal(true);
		}
	};

	// const handleDeleteClick = (id) => () => {
	// 	setRows(rows.filter((row) => row.id !== id));
	// };

	const handleCancelClick = (id) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		});

		const editedRow = rows.find((row) => row.id === id);
		if (editedRow.isNew) {
			setRows(rows.filter((row) => row.id !== id));
		}
	};

	const processRowUpdate = (newRow) => {
		const updatedRow = { ...newRow, isNew: false };
		const { name, description, isActive, status, createdAt, updatedAt } =
			newRow;

		const payload = {
			name: name,
			description: description,
			isActive: isActive,
			status: status,
			accesses: selectedAccess,
			createdAt: createdAt,
			updatedAt: updatedAt,
		};
		// console.log(payload, "payload");

		if (newRow.isNew === true) {
			setLoading(true);
			PermissionsAPI.createPermission(payload)
			.then((res) => {
					setLoading(false);
					setMessage(res.data.message);
					setSeverity("success");
					setOpenSnackbar(true);
					setRows(rows.map((row) => (row.id === newRow.id ? newRow : row)));
				})
				.catch((err) => {
					setLoading(false);
					setMessage(err.response?.data.message);
					setSeverity("error");
					setOpenSnackbar(true);
				});
		}

		if (!newRow.isNew) {
			setLoading(true);
			PermissionsAPI.updatePermission({
				id: updatedRow.id,
				data: {
					name: name,
					description: description,
					isActive: isActive,
					status: status,
					accesses: selectedAccess,
					createdAt: createdAt,
					updatedAt: updatedAt,
				},
			})
				.then((res) => {
					setMessage(res.data.message);
					setLoading(false);
					setSeverity("success");
					setOpenSnackbar(true);
					setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
				})
				.catch((err) => {
					setLoading(false);
					setMessage(err.response?.data.message);
					// console.log(err.response?.data.message, "err");
					setSeverity("error");
					setOpenSnackbar(true);
				});
		}
		return updatedRow;
	};

	const handleAccessSelectChange = (event) => {
		const value = event.target.value;
		// console.log(value, "value");
		setSelectedAccess(value);
	};

	const getAccessList = () => {
		PermissionsAPI.getAccess()
			.then((res) => {
				setAccessList(res.data.data.results);
				// console.log(res.data.data.results, "access list");
			})
			.catch((err) => {
				setMessage(err.response?.data.message);
				setSeverity("error");
				setOpenSnackbar(true);
			});
	};

	const actions = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 100,
			cellClassName: "actions",
			getActions: (params) => {
				const isInEditMode =
					rowModesModel[params.id]?.mode === GridRowModes.Edit;

				if (isInEditMode) {
					return [
						<GridActionsCellItem
							icon={<SaveIcon />}
							label="Save"
							// onClick={handleSaveClick(params.id)}
							onClick={() => {
								setEditSelectedRow({
									id: params.id,
									name: params.row.name,
									access: params.row.permissionsAccess,
								});
								setOpenAccessModal(true);
							}}
						/>,
						<GridActionsCellItem
							icon={<CancelIcon />}
							label="Cancel"
							className="textPrimary"
							onClick={handleCancelClick(params.id)}
							color="inherit"
						/>,
					];
				}

				return [
					<GridActionsCellItem
						icon={<SensorDoorOutlinedIcon />}
						label="View Access"
						className="textPrimary"
						onClick={() => {
							setViewSelectedRow({
								id: params.id,
								name: params.row.name,
								access: params.row.permissionsAccess,
							});
							setOpenModal(true);
						}}
						color="inherit"
					/>,
					<GridActionsCellItem
						icon={<EditIcon />}
						label="Edit Row"
						className="textPrimary"
						// onClick={() => {
						// 	setEditSelectedRow({
						// 		id: params.id,
						// 		name: params.row.name,
						// 		access: params.row.permissionsAccess,
						// 	});
						// 	setOpenAccessModal(true);
						// }}
						onClick={handleEditClick(params.id)}
						color="inherit"
					/>,
					// <GridActionsCellItem
					// 	icon={<DeleteIcon />}
					// 	label="Delete"
					// 	onClick={handleDeleteClick(id)}
					// 	color="inherit"
					// />,
				];
			},
		},
	];

	React.useEffect(() => {
		handleRolesLoad();
		getAccessList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		const timer = setInterval(() => {
			setProgress((oldProgress) => {
				if (oldProgress === 100) {
					return 0;
				}
				const diff = Math.random() * 10;
				return Math.min(oldProgress + diff, 100);
			});
		}, 500);

		return () => {
			clearInterval(timer);
		};
	}, []);

	const handleRolesLoad = async () => {
		try {
			setLoading(true);
			const request = await PermissionsAPI.getPermissions();
			const response = request.data.data.results;
			// console.log(request, "roles");
			if (response && response.length) {
				setLoading(false);
				setRows(response);
			}
		} catch (error) {
			setLoading(false);
			if (error.response?.status === 401) {
				setMessage(
					"Sorry your sign in token has expired, please sign in again to continue using Spark Admin 🤭"
				);
				setSeverity("error");
				setOpenSnackbar(true);
				localStorage.clear("@spark-session");
				window.location.replace("/sign-in");
			} else if (error.status === 210) {
				setMessage("Please check your internet connection 😅");
				setSeverity("error");
				setOpenSnackbar(true);
			} else {
				setMessage(
					"Sorry, there was an error loading the roles, please check your internet connection, refresh or sign out and sign in again 😅"
				);
				setSeverity("error");
				setOpenSnackbar(true);
			}
		}
	};
	return (
		<div>
			<Snackbar
				open={openSnackbar}
				autoHideDuration={2000}
				onClose={() => setOpenSnackbar(false)}
			>
				<Alert
					onClose={() => setOpenSnackbar(false)}
					severity={severity}
					sx={{ width: "60%" }}
				>
					{message}
				</Alert>
			</Snackbar>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<h2>Manage Permissions</h2>
			</div>
			{loading && (
				<LinearProgress
					value={progress}
					variant="determinate"
					sx={{ mt: -1, mb: 1 }}
				/>
			)}
			<Box
				sx={{
					backgroundColor: "#FDF6E4",
					borderRadius: 3,
					height: 500,
					width: "100%",
					"& .actions": {
						color: "text.secondary",
					},
					"& .textPrimary": {
						color: "text.primary",
					},
				}}
			>
				<DataGrid
					loading={rows.length === 0}
					style={{ padding: "0px 20px", borderRadius: "10px" }}
					rows={rows}
					columns={[
						...RolesColumns.map(
							(obj) => actions.find((o) => o.field === obj.field) || obj
						),
					]}
					// columns={RolesColumns}
					editMode="row"
					rowModesModel={rowModesModel}
					onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
					onRowEditStart={handleRowEditStart}
					onRowEditStop={handleRowEditStop}
					processRowUpdate={processRowUpdate}
					components={{
						Toolbar: EditToolbar,
					}}
					componentsProps={{
						toolbar: { setRows, setRowModesModel },
					}}
					experimentalFeatures={{ newEditingApi: true }}
					pageSize={numberOfItems}
					pagination={true}
					onPageSizeChange={(pageSize) => {
						setNumberOfItems(pageSize);
					}}
					rowsPerPageOptions={[10, 15, 20]}
					onProcessRowUpdateError={(error) => {
						setMessage(error.message);
						setSeverity("error");
						setOpenSnackbar(true);
					}}
					initialState={{
						sorting: {
							sortModel: [{ field: "name", sort: "asc" }],
						},
					}}
				/>
			</Box>
			{/* {view access modal} */}
			<Modal
				open={openModal}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={ModalStyle}>
					<Div>
						<Typography
							id="modal-modal-title"
							variant="h6"
							component="h2"
							sx={{ mb: 3, mt: 1 }}
						>
							{viewSelectedRow?.name + "'s Page Accesses 👇🏼"}
						</Typography>
						{/* {console.log(viewSelectedRow, "viewSelectedRow")} */}
						{viewSelectedRow?.access ? (
							viewSelectedRow?.access?.map((x) => {
								return (
									<Chip
										style={{ marginRight: "5px", marginBottom: "10px" }}
										key={x.id}
										label={x.access.name}
										title={x.access.description}
									/>
								);
							})
						) : (
							<Typography
								style={{
									color: "red",
									border: "1px solid red",
									backgroundColor: "#E8E8E8",
									borderRadius: "20px",
									padding: "10px",
									fontSize: "14px",
									textAlign: "center",
									fontWeight: "500",
								}}
							>
								No page accesses found for this role
							</Typography>
						)}
					</Div>
				</Box>
			</Modal>
			{/* {edit access modal} */}
			<Modal
				open={openAccessModal}
				onClose={handleAccessModalClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={ModalStyle}>
					<Typography
						id="modal-modal-title"
						variant="h6"
						component="h2"
						sx={{ mb: 3, mt: 1 }}
					>
						{editSelectedRow?.name &&
							editSelectedRow?.name + "'s Page Accesses 👇🏼"}
					</Typography>
					{editSelectedRow?.access ? (
						editSelectedRow?.access?.map((x) => {
							return (
								<Chip
									style={{ marginRight: "5px", marginBottom: "10px" }}
									key={x.id}
									label={x.access.name}
									title={x.access.description}
								/>
							);
						})
					) : (
						<Typography
							style={{
								color: "red",
								border: "1px solid red",
								backgroundColor: "#E8E8E8",
								borderRadius: "20px",
								padding: "10px",
								fontSize: "14px",
								textAlign: "center",
								fontWeight: "500",
							}}
						>
							No page accesses set for this role
						</Typography>
					)}
					<div>
						<FormControl required fullWidth sx={{ mt: 2 }}>
							<InputLabel id="mutiple-select-label">
								Set/Update Accesses
							</InputLabel>
							<Select
								labelId="mutiple-select-label"
								multiple
								fullWidth
								value={selectedAccess}
								label="Select Permissions"
								onChange={handleAccessSelectChange}
								renderValue={(selected) => selected.join(", ")}
								MenuProps={MenuProps}
								sx={{ color: "primary" }}
							>
								{accessList.map((x) => (
									<MenuItem key={x.id} value={x.id}>
										{x.name}
									</MenuItem>
								))}
							</Select>
							<div style={{ display: "flex", flexDirection: "column" }}>
								{selectedAccess.length <= 0 ? (
									<span style={{ color: "red", fontSize: "12px" }}>
										Please select at least one access
									</span>
								) : null}

								<div style={{ display: "flex", flexDirection: "column" }}>
									<Button
										sx={{ mt: 2 }}
										onClick={handleSaveClick(editSelectedRow.id)}
										variant="contained"
										color="secondary"
										disabled={selectedAccess.length > 0 ? false : true}
									>
										Continue
									</Button>
								</div>
							</div>
						</FormControl>
					</div>
				</Box>
			</Modal>
		</div>
	);
};

export default Roles;
