// import { Check, Close } from "@mui/icons-material";
// import { FormControl, MenuItem, Select } from "@mui/material";
// import {
// 	MONEY_REQUEST_STATUSES,
// 	statusPatchKeys,
// } from "app/components/Statuses";
// import { MoneyRequestsAPI, UserAPI } from "app/routes/middleware";
import { Button } from "@mui/material";
import { moneyFormat } from "app/components/MoneyFormat";
import { MONEY_REQUEST_STATUSES } from "app/components/Statuses";
import { userDetailsFormat } from "app/components/UserDetailsTooltip";

// const handleChange = async (event, params) => {
// 	const state = statusPatchKeys["User " + event.target.value?.trim()];
// 	const moneyRequestId = params.row.id;

// 	if (state && moneyRequestId) {
// try {
// 	console.log({ state, moneyRequestId });
// 	await UserAPI.patchUserStatus(moneyRequestId, state);
// } catch (error) {
// 	console.log(error);
// }
// 	}
// };

export const columns = [
	{ field: "id", headerName: "ID", width: 70, hide: true },
	{
		field: "requestSender",
		headerName: "Requester",
		width: 230,
		renderCell: (params) => userDetailsFormat(params, "Requester"),
	},
	{
		field: "requestReceiver",
		headerName: "Requestee",
		width: 230,
		renderCell: (params) => userDetailsFormat(params, "Requestee"),
	},
	{
		field: "amount",
		headerName: "Amount",
		width: 230,
		renderCell: (params) => {
			const currency = params?.row?.currency ? params?.row?.currency : "GHS";
			const amount = params?.row?.amount ? Number(params?.row?.amount) : null;
			return (
				<span style={{ fontSize: 12, fontWeight: "400" }}>
					{amount ? (
						<>
							{currency} {moneyFormat(amount, "en", 2)}
						</>
					) : (
						`No amount detected`
					)}
				</span>
			);
		},
	},
	{
		field: "status",
		headerName: "Status",
		width: 230,
		renderCell: (params) => {
			// console.log(params, "params");
			return params.value === null || "" ? (
				<span style={{ fontSize: 12, fontWeight: "400" }}>
					No status detected
				</span>
			) : (
				<span style={{ fontSize: 12, fontWeight: "400" }}>{params.value}</span>
			);
		},
	},
	{
		field: "clubId",
		headerName: "Requested From Club",
		width: 230,
		renderCell: (params) => {
			return params.value ? (
				<span style={{ marginLeft: 40, color: "#44D600" }}>Yes</span>
			) : (
				<span style={{ marginLeft: 40, color: "red" }}>No</span>
			);
		},
	},
	{
		field: "createdAt",
		headerName: "Date Requested",
		width: 230,
		type: "dateTime",
		renderCell: (params) => {
			const date = params?.row?.createdAt
				? new Date(params?.row?.createdAt).toUTCString()
				: "No date detected";
			return <span style={{ fontSize: 12, fontWeight: "500" }}> {date} </span>;
		},
	},
	{
		field: "updatedAt",
		headerName: "Date Updated",
		width: 230,
		type: "dateTime",
		renderCell: (params) => {
			const date = params?.row?.createdAt
				? new Date(params?.row?.createdAt).toUTCString()
				: "No date detected";
			return <span style={{ fontSize: 12, fontWeight: "500" }}> {date} </span>;
		},
	},
	{
		field: "expiresAt",
		headerName: "Expires At",
		width: 230,
		type: "dateTime",
		renderCell: (params) => {
			const date = params?.row?.createdAt
				? new Date(params?.row?.createdAt).toUTCString()
				: "No date detected";
			return <span style={{ fontSize: 12, fontWeight: "500" }}> {date} </span>;
		},
	},
];
