import { InputAdornment, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Search } from "@mui/icons-material";

export const SearchComp = ({
	search,
	handleSearch,
	handleAPISearch,
	numberOfItems,
	pageNumber,
	placeholder,
}) => {
	function makeSearchRequest(event) {
		if (event.code === "Enter") {
			handleAPISearch(search, numberOfItems, pageNumber);
		}
	}
	return (
		<Box width={250} sx={{ mb: 3, borderRadius: 2 }}>
			<TextField
				name="search"
				value={search}
				onChange={handleSearch}
				onKeyUp={makeSearchRequest}
				placeholder={placeholder}
				style={{ width: "100%" }}
				InputProps={{
					endAdornment: (
						<InputAdornment
							style={{ cursor: "pointer" }}
							position="start"
							onClick={() => handleAPISearch(search, numberOfItems, pageNumber)}
						>
							<Search />
						</InputAdornment>
					),
				}}
			/>
		</Box>
	);
};
