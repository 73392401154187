const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
	getContentAnchorEl: null,
	anchorOrigin: {
		vertical: "bottom",
		horizontal: "center",
	},
	transformOrigin: {
		vertical: "top",
		horizontal: "center",
	},
	variant: "menu",
};

export const ModalStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "none",
	outline: "none",
	borderRadius: "12px",
	boxShadow: 24,
	p: 4,
};

export const NotifsModalStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 500,
	bgcolor: "background.paper",
	border: "none",
	outline: "none",
	borderRadius: "12px",
	boxShadow: 24,
	p: 4,
};

export const LoadingModalStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	// bgcolor: "background.paper",
	border: "none",
	outline: "none",
	// borderRadius: "12px",
	// boxShadow: 24,
	p: 4,
};

