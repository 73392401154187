import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import {
	Avatar,
	Box,
	Button,
	IconButton,
	Modal,
	TextField,
	Typography,
} from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { StyledDataGrid } from "app/utils/appHelpers";
import { Columns } from ".";
import { AdminDealsAPI } from "app/routes/middleware";
import { ItemImageUpload } from "../../catalogs/imageUpload";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { STATUSCODES } from "app/utils/appHelpers";

const ProductTable = ({ setMessage, setSeverity, setOpenSnackbar }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [numberOfItems, setNumberOfItems] = useState(10);
	const [fetchedItems, setFetchedItems] = useState([]);
	const [files, setFiles] = useState([]);
	const [fileDrop, setFileDrop] = useState("");
	const [isEditingItems, setIsEditingItems] = useState(null);
	const [isUploading, setIsUploading] = useState(false);
	const [id, setId] = useState(null);
	const [newItemImage, setNewItemImage] = useState("");
	const [editedItemImage, setEditedItemImage] = useState("");
	const [editedItemName, setEditedItemName] = useState("");
	const [editedItemDescription, setEditedItemDescription] = useState("");
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [showUpdateImage, setShowUpdateImage] = useState(false);
	const [isProductUpdateSuccess, setIsOrderUpdateSuccess] =
		React.useState(false);
	const { reset } = useForm();

	React.useEffect(() => {
		handleGetItems();
		if (isProductUpdateSuccess === true) {
			handleGetItems();
		}
	}, [isProductUpdateSuccess]);

	const handleGetItems = async () => {
		setIsLoading(true);
		try {
			const request = await AdminDealsAPI.getItems();
			const response = request.data.data;
			setFetchedItems(response);
			setIsLoading(false);
			console.log("Items:", response);
		} catch (error) {
			console.log(error);
			setIsLoading(false);
		}
	};

	const handleDeleteItemImage = (file) => {
		setFiles((curr) => curr.filter((f) => f.name !== file.name));
		setFileDrop("");
	};

	const extraColumns = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 100,
			cellClassName: "actions",
			getActions: (params) => {
				return [
					<GridActionsCellItem
						icon={<EditIcon />}
						label="Edit Row"
						className="textPrimary"
						color="inherit"
						onClick={() => {
							setIsOpenModal(true);
							setId(params.row.id);
							setEditedItemImage(params.row.image);
							setEditedItemName(params.row.name);
							setEditedItemDescription(params.row.description);
							// setNewItemImage(params.row.image);
						}}
					/>,
				];
			},
		},
	];

	const handleEditModalClose = () => {
		setFiles([]);
		setFileDrop("");
		setNewItemImage("");
		setEditedItemImage("");
		setEditedItemName("");
		setEditedItemDescription("");
		reset();
		setShowUpdateImage(false);
		setIsOpenModal(false);
	};

	const handleEditItem = async (e) => {
		e.preventDefault();
		setIsEditingItems(true);
		console.log({ newItemImage });
		if (!newItemImage || newItemImage.length === 0) {
			const payload = {
				id: id,
				name: editedItemName,
				description: editedItemDescription,
			};
			console.log({ payload });
			try {
				const editItemRequest = await AdminDealsAPI.updateItem({
					id: id,
					payload: payload,
				});
				if (STATUSCODES.SUCCESS.includes(editItemRequest.status)) {
					setMessage("Saved successfully 🎉");
					setSeverity("success");
					setNewItemImage("");
					setEditedItemImage("");
					setOpenSnackbar(true);
					setIsEditingItems(false);
					setIsOrderUpdateSuccess(true);
					handleEditModalClose();
					handleGetItems();
				}
				setIsOrderUpdateSuccess(false);
			} catch (error) {
				setIsEditingItems(false);
				if (STATUSCODES.CLIENT_ERROR.includes(error?.response?.status)) {
					setMessage(
						"Please refresh and try again or please reach out to tech support"
					);
					setSeverity("error");
					setOpenSnackbar(true);
				} else if (STATUSCODES.SERVER_ERROR.includes(error?.response?.status)) {
					setMessage(
						"Sorry this is from us, please try again later or reach out to tech support"
					);
					setSeverity("error");
					setOpenSnackbar(true);
				}
				// console.log(error);
			}
		} else if (newItemImage || newItemImage.length > 0) {
			try {
				const imageRequest = await AdminDealsAPI.uploadImage({
					image: newItemImage, // Use the itemImage state for image upload
				});

				if (STATUSCODES.SUCCESS.includes(imageRequest.status)) {
					setMessage("Image uploaded, now saving changes...");
					setSeverity("info");
					setOpenSnackbar(true);
					const payload = {
						id: id,
						name: editedItemName,
						description: editedItemDescription,
						image: imageRequest.data.data.imageUrl, // Store the image URL in the item object
					};
					console.log({ payload });
					try {
						const editItemRequest = await AdminDealsAPI.updateItem({
							id: id,
							payload: payload,
						});
						if (STATUSCODES.SUCCESS.includes(editItemRequest.status)) {
							setMessage("Saved successfully 🎉");
							setSeverity("success");
							setNewItemImage("");
							setEditedItemImage("");
							setOpenSnackbar(true);
							setIsEditingItems(false);
							setIsOrderUpdateSuccess(true);
							handleEditModalClose();
							handleGetItems();
						}
						setIsOrderUpdateSuccess(false);
					} catch (error) {
						setIsEditingItems(false);
						if (STATUSCODES.CLIENT_ERROR.includes(error?.response?.status)) {
							setMessage(
								"Please refresh and try again or please reach out to tech support"
							);
							setSeverity("error");
							setOpenSnackbar(true);
						} else if (
							STATUSCODES.SERVER_ERROR.includes(error?.response?.status)
						) {
							setMessage(
								"Sorry this is from us, please try again later or reach out to tech support"
							);
							setSeverity("error");
							setOpenSnackbar(true);
						}
						// console.log(error);
					}
				}
			} catch (error) {
				setIsEditingItems(false);
				setIsUploading(false);
				if (STATUSCODES.CLIENT_ERROR.includes(error?.response?.status)) {
					setMessage(
						"Please refresh and try again or please reach out to tech support"
					);
					setSeverity("error");
					setOpenSnackbar(true);
				} else if (STATUSCODES.SERVER_ERROR.includes(error?.response?.status)) {
					setMessage(
						"Sorry this is from us, please try again later or reach out to tech support"
					);
					setSeverity("error");
					setOpenSnackbar(true);
				}
				// console.log(error);
			}
		}
	};

	return (
		<div>
			<Box
				sx={{
					backgroundColor: "background.paper",
					borderRadius: 3,
					height: 600,
					width: "100%",
					"& .actions": {
						color: "text.secondary",
					},
					"& .textPrimary": {
						color: "text.primary",
					},
				}}
			>
				<StyledDataGrid
					style={{ padding: "0px 20px", borderRadius: "10px" }}
					rowHeight={80}
					rows={fetchedItems}
					loading={fetchedItems.length === 0}
					pageSize={numberOfItems}
					pagination={true}
					disableSelectionOnClick={true}
					rowCount={fetchedItems?.length ? fetchedItems?.length : 0}
					onPageSizeChange={(pageSize) => {
						setNumberOfItems(pageSize);
					}}
					columns={[
						...Columns.map(
							(obj) => extraColumns.find((o) => o.field === obj.field) || obj
						),
					]}
					rowsPerPageOptions={[10, 15, 20]}
					experimentalFeatures={{ newEditingApi: true }}
					sx={{
						boxShadow: 2,
						"& .MuiDataGrid-cell:hover": {
							color: "primary.main",
						},
					}}
					initialState={{
						sorting: {
							sortModel: [{ field: "name", sort: "asc" }],
						},
					}}
				/>
			</Box>
			<Modal open={isOpenModal}>
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: 500,
						bgcolor: "background.paper",
						boxShadow: 24,
						p: 4,
						borderRadius: "8px",
						outline: "none",
						overflow: "auto",
						maxHeight: "95vh",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<Typography variant="h5" sx={{ fontWeight: 600 }}>
							Add New Items To Catalog
						</Typography>
						<IconButton onClick={handleEditModalClose}>
							<CloseIcon />
						</IconButton>
					</div>
					<form>
						<div style={{ marginTop: "20px" }}>
							{showUpdateImage === false ? (
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
									}}
								>
									<Avatar
										src={editedItemImage}
										variant="square"
										alt="Avatar"
										sx={{
											width: 200,
											height: 150,
											objectFit: "fill",
											border: "1px solid #CFCECE",
											borderRadius: "5px",
											mb: 2,
											boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
										}}
									/>
									<Button
										variant="contained"
										color="secondary"
										sx={{ fontSize: "10px" }}
										onClick={() => setShowUpdateImage(true)}
									>
										Change Catalog Image
									</Button>
								</div>
							) : (
								<div
									style={{
										display: "flex",
										alignItems: "center",
										flexDirection: "column",
										width: "100%",
										margin: "20px 0px",
									}}
								>
									<ItemImageUpload
										setFileDrop={setNewItemImage} // Set itemImage when an image is dropped or selected
										title="Upload Item Image"
										setFiles={setFiles}
										files={files}
										handleDeleteItemImage={handleDeleteItemImage}
									/>
								</div>
							)}
							<TextField
								label="Item Name"
								fullWidth
								margin="normal"
								value={editedItemName}
								onChange={(e) => setEditedItemName(e.target.value)}
							/>
							<TextField
								label="Item Description"
								fullWidth
								margin="normal"
								value={editedItemDescription}
								onChange={(e) => setEditedItemDescription(e.target.value)}
							/>
						</div>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								mt: 3,
							}}
						>
							<Button
								variant="contained"
								color="secondary"
								// disabled={newItems.length === 0}
								type="submit"
								fullWidth
								sx={{ height: "50px" }}
								onClick={handleEditItem}
							>
								{isEditingItems === true ? "Saving..." : "Save Edits"}
							</Button>
						</Box>
					</form>
				</Box>
			</Modal>
		</div>
	);
};

export default ProductTable;
