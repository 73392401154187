import { alpha } from "@mui/material/styles";

export const sidebarTheme = {
	type: "light",
	palette: {
		primary: {
			main: "#F79031",
			light: "#FFEDD2",
			dark: "#FDD69D",
			contrastText: "#FFF",
		},
		secondary: {
			main: "#C2F2A2",
			light: "#E4FFD2",
			dark: "#62A933",
			contrastText: "#FFF",
		},
		error: {
			main: "#E73145",
			light: "#FF6A70",
			dark: "#AD001E",
			contrastText: "#FFF",
		},
		warning: {
			main: "#F39711",
			light: "#FFC84C",
			dark: "#BB6900",
			contrastText: "#FFF",
		},
		info: {
			main: "#2EB5C9",
			light: "#6FE7FC",
			dark: "#008598",
			contrastText: "#FFF",
		},
		success: {
			main: "#3BD2A2",
			light: "#78FFD3",
			dark: "#00A073",
			contrastText: "#FFF",
		},
		text: {
			primary: "#475259",
			secondary: "#8595A6",
			disabled: "#A2B2C3",
			title: "#37373C",
		},
		nav: {
			action: {
				active: "#62A933",
				hover: "#C2F2A2",
			},
			background: {
				active: alpha("#62A933", 0.15),
				hover: "#51b848",
			},
			tick: {
				active: "#C2F2A2",
				hover: "#000",
			},
		},
		divider: "#DEE2E6",
		background: {
			paper: "#FFFFFF",
			default: "#F5F7FA",
		},
		action: {
			active: "#475259",
			hover: "#F5F7FA",
		},
	},
};
