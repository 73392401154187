import React from "react";
import { useNavigate } from "react-router-dom";

const SearchGlobal = () => {
	const navigate = useNavigate();
	return (
		<div
			onClick={() => navigate("/home", { replace: true })}
			style={{
				display: "flex",
				alignItems: "center",
				marginTop: "10px",
				cursor: "pointer",
			}}
		>
			<div style={{ marginLeft: "-5px" }}>
				<img src="/images/logos/icon.png" alt="icon" width={70} />
			</div>
			<div style={{ marginLeft: "-10px" }}>
				<img src="/images/logos/logo.svg" alt="icon" width={100} />
			</div>
		</div>
	);
};

export default SearchGlobal;
