import { useState } from "react";
import { DateRange } from "react-date-range";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

export const DateValues = {
	start:
		new Date(new Date().getFullYear(), 0, 1).toISOString().split("T")[0] +
		"T00:00:00.000Z",
	end: new Date().toISOString(),
};

export const DateRangeComponent = ({ setDateRange }) => {
	const [state, setState] = useState([
		{
			startDate: new Date(),
			endDate: null,
			key: "selection",
		},
	]);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	return (
		<div>
			<Button aria-describedby={id} onClick={handleClick}>
				<CalendarMonthIcon />
			</Button>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<DateRange
					editableDateInputs={true}
					onChange={(item) => {
						setState([item.selection]);
						// console.log(item.selection);
						DateValues.start = item.selection.startDate.toISOString();
						DateValues.end = item.selection.endDate.toISOString();
						setDateRange({
							startDate: DateValues.start,
							endDate: DateValues.end,
						});
						// console.log(
						// 	{ startDate: DateValues.start, endDate: DateValues.end },
						// 	"dateValues!!"
						// );
					}}
					moveRangeOnFirstSelection={false}
					ranges={state}
				/>
			</Popover>
		</div>
	);
};
