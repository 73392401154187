import React from "react";
import {
	Area,
	AreaChart,
	CartesianGrid,
	Line,
	LineChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	Cell,
	Pie,
	PieChart,
	Legend,
	Radar,
	PolarGrid,
	PolarRadiusAxis,
	RadarChart,
} from "recharts";
import Div from "@jumbo/shared/Div";
import List from "@mui/material/List";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import styled from "@emotion/styled";

const CustomTooltip = ({ active, payload }) => {
	if (active) {
		return (
			<React.Fragment>
				{`${payload[0].name} : ${payload[0].value}%`}
			</React.Fragment>
		);
	}
	return null;
};

const orderReportsData = [
	{
		subject: "Math",
		A: 120,
		B: 110,
		fullMark: 150,
	},
	{
		subject: "Chinese",
		A: 98,
		B: 130,
		fullMark: 150,
	},
	{
		subject: "English",
		A: 86,
		B: 130,
		fullMark: 150,
	},
	{
		subject: "Geography",
		A: 99,
		B: 100,
		fullMark: 150,
	},
	{
		subject: "Physics",
		A: 85,
		B: 90,
		fullMark: 150,
	},
	{
		subject: "History",
		A: 65,
		B: 85,
		fullMark: 150,
	},
];


const ListItemInline = styled(ListItem)(({ theme }) => ({
	width: "auto",
	display: "inline-flex",
	padding: theme.spacing(0, 0.5),
}));

const renderLegend = (props) => {
	const { payload } = props;

	return (
		<List
			disablePadding
			sx={{
				display: "flex",
				alignItems: "center",
				textAlign: "center",
				justifyContent: "center",
				minWidth: 0,
			}}
		>
			{payload.map((entry, index) => {
				return (
					<ListItemInline key={`item-${index}`}>
						<ListItemIcon sx={{ minWidth: 16 }}>
							<FiberManualRecordIcon
								fontSize={"10px"}
								sx={{ color: entry.color }}
							/>
						</ListItemIcon>
						<ListItemText primary={entry.value} />
					</ListItemInline>
				);
			})}
		</List>
	);
};

export const Chart1 = ({ data }) => {
	return (
		<ResponsiveContainer height={55}>
			<LineChart data={data} className={"mx-auto"}>
				<CartesianGrid
					strokeDasharray="6 1 2"
					horizontal={false}
					strokeOpacity={0.5}
				/>
				<Tooltip
					cursor={false}
					content={({ active, label, payload }) => {
						return active ? (
							<Div sx={{ color: "common.white" }}>
								{payload.map((row, index) => (
									<div key={index}>{`${label}: ${row.value}`}</div>
								))}
							</Div>
						) : null;
					}}
					wrapperStyle={{
						backgroundColor: "rgba(0,0,0,.8)",
						padding: "5px 8px",
						borderRadius: 4,
						overflow: "hidden",
						// boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
					}}
				/>
				<XAxis dataKey="month" hide />
				<Line
					dataKey="count"
					type="monotone"
					dot={null}
					strokeWidth={3}
					stackId="2"
					stroke="rgb(52, 143, 108)"
				/>
			</LineChart>
		</ResponsiveContainer>
	);
};

export const Chart2 = ({ data, color, shadowColor, hovertitle }) => {
	return (
		<ResponsiveContainer height={80}>
			<LineChart data={data} className={"mx-auto"}>
				<defs>
					<filter id="shadow" height="200%">
						<feDropShadow
							dx="0"
							dy="5"
							stdDeviation="8"
							floodColor={shadowColor ? shadowColor : "black"}
							// floodColor={"#fff"}
						/>
					</filter>
				</defs>
				<Tooltip
					cursor={false}
					content={({ active, label, payload }) => {
						return active ? (
							<Div sx={{ color: "common.white" }}>
								{payload.map((row, index) => (
									<div
										key={index}
									>{`${label}: ${row.value} ${hovertitle}`}</div>
								))}
							</Div>
						) : null;
					}}
					wrapperStyle={{
						backgroundColor: color ? color : "rgba(0,0,0,.8)",
						padding: "5px 8px",
						borderRadius: 4,
						overflow: "hidden",
						// boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
					}}
				/>
				<XAxis dataKey="month" hide />
				<Line
					dataKey="count"
					// filter="url(#shadow)"
					type="monotone"
					dot={null}
					strokeWidth={3}
					stackId="2"
					stroke={color ? color : "#475259"}
				/>
			</LineChart>
		</ResponsiveContainer>
	);
};

export const Chart3 = ({ data, color }) => {
	return (
		<ResponsiveContainer height={80}>
			<AreaChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
				{/* <Tooltip
					cursor={false}
					content={({ active, label, payload }) => {
						return active ? (
							<div className={"text-white"}>
								{payload.map((row, index) => (
									<div key={index}>{`${label}: ${row.value} Signups`}</div>
								))}
							</div>
						) : null;
					}}
					wrapperStyle={{
						backgroundColor: "rgba(0,0,0,.8)",
						padding: "5px 8px",
						borderRadius: 4,
						overflow: "hidden",
						boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
					}}
				/> */}
				<XAxis dataKey="month" hide />
				<Area
					dataKey="count"
					type="monotone"
					strokeWidth={2}
					stackId="2"
					stroke="#FFf"
					fill={color ? color : "#0DC7B8"}
					fillOpacity={1}
				/>
			</AreaChart>
		</ResponsiveContainer>
	);
};

export const Chart4 = ({ data, color }) => {
	const { pieData, colors } = data;
	return (
		<Div sx={{ mb: 3 }}>
			<ResponsiveContainer height={224}>
				<PieChart>
					<defs>
						<filter id="f1" x="0" y="0" width="150%" height="150%">
							<feGaussianBlur in="SourceAlpha" stdDeviation="4" />
							<feOffset dx="2" dy="4" />
							<feMerge>
								<feMergeNode />
								<feMergeNode in="SourceGraphic" />
							</feMerge>
						</filter>
					</defs>
					<Pie
						data={pieData}
						color="#000000"
						dataKey="value"
						nameKey="name"
						cx="50%"
						cy="50%"
						isAnimationActive={true}
						outerRadius={95}
						fill="#8884d8"
						strokeWidth={0}
					>
						{pieData.map((entry, index) => (
							<Cell
								key={`cell-${index}`}
								fill={colors[index % colors.length]}
								strokeWidth={8}
							/>
						))}
					</Pie>
					<Tooltip content={<CustomTooltip />} />
				</PieChart>
			</ResponsiveContainer>
		</Div>
	);
};

export const Chart5 = ({ height }) => {
	return (
		<Div sx={{ pb: 3 }}>
			<ResponsiveContainer height={height ? height : 250}>
				<RadarChart
					cx="50%"
					cy="50%"
					outerRadius="100%"
					data={orderReportsData}
				>
					<defs>
						<filter id="f1" x="0" y="0" width="150%" height="150%">
							<feGaussianBlur in="SourceAlpha" stdDeviation="4" />
							<feOffset dx="2" dy="4" />
							<feMerge>
								<feMergeNode />
								<feMergeNode in="SourceGraphic" />
							</feMerge>
						</filter>
					</defs>
					<PolarGrid />
					<PolarRadiusAxis angle={30} domain={[0, 150]} />
					<Radar
						name="Processed"
						dataKey="B"
						stroke="#3f51b5"
						filter="url(#f1)"
						fill="#3f51b5"
					/>
					<Radar
						name="Pending"
						dataKey="A"
						stroke="#ec407a"
						filter="url(#f1)"
						fill="#ec407a"
					/>
					<Legend
						content={renderLegend}
						wrapperStyle={{ position: "absolute", bottom: -24 }}
					/>
				</RadarChart>
			</ResponsiveContainer>
		</Div>
	);
};

