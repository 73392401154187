import {
	Avatar,
	Box,
	Button,
	IconButton,
	Modal,
	TextField,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { AdminDealsAPI } from "app/routes/middleware";
import { STATUSCODES } from "app/utils/appHelpers";
import { CatalogImageUpload } from "../imageUpload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const EditCatalog = ({
	editOpen,
	handleEditModalClose,
	editedCatalog,
	setOpenSnackbar,
	setMessage,
	setSeverity,
	setIsCatalogUpdateSuccess,
} ) => {
	console.log({ editedCatalog });
	const { handleSubmit, control, reset } = useForm();
	const [isSaving, setIsSaving] = useState();
	const [files1, setFiles1] = useState([]);
	const [fileDrop, setFileDrop] = useState("");
	const [fileUrl, setFileUrl] = useState("");
	const [isUploading, setIsUploading] = useState(false);
	const [isUploadSuccess, setIsUploadSuccess] = useState(false);
	// const [isUploadError, setIsUploadError] = useState(false);
	const [showUpdateImage, setShowUpdateImage] = useState(false);
	const [isSavingStatus, setIsSavingStatus] = useState(false);
	const [isDuplicateSaving, setIsDuplicateSaving] = useState(false);
	//

	const handleDelete1 = (file) => {
		setFiles1((curr) => curr.filter((f) => f.name !== file.name));
		setFileDrop("");
	};

	const handleUploadImage = async () => {
		try {
			setIsUploading(true);
			const request = await AdminDealsAPI.uploadImage({
				image: fileDrop,
			});
			if (request.status === 200) {
				setFileDrop("");
				setFileUrl(request.data.data.imageUrl);
				setIsUploadSuccess(true);
				setIsUploading(false);
			}
			// console.log(request, "request");
		} catch (error) {
			// console.log(error);
			setIsUploading(false);
			setIsUploadSuccess(false);
		}
	};

	// Use useEffect to watch for changes in editedCatalog and reset the form
	React.useEffect(() => {
		if (editedCatalog) {
			reset({
				name: editedCatalog.name || "",
				description: editedCatalog.description || "",
				startDate: handleDateObject(editedCatalog.startDate) || "",
				endDate: handleDateObject(editedCatalog.endDate) || "",
				minimumOrderValue: editedCatalog.minimumOrderValue || "",
			});
		}
	}, [editedCatalog, reset]);

	const handleDateObject = (date) => {
		// Convert the date string to a Date object
		const startDateObject = new Date(date);

		// Get the date part from the Date object
		const startYear = startDateObject.getUTCFullYear();
		const startMonth = `0${startDateObject.getUTCMonth() + 1}`.slice(-2); // Adding 1 to month because it's zero-indexed
		const startDay = `0${startDateObject.getUTCDate()}`.slice(-2);

		// Create the defaultDateValue string in "yyyy-mm-dd" format
		const defaultStartDateValue = `${startYear}-${startMonth}-${startDay}`;
		return defaultStartDateValue;
	};

	const handleSaveChanges = async (data) => {
		setIsSaving(true);
		// console.log("data", data);
		const startInputDateTime = new Date(data.startDate);
		const endInputDateTime = new Date(data.endDate);
		// console.log(startInputDateTime, "startInputDateTime");
		try {
			const request = await AdminDealsAPI.updateCatalog({
				id: editedCatalog?.id,
				payload: {
					image: fileUrl ? fileUrl : editedCatalog?.image,
					name: data.name,
					description: data.description,
					startDate: startInputDateTime,
					endDate: endInputDateTime,
					minimumOrderValue: parseInt(data.minimumOrderValue),
				},
			});
			// console.log(request, "request");
			if (STATUSCODES.SUCCESS.includes(request.status)) {
				setIsSaving(false);
				handleEditModalClose();
				setIsCatalogUpdateSuccess(true);
				setOpenSnackbar(true);
				setMessage(`Catalog ${data.name} updated successfully`);
				setSeverity("success");
			}
			setIsCatalogUpdateSuccess(false);
		} catch (error) {
			// console.log(error, "error");
			if (STATUSCODES.CLIENT_ERROR.includes(error?.response?.status)) {
				setMessage(
					`Error updating catalog ${data.name}. Please make sure you've entered the right details or please reach out to tech support`
				);
				setSeverity("error");
				setOpenSnackbar(true);
			} else if (STATUSCODES.SERVER_ERROR.includes(error?.response?.status)) {
				setMessage(
					"Sorry this is from us, please try again later or reach out to tech support"
				);
				setSeverity("error");
				setOpenSnackbar(true);
			}
		}
	};

	const handleStatusChange = async (status) => {
		// console.log({
		// 	editedCatalog,
		// 	status,
		// });
		setIsSavingStatus(true);
		try {
			const request = await AdminDealsAPI.updateCatalog({
				id: editedCatalog?.id,
				payload: {
					status: status,
				},
			});
			if (STATUSCODES.SUCCESS.includes(request.status)) {
				setIsSavingStatus(false);
				handleEditModalClose();
				setIsCatalogUpdateSuccess(true);
				setOpenSnackbar(true);
				setMessage(`Catalog ${editedCatalog?.name} updated successfully`);
				setSeverity("success");
			}
		} catch (error) {
			setIsSavingStatus(false);
			// console.log(error, "error");
			if (STATUSCODES.CLIENT_ERROR.includes(error?.response?.status)) {
				setMessage(
					`Error updating catalog ${editedCatalog?.name}. Please make sure you've entered the right details or please reach out to tech support`
				);
				setSeverity("error");
				setOpenSnackbar(true);
			} else if (STATUSCODES.SERVER_ERROR.includes(error?.response?.status)) {
				setMessage(
					"Sorry this is from us, please try again later or reach out to tech support"
				);
				setSeverity("error");
				setOpenSnackbar(true);
			}
		}
	};

	const handleDuplicateCatalog = async (catalog) => {
		// console.log({ catalog });

		setIsDuplicateSaving(true);

		const payload = {
			name: catalog.name,
			image: catalog.image,
			description: catalog.description,
			startDate:catalog.startDate,
			endDate: catalog.endDate,
			status: catalog.status,
			minimumOrderValue: catalog.minimumOrderValue,
			newItems: [],
			items: catalog.items,
		};

		try {
			const request = await AdminDealsAPI.duplicateCatalog(catalog.id);
			if (STATUSCODES.SUCCESS.includes(request.status)) {
				setIsDuplicateSaving(true);
				setMessage(`Catalog ${catalog.name} duplicated successfully! 🎉`);
				setSeverity("success");
				setOpenSnackbar(true);
				setIsDuplicateSaving(false);
				handleEditModalClose();
			}
		} catch (error) {
			setIsDuplicateSaving(false);
			if (STATUSCODES.CLIENT_ERROR.includes(error?.response?.status)) {
				setMessage(
					"Please make sure you've entered the right details or please reach out to tech support"
				);
				setSeverity("error");
				setOpenSnackbar(true);
			} else if (STATUSCODES.SERVER_ERROR.includes(error?.response?.status)) {
				setMessage(
					"Sorry this is from us, please try again later or reach out to tech support"
				);
				setSeverity("error");
				setOpenSnackbar(true);
			}
		}

		// setIsDuplicateSaving(true);
		// try {
		// 	const request = await AdminDealsAPI.updateCatalog({
		// 		id: editedCatalog?.id,
		// 		payload: {
		// 			status: '',
		// 		},
		// 	});
		// 	if (STATUSCODES.SUCCESS.includes(request.status)) {
		// 		setIsDuplicateSaving(false);
		// 		handleEditModalClose();
		// 		setIsCatalogUpdateSuccess(true);
		// 		setOpenSnackbar(true);
		// 		setMessage(`Catalog ${editedCatalog?.name} duplicated successfully`);
		// 		setSeverity("success");
		// 	}
		// } catch (error) {
		// 	setIsSavingStatus(false);
		// 	// console.log(error, "error");
		// 	if (STATUSCODES.CLIENT_ERROR.includes(error?.response?.status)) {
		// 		setMessage(
		// 			`Error updating catalog ${editedCatalog?.name}. Please make sure you've entered the right details or please reach out to tech support`
		// 		);
		// 		setSeverity("error");
		// 		setOpenSnackbar(true);
		// 	} else if (STATUSCODES.SERVER_ERROR.includes(error?.response?.status)) {
		// 		setMessage(
		// 			"Sorry this is from us, please try again later or reach out to tech support"
		// 		);
		// 		setSeverity("error");
		// 		setOpenSnackbar(true);
		// 	}
		// }
	};

	return (
		<Modal open={editOpen}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: 500,
					bgcolor: "background.paper",
					boxShadow: 24,
					p: 4,
					borderRadius: "8px",
					outline: "none",
					overflow: "auto",
					maxHeight: "95vh",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						marginBottom: "1rem",
					}}
				>
					<Typography variant="h5" sx={{ fontWeight: 500 }}>
						Editing{" "}
						<i style={{ textDecoration: "underline", fontWeight: 600 }}>
							{editedCatalog?.name}'s
						</i>{" "}
						Catalog
					</Typography>
					<IconButton onClick={handleEditModalClose}>
						<CloseIcon />
					</IconButton>
				</div>
				<form>
					{showUpdateImage === false ? (
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}
						>
							<Avatar
								src={editedCatalog?.image}
								variant="square"
								alt="Avatar"
								sx={{
									width: 200,
									height: 150,
									objectFit: "fill",
									border: "1px solid #CFCECE",
									borderRadius: "5px",
									mb: 2,
									boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
								}}
							/>
							<Button
								variant="contained"
								color="secondary"
								sx={{ fontSize: "10px" }}
								onClick={() => setShowUpdateImage(true)}
							>
								Change Catalog Image
							</Button>
						</div>
					) : (
						<div
							style={{
								display: "flex",
								alignItems: "center",
								flexDirection: "column",
								width: "100%",
								margin: "20px 0px",
							}}
						>
							<CatalogImageUpload
								setFileDrop={setFileDrop}
								title={"Upload catalog image"}
								isUploadSuccess={isUploadSuccess}
								handleDelete={handleDelete1}
								setFiles={setFiles1}
								files={files1}
							/>
							<Button
								color="secondary"
								variant="contained"
								disabled={fileDrop.length === 0}
								sx={{
									mt: 1,
									borderRadius: "10px",
									fontSize: "10px",
									display: "flex",
									alignItems: "center",
								}}
								onClick={handleUploadImage}
							>
								<CloudUploadIcon sx={{ mr: 1 }} />{" "}
								{isUploading && fileDrop.length > 0
									? "Uploading..."
									: isUploading === false &&
									  fileDrop.length === 0 &&
									  isUploadSuccess === true
									? "Done"
									: "Upload Image"}
							</Button>
							{/* {fileUrl.length === 0 && isUploadError && (
								<FormHelperText error>
									Please upload chosen image to proceed
								</FormHelperText>
							)} */}
						</div>
					)}
					<Controller
						name="name"
						control={control}
						defaultValue={editedCatalog?.name || ""}
						render={({ field }) => (
							<>
								<TextField
									defaultValue={editedCatalog?.name || ""}
									fullWidth
									margin="normal"
									label="Name"
									{...field}
									inputProps={{
										maxLength: 50,
									}}
								/>
								<Typography
									variant="body1"
									sx={{ textAlign: "right", mt: -1, fontSize: "12px" }}
								>
									{50 - field.value.length < 0 ? 0 : 50 - field.value.length}{" "}
									characters left
								</Typography>
							</>
						)}
					/>
					<Controller
						name="description"
						control={control}
						defaultValue={editedCatalog?.description || ""}
						render={({ field }) => (
							<>
								<TextField
									fullWidth
									label="Description"
									margin="normal"
									{...field}
									inputProps={{
										maxLength: 250,
									}}
								/>
								<Typography
									variant="body1"
									sx={{ textAlign: "right", mt: -1, fontSize: "12px" }}
								>
									{250 - field.value.length < 0 ? 0 : 250 - field.value.length}{" "}
									characters left
								</Typography>
							</>
						)}
					/>
					<Controller
						name="startDate"
						control={control}
						defaultValue={handleDateObject(editedCatalog?.startDate) || ""}
						render={({ field }) => (
							<TextField
								label="Start Date"
								defaultValue={handleDateObject(editedCatalog?.startDate) || ""}
								fullWidth
								margin="normal"
								type="date" // Use type="date" for date input
								{...field}
								InputLabelProps={{ shrink: true }}
							/>
						)}
					/>
					<Controller
						name="endDate"
						control={control}
						defaultValue={handleDateObject(editedCatalog?.endDate) || ""}
						render={({ field }) => (
							<TextField
								label="End Date"
								fullWidth
								defaultValue={handleDateObject(editedCatalog?.endDate) || ""}
								margin="normal"
								type="date"
								{...field}
								InputLabelProps={{ shrink: true }}
							/>
						)}
					/>
					<Controller
						name="minimumOrderValue"
						control={control}
						defaultValue={editedCatalog?.minimumOrderValue || ""}
						render={({ field }) => (
							<TextField
								label="Minimum Order Value"
								fullWidth
								margin="normal"
								{...field}
							/>
						)}
					/>

					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-between",
							mt: 2,
						}}
					>
						{editedCatalog?.status === "DRAFT" ? (
							<>
								<Button
									variant="contained"
									sx={{
										backgroundColor: "#62A933",
										"&:hover": {
											backgroundColor: "#87CF57",
										},
									}}
									onClick={() => handleStatusChange("ACTIVE")}
								>
									{isSavingStatus === true ? "Publishing..." : "Publish Now"}
								</Button>
								<Button
									variant="outlined"
									color="primary"
									onClick={handleSubmit(handleSaveChanges)}
									sx={{
										my: 2,
									}}
								>
									{isSavingStatus === true ? "Saving..." : "Save Edits"}
								</Button>
								{editedCatalog?.isClosed ? (
									<Button
										variant="contained"
										sx={{
											backgroundColor: "#698FBB",
											"&:hover": {
												backgroundColor: "#939191",
											},
										}}
										onClick={() => handleDuplicateCatalog(editedCatalog)}
									>
										{isDuplicateSaving ? "Duplicating..." : "Duplicate"}
									</Button>
								) : null}
							</>
						) : (
							<>
								<Button
									variant="contained"
									sx={{
										backgroundColor: "#BFBFBF",
										"&:hover": {
											backgroundColor: "#939191",
										},
									}}
									onClick={() => handleStatusChange("DRAFT")}
								>
									{isSavingStatus === true ? "Draft..." : "Move to Drafts"}
								</Button>
								<Button
									variant="outlined"
									color="primary"
									onClick={handleSubmit(handleSaveChanges)}
									sx={{
										my: 2,
									}}
								>
									{isSaving === true ? "Saving..." : "Save Edits"}
								</Button>
								{editedCatalog?.isClosed ? (
									<Button
										variant="contained"
										sx={{
											backgroundColor: "#698FBB",
											"&:hover": {
												backgroundColor: "#939191",
											},
										}}
										onClick={() => handleDuplicateCatalog(editedCatalog)}
									>
										{isDuplicateSaving ? "Duplicating..." : "Duplicate"}
									</Button>
								) : null}
							</>
						)}
					</Box>
				</form>
			</Box>
		</Modal>
	);
};

export default EditCatalog;
