import { Alert, LinearProgress, Snackbar } from "@mui/material";
import DatePicker from "app/components/DatePicker";
import { DateRangeComponent } from "app/components/DateRange";
import { MoneyRequestsAPI } from "app/routes/middleware";
import { dateRangePicker } from "app/utils/appHelpers";
import React from "react";
import { Helmet } from "react-helmet-async";
import { columns } from "./data/column";
import Table from "./data/table";

const MoneyRequests = () => {
	const [progress, setProgress] = React.useState(0);
	const [loading, setLoading] = React.useState(true);
	const [rowsData, setRowsData] = React.useState([]);
	const [rowModesModel, setRowModesModel] = React.useState({});
	const [dateRange, setDateRange] = React.useState(dateRangePicker.ytd());
	const [numberOfItems, setNumberOfItems] = React.useState(50);
	const [open, setOpen] = React.useState(false);
	const [message, setMessage] = React.useState("");
	const [severity, setSeverity] = React.useState("success");
	const [duration, setDuration] = React.useState(0);

	React.useEffect(() => {
		if (dateRange.startDate !== undefined && dateRange.endDate !== undefined) {
			setLoading(true);
			handleMoneyRequestsLoad(dateRange);
		}

		return () => {
			setLoading(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setDateRange, dateRange]);

	React.useEffect(() => {
		const timer = setInterval(() => {
			setProgress((oldProgress) => {
				if (oldProgress === 100) {
					return 0;
				}
				const diff = Math.random() * 10;
				return Math.min(oldProgress + diff, 100);
			});
		}, 500);

		return () => {
			clearInterval(timer);
		};
	}, []);

	const handleMoneyRequestsLoad = async () => {
		try {
			setLoading(true);
			const request = await MoneyRequestsAPI.get(dateRange);
			const response = request.data.data.result;
			setLoading(false);

			// console.log(response, "money requests");

			if (!response && response.length === 0) {
				setLoading(false);
				setMessage(
					"No transactions found for the selected date range, please try to select another date range"
				);
				setSeverity("info");
				setDuration(5000);
				setOpen(true);
				setRowsData([]);
			} else if (response && response.length > 0) {
				setRowsData(response);
				setLoading(false);
			}
		} catch (error) {
			setLoading(false);
			if (error.response.status === 401) {
				setMessage(
					"Sorry your sign in token has expired, please sign in again to continue using Spark Admin 🤭"
				);
				setSeverity("error");
				setDuration(4000);
				setOpen(true);
				localStorage.clear("@spark-session");
				window.location.replace("/sign-in");
			} else {
				setMessage("Sorry, something went wrong, please try again later 😢");
				setSeverity("error");
				setDuration(4000);
				setOpen(true);
			}
		}
	};
	return (
		<div>
			<Helmet>
				<title>Money Requests - Spark Dashboard</title>
			</Helmet>
			<Snackbar
				open={open}
				autoHideDuration={duration}
				onClose={() => setOpen(false)}
			>
				<Alert
					onClose={() => setOpen(false)}
					severity={severity}
					sx={{ width: "60%" }}
				>
					{message}
				</Alert>
			</Snackbar>
			<div
				style={{
					marginTop: "-30px",
					display: "flex",
				}}
			>
				<DatePicker setDateRange={setDateRange} />
				<DateRangeComponent setDateRange={setDateRange} />
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					// marginTop: "-30px",
				}}
			>
				<h2>Money Requests</h2>
			</div>
			{loading && (
				<LinearProgress
					value={progress}
					variant="determinate"
					sx={{ mb: 1, mt: -1 }}
				/>
			)}

			<Table
				rows={rowsData}
				setRows={setRowsData}
				columns={columns}
				rowModesModel={rowModesModel}
				setRowModesModel={setRowModesModel}
				pageSize={numberOfItems}
				pagination={true}
				disableSelectionOnClick={true}
				rowCount={rowsData?.length ? rowsData?.length : 0}
				onPageSizeChange={(pageSize) => {
					setNumberOfItems(pageSize);
				}}
			/>
		</div>
	);
};

export default MoneyRequests;
