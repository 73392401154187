const start_dur = "2022-09-23T20:00:00.000Z";
const end_dur = "2022-09-30T20:00:00.000Z";

const always = [
	{
		name: "total # of incomplete onboarders (did ph# verification only)",
		label: "Incomplete",
		url: "dashboard/user/onboarding?mode=UNCOMPLETED",
	},
	{
		name: "total # of users (by ended of the reporting period)",
		label: "Total_Users",
		url: "dashboard/user/onboarding?mode=BASIC",
	},
	{
		name: `total # of users that are KYC'ed AND connected at least one External Wallet`,
		label: "Kyc_and_Ext_Wallet",
		url: "dashboard/user/onboarding?mode=KYC_AND_EXT_WALLET",
	},
	{
		name: `total # of users that are KYC'ed`,
		label: "Kyc",
		url: "dashboard/user/onboarding?mode=KYC",
	},
	{
		name: "total # of users that have connected at least one External Wallet",
		label: "Ext_Wallet",
		url: "dashboard/user/onboarding?mode=EXT_WALLET",
	},
	{
		name: "all the session data for the users",
		label: "Users_Session",
		url: "user/metrics?mode=USERS_SESSION",
	},

	{
		name: "total # of clubs (by ended of the reporting period)",
		label: "Total_Clubs",
		url: "dashboard/clubs/metrics?mode=ALL",
	},
];

const duration = [
	// Count - Users
	{
		name: "total # of user logins",
		label: "User_Logins",
		url: "dashboard/transaction/count?mode=USER_LOGINS",
	},
	{
		name: "total # of active user logins",
		label: "Active_User_Logins",
		url: "dashboard/transaction/count?mode=ACTIVE_USER_LOGINS",
	},
	{
		name: "total # of active users, by end of period (tx = add/withdraw/send money/approved request, whether in-Club or not)",
		label: "Transactions_Count",
		url: "dashboard/transaction/count?mode=ALL_TRANS",
	},
	{
		name: "total # of active users that have transacted In-Spark (send money or had their request approved; at least once)",
		label: "",
		url: "dashboard/transaction/count?mode=SENT_MONEY_AND_APPROVED_REQUEST",
	},
	{
		name: "total # of active users that have sent money (at least once)",
		label: "Money_Sent_P2P_Count",
		url: "dashboard/transaction/count?mode=TRANS&transType=Send Money",
	},
	{
		name: "total # of active users that had THEIR money request approved (at least once)",
		label: "Request_Money_P2P_Count",
		url: "dashboard/transaction/count?mode=TRANS&transType=moneyRequests&__type=HAD_APPROVED",
	},
	{
		name: "total # of active users that have added money (at least once)",
		label: "User_Deposit_Count",
		url: "dashboard/transaction/count?mode=TRANS&transType=Cash In",
	},
	{
		name: "total # of active users that have withdrawn money (at least once)",
		label: "User_Withdraw_Count",
		url: "dashboard/transaction/count?mode=TRANS&transType=Cash Out",
	},

	{
		name: "In app transactions",
		label: "In_App_Transactions",
		url: "transaction/count-by-status?",
	},
	{
		name: "Total spark revenue",
		label: "Cash_Held_On_Spark",
		url: "transaction/volume-find?mode=CASH_ON_SPARK",
	},
	{
		name: "Total club wallets volume",
		label: "Total_Club_Wallets_Volume",
		url: "transaction/volume-find?mode=TOTAL_CLUB_WALLETS_VOLUME",
	},

	{
		name: "total # of all in-Spark transactions (send or approved request, whether in-Club or not)",
		label: "All_InSpark_Transactions_Count",
		url: "dashboard/transactions?mode=ALL",
	},
	{
		name: "total # of all in-Spark transactions (Club only)",
		label: "Club_Transactions_Count",
		url: "dashboard/transactions?mode=CLUB_ONLY",
	},
	{
		name: "total # of all add money transactions",
		label: "Deposit_Count",
		url: "dashboard/transactions?mode=ADD_MONEY",
	},
	{
		name: "total # of all withdrawal transactions",
		label: "Withdraw_Count",
		url: "dashboard/transactions?mode=WITHDRAW_MONEY",
	},
	{
		name: "total # of all money sends (whether in-Club or not)",
		label: "Total_Money_Sent_Count",
		url: "dashboard/transactions?mode=SEND_MONEY_ALL",
	},
	{
		name: "total # of all money sends (Club only)",
		label: "",
		url: "dashboard/transactions?mode=SEND_MONEY_CLUB",
	},
	{
		name: "total # of all approved money requests (whether in-Club or not)",
		label: "Total_Money_Requested_Count",
		url: "dashboard/transactions?mode=REQUEST_MONEY_ALL",
	},
	{
		name: "total # of all approved money requests (Club only)",
		label: "",
		url: "dashboard/transactions?mode=REQUEST_MONEY_CLUB",
	},
	{
		name: "total # of all approved money requests (P2P only)",
		label: "Request_Money_P2P",
		url: "dashboard/transactions?mode=REQUEST_MONEY_P2P",
	},

	// Volume
	{
		name: "total volume of all transactions in-Spark transactions (send or approved request, whether in-Club or not)",
		label: "All_InSpark_Transactions_Volume",
		url: "dashboard/transactions?mode=ALL",
		extractor: "volume",
	},
	{
		name: "total volume of all in-Spark transactions (Club only)",
		label: "Club_Transactions_Volume",
		url: "dashboard/transactions?mode=CLUB_ONLY",
		extractor: "volume",
	},
	{
		name: "total volume of all add money transactions",
		label: "Deposit_Volume",
		url: "dashboard/transactions?mode=ADD_MONEY",
		extractor: "volume",
	},
	{
		name: "total volume of all withdrawal transactions",
		label: "Withdraw_Volume",
		url: "dashboard/transactions?mode=WITHDRAW_MONEY",
		extractor: "volume",
	},
	{
		name: "total volume of money sends (whether in-Club or not)",
		label: "Total_Money_Sent_Volume",
		url: "dashboard/transactions?mode=SEND_MONEY_ALL",
		extractor: "volume",
	},
	{
		name: "total volume of money sends (Club only)",
		label: "Send_Money_Club_Volume",
		url: "dashboard/transactions?mode=SEND_MONEY_CLUB",
		extractor: "volume",
	},
	{
		name: "total volume of approved money requests (whether in-Club or not)",
		label: "Total_Money_Requested_Volume",
		url: "dashboard/transactions?mode=REQUEST_MONEY_ALL",
		extractor: "volume",
	},
	{
		name: "total volume of approved money requests (Club only)",
		label: "Club_Money_Request_Approved_Volume",
		url: "dashboard/transactions?mode=REQUEST_MONEY_CLUB",
	},

	// Count - Club Only
	{
		name: "total # of active Clubs",
		label: "Active_Clubs",
		url: "dashboard/clubs/metrics?mode=ACTIVE_CLUBS",
	},
	{
		name: "average # of transactions (any type) per active Club",
		label: "Avg_Transactions_Per_Active_Club",
		url: "dashboard/clubs/metrics?mode=AVG_TRANS_IN_ACTIVE_CLUBS",
	},
	{
		name: "total # of members in at least 1 active Club",
		label: "Users_In_Active_Clubs",
		url: "dashboard/clubs/metrics?mode=TOTAL_MEMBERS_IN_ACTIVE_CLUBS",
	},
	{
		name: "total # of active Clubs with only 1 member",
		label: "One_Member_Active_Clubs",
		url: "dashboard/clubs/metrics?mode=ACTIVE_CLUBS_ONE_MEMBER",
	},
	{
		name: "average # of active clubs an active user is in",
		label: "Avg_Active_Clubs_Per_Active_User",
		url: "dashboard/clubs/metrics?mode=AVERAGE_ACTIVE_USERS_PER_ACTIVE_CLUB",
	},
	{
		name: "average total # of invites sent per unique Club Created (upon creation)",
		label: "Avg_Total_Invites_On_Club_Create",
		url: "dashboard/clubs/metrics?mode=AVG_TOTAL_INVITES_ON_CLUB_CREATE",
	},
	{
		name: "% of users invited during club creation, that accept club invite",
		label: "Acceptance_Rate_On_Club_Create",
		url: "dashboard/clubs/metrics?mode=PERCENTAGE_OF_USERS_INVITED_DUR_CLUB_CREATE_AND_ACCEPTED",
	},
	{
		name: "average time (in minutes) it takes for user invited at club creation, to become a member",
		label: "Avg_Time_Invites_Accptd_On_Club_Create",
		url: "dashboard/clubs/metrics?mode=AVG_TIME_INVITES_ACCEPTED_ON_CLUB_CREATE",
	},
	{
		name: "average # of members per Club",
		label: "Avg_Users_Per_Club",
		url: "dashboard/clubs/metrics?mode=AVERAGE_USERS_PER_CLUB",
	},
	{
		name: "average # of members per active Club",
		label: "Avg_Users_Per_Active_Club",
		url: "dashboard/clubs/metrics?mode=AVERAGE_USERS_PER_ACTIVE_CLUB",
	},
	{
		name: "average # of active members (active member = transacted In-Club) per active Club",
		label: "Avg_Active_Users_Per_Active_Club",
		url: "dashboard/clubs/metrics?mode=AVERAGE_ACTIVE_USERS_PER_ACTIVE_CLUB",
	},
];

export function appendQueries(data, query) {
	const temp = [];
	return data.map((metric) => ({
		...metric,
		url: `/admin/${metric.url}&startDate=${query.startDate}&endDate=${query.endDate}`,
	}));
	// for (const metric of data) {
	// 	console.log('METRIC URL: ', metric.url)

	// 	metric.url = "/admin/" + metric.url + `&startDate=${query.startDate}&endDate=${query.endDate}`;
	// 	temp.push(metric)
	// }
	// return temp;
}

let start = "2022-01-01T20:00:00.000Z";
let end = "2022-09-30T20:00:00.000Z";

// let start = new Date().toISOString().split("T")[0] + "T00:00:00.000Z";
// let end = new Date().toISOString();

const query = { start, end };

export const metricURLs = [...always, ...duration];
