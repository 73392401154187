export const columns = [
	{
		field: "id",
		headerName: "ID",
		width: 80,
		hide: true,
		headerAlign: "left",
	},
	{
		field: "firstName",
		headerName: "First Name",
		width: 150,
		headerAlign: "left",
		align: "left",
	},
	{
		field: "otherNames",
		headerName: "Other Names",
		width: 120,
		headerAlign: "left",
		align: "left",
		renderCell: ( params ) => {
			return params.value === null || !params.value ? "N/A" : params.value;
		}
	},
	{
		field: "lastName",
		headerName: "Last Name",
		width: 150,
		headerAlign: "left",
		align: "left",
	},
	{
		field: "socialHandle",
		headerName: "Social Handle",
		width: 150,
		headerAlign: "left",
		align: "left",
	},
	{
		field: "amount",
		headerName: "Amount",
		headerAlign: "left",
		align: "left",
	},
	{
		field: "numberOfTimes",
		headerName: "Frequency",
		headerAlign: "left",
		align: "left",
	},
	{
		field: "createdAt",
		headerName: "Created At",
		headerAlign: "left",
		align: "left",
		width: 180,
		valueFormatter: ({ value }) => value && new Date(value).toLocaleString(),
	},
];
