import React, { useEffect } from "react";
import ContentUpload from "./component/content_upload";
import "../kyc/style/kyc.css";
import KycStatus from "./component/kyc_status";
import { Alert, MenuItem, Select } from "@mui/material";
import { Button } from "app/components/Button";
import { Input } from "app/components/Input";
import {
	CheckIDNumberAPI,
	GetCountriesAPI,
	UploadKycAPI,
} from "app/routes/middleware";
import { useAuthState } from "app/providers/AuthContext";
import {
	ACTIONS_IDENTIFIERS,
	resturctureAccess,
	storage,
} from "app/utils/appHelpers";
import ManualOverride from "app/components/ManualOverride";
import J4RobotLoader from "app/components/Modals";

const Verification = ({ user, existence }) => {
	const [loading, setLoading] = React.useState(false);
	const [countries, setCountries] = React.useState([]);
	const [responseValue, setResponseValue] = React.useState({});
	const [idTypesData, setIdTypesData] = React.useState([]);
	const [countryValue, setCountryValue] = React.useState("");
	const [idTypeValue, setIdTypeValue] = React.useState("");
	const [idNumberValue, setIdNumberValue] = React.useState("");
	const [verifyMessage, setVerifyMessage] = React.useState("");
	const [verifyMessageStatus, setVerifyMessageStatus] = React.useState("");
	const [selfieImage, setSelfieImage] = React.useState();
	const [idImage, setIdImage] = React.useState();
	const [responseImage, setResponseImage] = React.useState("");
	const [idVerifyResponseText, setIdVerifyResponseText] = React.useState("");
	const [showUploadSection, setShowUploadSection] = React.useState(false);
	const [isUploading, setIsUploading] = React.useState(false);
	const [uploadStatus, setUploadStatus] = React.useState("");
	const [state, setState] = React.useState({
		isOverrideKYCAllowed: false,
		isIdVerified: user?.hasAVerifiedId || false,
		isLoading: false,
		isError: false,
		errorMessage: null,
	});
	const addBase = "data:image/jpeg;base64,";

	const { user: loggedInUser, allAccesses } = useAuthState();
	console.log(user, "user");
	console.log(existence, "existence");
	const permissions = loggedInUser.permissions;

	const OverrideKYCFunction = async () => {
		const response = await resturctureAccess(
			allAccesses,
			permissions,
			"actions"
		);
		const overrideKYC = response.unstructuredUserMenus.filter(
			(x) => x.icon === ACTIONS_IDENTIFIERS.MANUAL_OVERRIDE
		);
		if (overrideKYC.length) {
			setState({
				isOverrideKYCAllowed: true,
			});
		}
		// console.log(overrideKYC, "overrideKYC");
		// console.log(response.unstructuredUserMenus, "response");
	};

	useEffect(() => {
		fetchCountries();
		OverrideKYCFunction();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchCountries = async () => {
		const request = await GetCountriesAPI.get();
		const response = request.data.data;
		setResponseValue(response);
		setCountries(Object.keys(response));
	};

	const getSelectedCountry = (event) => {
		const selectedCountry = event.target.value;
		getIdTypes(selectedCountry);
	};

	const getIdTypes = (countryName) => {
		const getByCountry = responseValue[countryName];
		setCountryValue(getByCountry.country);
		const idTypes = Object.values(getByCountry.idTypes);
		setIdTypesData(idTypes);
	};

	const getIdValues = (event) => {
		setIdTypeValue(event.target.value);
	};

	const getIdNumber = (event) => {
		setIdNumberValue(event.target.value);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		setVerifyMessageStatus("");
		const data = {
			userId: user.id,
			country: countryValue,
			idType: idTypeValue,
			idNumber: idNumberValue,
		};
		// console.log(data);
		try {
			const request = await CheckIDNumberAPI.post(data);
			const response = request.data.data?.Actions.Verify_ID_Number;
			const responseText = request.data.data.ResultText;
			setIdVerifyResponseText(responseText);
			setLoading(false);
			// console.log(request.data, "request");
			setResponseImage(request.data.data?.Photo);

			if (response === "Not Done") {
				setVerifyMessage("Invalid ID");
				setShowUploadSection(false);
			} else {
				setShowUploadSection(true);
				setVerifyMessage("Valid ID");
			}
		} catch (error) {
			setLoading(false);
			setShowUploadSection(false);
			if (error.response?.status === 500) {
				setVerifyMessageStatus(
					"Sorry, this is on us, please refresh or confirm if details are correct and try again"
				);
			}
			// console.log(error, "error");
		}
	};

	const handleUpload = async () => {
		setIsUploading(true);
		const data = {
			userId: user.id,
			country: countryValue,
			idType: idTypeValue,
			idNumber: idNumberValue,
			selfieBase64: selfieImage,
			cardImageBase64: selfieImage,
		};
		try {
			const request = await UploadKycAPI.post(data);
			setIsUploading(false);
			// console.log(request.data);
			if (request.data.data[1]?.status === "Approved") {
				setUploadStatus("Approved");
			}
		} catch (error) {
			setIsUploading(false);
			setUploadStatus(false);

			// console.log(error, "error");
			if (error.response?.status === 500) {
				setVerifyMessageStatus(
					"Sorry, this is on us, please refresh and try again"
				);
			} else if (error.response?.status === 400) {
				setVerifyMessageStatus(
					"Please confirm if inputs are correct and try again or refresh the page"
				);
			}
		}
	};

	return (
		<div>
			{/* <J4RobotLoader
				active={state.isLoading}
				text={`Loading ${loaderMessage} for ${selectedUser?.firstName} ${selectedUser?.lastName}`}
			/> */}
			<div
				style={{
					marginTop: "-30px",
				}}
			>
				<h2>User Information</h2>
			</div>
			<div>
				<KycStatus
					user={{
						...user,
						hasAVerifiedId: state?.isIdVerified
							? state?.isIdVerified
							: user?.hasAVerifiedId,
					}}
					existence={existence}
				/>
			</div>
			{existence && user?.firstName && user?.lastName ? (
				<div>
					<div
						style={{
							marginTop: "30px",
						}}
					>
						<h2>Verify User ID</h2>
						<p style={{ color: "red", marginBottom: "20px" }}>
							{verifyMessageStatus}
						</p>
						{state.isError && (
							<p style={{ color: "red", marginBottom: "20px" }}>
								{state.errorMessage}
							</p>
						)}
						<div>
							<form
								onSubmit={handleSubmit}
								style={{ textAlign: "left" }}
								noValidate
								autoComplete="off"
							>
								<div className="input-select-main">
									{idVerifyResponseText && (
										<Alert
											severity="info"
											style={{ fontSize: "12px", marginBottom: "10px" }}
										>
											{idVerifyResponseText}
										</Alert>
									)}
									<div className="input-select">
										<div className="input-select-div input-div1">
											<label style={{ marginBottom: "10px" }} htmlFor="country">
												Country
											</label>
											<Select
												defaultValue={"Select Country"}
												className="select"
												sx={{
													borderRadius: "30px",
													height: "50px",
													width: "100%",
												}}
												onChange={getSelectedCountry}
												name="country"
											>
												<MenuItem value="Select Country">
													Select Country
												</MenuItem>
												{countries &&
													countries.map((country) => (
														<MenuItem key={country} value={country}>
															{country}
														</MenuItem>
													))}
											</Select>
										</div>
										<div className="input-select-div">
											<label style={{ marginBottom: "10px" }} htmlFor="idType">
												ID Type
											</label>
											<Select
												defaultValue={"Select ID Type"}
												sx={{
													borderRadius: "30px",
													height: "50px",
													width: "100%",
												}}
												name="idType"
												className="select"
												// onClick={getIdTypes}
												onChange={getIdValues}
											>
												<MenuItem value={"Select ID Type"}>
													Select an ID Type
												</MenuItem>
												{idTypesData &&
													idTypesData.map((id) => (
														<MenuItem key={id} value={id}>
															{id}
														</MenuItem>
													))}
											</Select>
										</div>
									</div>
									<div className="input-select bottom-input-div">
										<div className="input-select-div input-div1 bottom-div">
											<label
												style={{ marginBottom: "10px" }}
												htmlFor="idNumber"
											>
												ID Number
											</label>
											<Input
												name="idNumber"
												id="outlined-number"
												className=""
												type="text"
												placeholder="Enter ID Number"
												style={{
													borderRadius: "30px",
													height: "50px",
													width: "100%",
													border: "1px solid #ccc",
													padding: "0 20px",
													fontFamily: "Inherit",
												}}
												onChange={getIdNumber}
											/>
										</div>
										<div className="input-select-div button-div">
											<div
												style={{
													width: "43%",
												}}
											>
												<Button
													title={
														loading
															? "Verifying..."
															: verifyMessage && !loading
															? verifyMessage
															: "Verify ID"
													}
													type={"submit"}
													style={
														verifyMessage === "Invalid ID"
															? {
																	backgroundColor: "white",
																	color: "red",
																	borderRadius: "30px",
																	height: "45px",
																	width: "100%",
																	border: "2px solid red",
																	shadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
																	fontWeight: "500",
																	fontSize: "14px",
																	fontFamily: "Inherit",
																	cursor: "pointer",
															  }
															: verifyMessage === "Valid ID"
															? {
																	backgroundColor: "white",
																	color: "#62A933",
																	borderRadius: "30px",
																	height: "45px",
																	width: "100%",
																	border: "2px solid #62A933",
																	shadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
																	fontWeight: "500",
																	fontSize: "14px",
																	fontFamily: "Inherit",
																	cursor: "pointer",
															  }
															: {
																	backgroundColor: "#62A933",
																	borderRadius: "30px",
																	height: "45px",
																	width: "100%",
																	border: "none",
																	shadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
																	color: "#fff",
																	fontWeight: "500",
																	fontSize: "14px",
																	fontFamily: "Inherit",
																	cursor: "pointer",
															  }
													}
												/>
											</div>
											<div className="manualOverride">
												{/* {state.isLoading ? "Loading..." : ""} */}
												{state.isOverrideKYCAllowed ? (
													<ManualOverride
														user={user}
														setState={setState}
														loading={state.isLoading ? true : false}
													/>
												) : (
													""
												)}
											</div>
										</div>
									</div>
									<div>
										{verifyMessage === "Valid ID" && (
											<img
												src={addBase.concat(responseImage)}
												alt="ID"
												width={100}
											/>
										)}
									</div>
								</div>
							</form>
						</div>
					</div>
					<div
						style={{
							marginTop: "30px",
						}}
					>
						<h2>Upload ID</h2>
						<div className="upload-main-wrapper">
							<div className="upload-id-div">
								<div className="upload-div upload1">
									<ContentUpload
										title={"Upload recent photo"}
										setFileDrop={setSelfieImage}
										fileDrop={selfieImage}
									/>
								</div>
								<div className="upload-div">
									<ContentUpload
										title={"Upload valid ID"}
										setFileDrop={setIdImage}
										fileDrop={idImage}
									/>
								</div>
							</div>
						</div>
						<div className="upload-btns">
							<Button
								title={isUploading === true ? "Verifying..." : "Verify"}
								type={"submit"}
								className="upload-btn1"
								onClick={handleUpload}
							/>
							{uploadStatus === "Approved" ? (
								<div
									className="upload-btn2"
									style={{
										color: "green",
									}}
								>
									ID VERFIFICATION WAS SUCCESSFUL
								</div>
							) : uploadStatus === "Rejected" ? (
								<div
									className="upload-btn2"
									style={{
										color: "FFF",
										backgroundColor: "red",
									}}
								>
									ID VERFIFICATION FAILED
								</div>
							) : null}
						</div>
					</div>{" "}
				</div>
			) : (
				""
			)}
			<div
				style={{
					display: "flex",
					width: "100%",
					justifyContent: "flex-end",
					marginTop: "30px",
				}}
				className="upload-btns"
			>
				<Button
					onClick={(e) => {
						e.preventDefault();
						window.location.reload();
					}}
					className="upload-btn1"
					title={"Verify Another User"}
				/>
			</div>
		</div>
	);
};;;

export default Verification;
